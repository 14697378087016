import React, { PureComponent } from "react";
import { defineMessages, injectIntl, intlShape } from "react-intl";

import { DialogBox, DialogBoxFooterType, NumericInput, Checkbox, Loader } from "~/core";

import { connect } from "react-redux";
import "./profitLossDetailed-config-modal.css";
import * as pfselectors from "./selectors";

const messages = defineMessages({
    profitLossDetailedCostPerAcreLbl: {
        id: "profitLossDetailed.configInfo.costPerAcreLbl",
        defaultMessage: "Cost/Acre",
    },
    profitLossDetailedSeedLbl: {
        id: "profitLossDetailed.configInfo.seedLbl",
        defaultMessage: "Seed",
    },
    profitLossDetailedFertilizerLbl: {
        id: "profitLossDetailed.configInfo.fertilizerLbl",
        defaultMessage: "Fertilizer",
    },
    profitLossDetailedChemicalLbl: {
        id: "profitLossDetailed.configInfo.chemicalLbl",
        defaultMessage: "Chemical",
    },
    profitLossDetailedIrrigationLbl: {
        id: "profitLossDetailed.configInfo.irrigationLbl",
        defaultMessage: "Irrigation",
    },
    profitLossDetailedHarvestLbl: {
        id: "profitLossDetailed.configInfo.harvestLbl",
        defaultMessage: "Harvest",
    },
    profitLossDetailedEquipmentLbl: {
        id: "profitLossDetailed.configInfo.equipmentLbl",
        defaultMessage: "Equipment",
    },
    profitLossDetailedLaborLbl: {
        id: "profitLossDetailed.configInfo.laborLbl",
        defaultMessage: "Labor",
    },
    profitLossDetailedCropInsuranceLbl: {
        id: "profitLossDetailed.configInfo.cropInsuranceLbl",
        defaultMessage: "Crop Insurance",
    },
    profitLossDetailedIntOpCapitalLbl: {
        id: "profitLossDetailed.configInfo.intOpCapitalLbl",
        defaultMessage: "Interest-Operating Capital",
    },
    profitLossDetailedMiscLbl: {
        id: "profitLossDetailed.configInfo.miscLbl",
        defaultMessage: "Miscellaneous",
    },
    profitLossDetailedLandLbl: {
        id: "profitLossDetailed.configInfo.landLbl",
        defaultMessage: "Land",
    },
    profitLossDetailedTotalLbl: {
        id: "profitLossDetailed.configInfo.totalLbl",
        defaultMessage: "Total",
    },
    profitLossDetailedNonCropIncomeLbl: {
        id: "profitLossDetailed.configInfo.nonCropIncomeLbl",
        defaultMessage: "Non-Crop Income",
    },
    profitLossDetailedActualValueLbl: {
        id: "profitLossDetailed.configInfo.ActualValueLbl",
        defaultMessage: "Actual",
    },
    profitLossDetailedUseLbl: {
        id: "profitLossDetailed.configInfo.UseLbl",
        defaultMessage: "Use",
    },
});

const excludedFields = [
    "total",
    "nonCropIncome",
    "actualSeed",
    "actualFertilizer",
    "actualChemical",
    "useSeed",
    "useFertilizer",
    "useChemical",
];

const PROP_SEED = "seed";
const PROP_FERTILIZER = "fertilizer";
const PROP_CHEMICAL = "chemical";

interface ProfitLossDetailedConfigModalProps {
    applicationCost: any;
    onClose: (cost) => void;
    detailedCost: any;
    fieldGuid: string;
    intl: intlShape;
    isLoading: boolean;
}

interface ProfitLossDetailedConfigModalState {
    detailedCost: any;
    originalDetailedCost: any;
}

export class ProfitLossDetailedConfigModal_ extends PureComponent<
    ProfitLossDetailedConfigModalProps,
    ProfitLossDetailedConfigModalState
> {
    actualSeedCost: number;
    actualChemicalCost: number;
    actualFertilizerCost: number;

    constructor(props) {
        super(props);
        this.actualSeedCost = 0.0;
        this.actualChemicalCost = 0.0;
        this.actualFertilizerCost = 0.0;
        this.state = {
            detailedCost: props.detailedCost,
            originalDetailedCost: props.detailedCost,
        };
    }
    _saveEnabled() {
        const { detailedCost } = this.state;
        if (
            (detailedCost.seed > 0 ||
                detailedCost.fertilizer > 0 ||
                detailedCost.chemical > 0 ||
                detailedCost.irrigation > 0 ||
                detailedCost.harvest > 0 ||
                detailedCost.equipment > 0 ||
                detailedCost.labor > 0 ||
                detailedCost.cropInsurance > 0 ||
                detailedCost.interestOperatingCapital > 0 ||
                detailedCost.miscellaneous > 0 ||
                detailedCost.land > 0) &&
            detailedCost.total - Number(detailedCost.nonCropIncome) > 0
        )
            return true;
    }

    _onModalSave() {
        const { onClose } = this.props;
        onClose(this.state.detailedCost);
    }

    _onClose() {
        this.actualSeedCost = 0;
        this.actualChemicalCost = 0;
        this.actualFertilizerCost = 0;
        const { originalDetailedCost } = this.state;
        const { onClose } = this.props;
        onClose(originalDetailedCost);
    }

    _calculateTotal() {
        let _total = 0;
        const { detailedCost } = this.state;
        Object.entries(detailedCost).forEach(([key, value]) => {
            if (!excludedFields.includes(key)) {
                _total = Number(_total) + Number(value);
            }
        });
        this.setState({
            detailedCost: {
                ...this.state.detailedCost,
                total: _total,
            },
        });
    }

    _updateDetailedCost(propName, value) {
        this.setState(
            {
                detailedCost: {
                    ...this.state.detailedCost,
                    [propName]: Number(value),
                },
            },
            () => this._calculateTotal()
        );
    }

    _updateProfitLossWithActual(propName, isChecked, usePropName, costValue) {
        let actualValue = 0;
        const { applicationCost } = this.props;
        if (isChecked && applicationCost) {
            actualValue = Number(costValue);
        }
        this.setState({
            detailedCost: {
                ...this.state.detailedCost,
                [propName]: actualValue,
                [usePropName]: isChecked,
            },
        });
    }

    getActualCost(costValues) {
        if (costValues) {
            if (costValues.actualSeedCost) {
                this.actualSeedCost = Number(costValues.actualSeedCost);
            }
            if (costValues.actualChemicalCost) {
                this.actualChemicalCost = Number(costValues.actualChemicalCost);
            }
            if (costValues.actualFertilizerCost) {
                this.actualFertilizerCost = Number(costValues.actualFertilizerCost);
            }
        }
    }

    render() {
        const { detailedCost } = this.state;
        const { formatMessage } = this.props.intl;
        const { applicationCost, isLoading } = this.props;
        if (applicationCost) {
            this.getActualCost(applicationCost);
        }
        return (
            <DialogBox
                draggable
                isOpen
                unrestricted
                className="break-even-config-modal"
                footerType={DialogBoxFooterType.ACTION_CANCEL}
                actionDisabled={!this._saveEnabled()}
                onAction={() => this._onModalSave()}
                onClose={() => this._onClose()}
                title={"Detailed Cost"}
            >
                <div className="grid-container">
                    <div className="grid-child">
                        <div className="table-detailed">
                            <div className="row-detailed">
                                <div>
                                    <div className="title-text-cell header-theme-title">
                                        {formatMessage(messages.profitLossDetailedCostPerAcreLbl)}
                                    </div>
                                    <div className="title-cell header-theme-title">
                                        {formatMessage(messages.profitLossDetailedActualValueLbl)}
                                    </div>
                                    <div className="title-cell header-theme-title">
                                        {formatMessage(messages.profitLossDetailedUseLbl)}
                                    </div>
                                </div>
                            </div>
                            <div className="row-detailed">
                                <div className="text-cell">
                                    {formatMessage(messages.profitLossDetailedSeedLbl)}
                                </div>
                                <div className="cell">
                                    <NumericInput
                                        containerClassNames={["detailed-profit-loss-input"]}
                                        scale={2}
                                        precision={9}
                                        defaultValue={0}
                                        value={detailedCost.seed}
                                        showTopLabel={false}
                                        onChange={(numValue) =>
                                            this._updateDetailedCost("seed", numValue)
                                        }
                                    />
                                </div>
                                <div className="cell actual-val">
                                    {this.actualSeedCost.toFixed(2)}
                                </div>
                                <div className="use-cell">
                                    <Checkbox
                                        className="transplant-checkbox"
                                        value={detailedCost.useSeed}
                                        disabled={this.actualSeedCost === 0}
                                        onChange={(evt, value) =>
                                            this._updateProfitLossWithActual(
                                                PROP_SEED,
                                                value,
                                                "useSeed",
                                                this.actualSeedCost
                                            )
                                        }
                                    />
                                </div>
                            </div>
                            <div className="row-detailed">
                                <div className="text-cell">
                                    {formatMessage(messages.profitLossDetailedFertilizerLbl)}
                                </div>
                                <div className="cell">
                                    <NumericInput
                                        containerClassNames={["detailed-profit-loss-input"]}
                                        scale={2}
                                        precision={9}
                                        defaultValue={0}
                                        value={detailedCost.fertilizer}
                                        showTopLabel={false}
                                        onChange={(numValue) =>
                                            this._updateDetailedCost("fertilizer", numValue)
                                        }
                                    />
                                </div>
                                <div className="cell actual-val">
                                    {this.actualFertilizerCost?.toFixed(2)}
                                </div>
                                <div className="use-cell">
                                    <Checkbox
                                        className="transplant-checkbox"
                                        value={detailedCost.useFertilizer}
                                        disabled={this.actualFertilizerCost === 0}
                                        onChange={(evt, value) =>
                                            this._updateProfitLossWithActual(
                                                PROP_FERTILIZER,
                                                value,
                                                "useFertilizer",
                                                this.actualFertilizerCost
                                            )
                                        }
                                    />
                                </div>
                            </div>
                            <div className="row-detailed">
                                <div className="text-cell">
                                    {formatMessage(messages.profitLossDetailedChemicalLbl)}
                                </div>
                                <div className="cell">
                                    <NumericInput
                                        containerClassNames={["detailed-profit-loss-input"]}
                                        scale={2}
                                        precision={9}
                                        defaultValue={0}
                                        value={detailedCost.chemical}
                                        showTopLabel={false}
                                        onChange={(numValue) =>
                                            this._updateDetailedCost("chemical", numValue)
                                        }
                                    />
                                </div>
                                <div className="cell actual-val">
                                    {this.actualChemicalCost?.toFixed(2)}
                                </div>
                                <div className="use-cell">
                                    <Checkbox
                                        className="transplant-checkbox"
                                        value={detailedCost.useChemical}
                                        disabled={this.actualChemicalCost === 0}
                                        onChange={(evt, value) =>
                                            this._updateProfitLossWithActual(
                                                PROP_CHEMICAL,
                                                value,
                                                "useChemical",
                                                this.actualChemicalCost
                                            )
                                        }
                                    />
                                </div>
                            </div>
                            <div className="row-detailed">
                                <div className="text-cell">
                                    {formatMessage(messages.profitLossDetailedIrrigationLbl)}
                                </div>
                                <div className="cell">
                                    <NumericInput
                                        containerClassNames={["detailed-profit-loss-input"]}
                                        scale={2}
                                        precision={9}
                                        defaultValue={0}
                                        value={detailedCost.irrigation}
                                        showTopLabel={false}
                                        onChange={(numValue) =>
                                            this._updateDetailedCost("irrigation", numValue)
                                        }
                                    />
                                </div>
                            </div>
                            <div className="row-detailed">
                                <div className="text-cell">
                                    {formatMessage(messages.profitLossDetailedHarvestLbl)}
                                </div>
                                <div className="cell">
                                    <NumericInput
                                        containerClassNames={["detailed-profit-loss-input"]}
                                        scale={2}
                                        precision={9}
                                        defaultValue={0}
                                        value={detailedCost.harvest}
                                        showTopLabel={false}
                                        onChange={(numValue) =>
                                            this._updateDetailedCost("harvest", numValue)
                                        }
                                    />
                                </div>
                            </div>
                            <div className="row-detailed">
                                <div className="text-cell">
                                    {formatMessage(messages.profitLossDetailedEquipmentLbl)}
                                </div>
                                <div className="cell">
                                    <NumericInput
                                        containerClassNames={["detailed-profit-loss-input"]}
                                        scale={2}
                                        precision={9}
                                        defaultValue={0}
                                        value={detailedCost.equipment}
                                        showTopLabel={false}
                                        onChange={(numValue) =>
                                            this._updateDetailedCost("equipment", numValue)
                                        }
                                    />
                                </div>
                            </div>
                            <div className="row-detailed">
                                <div className="text-cell">
                                    {formatMessage(messages.profitLossDetailedLaborLbl)}
                                </div>
                                <div className="cell">
                                    <NumericInput
                                        containerClassNames={["detailed-profit-loss-input"]}
                                        scale={2}
                                        precision={9}
                                        defaultValue={0}
                                        value={detailedCost.labor}
                                        showTopLabel={false}
                                        onChange={(numValue) =>
                                            this._updateDetailedCost("labor", numValue)
                                        }
                                    />
                                </div>
                            </div>
                            <div className="row-detailed">
                                <div className="text-cell">
                                    {formatMessage(messages.profitLossDetailedCropInsuranceLbl)}
                                </div>
                                <div className="cell">
                                    <NumericInput
                                        containerClassNames={["detailed-profit-loss-input"]}
                                        scale={2}
                                        precision={9}
                                        defaultValue={0}
                                        value={detailedCost.cropInsurance}
                                        showTopLabel={false}
                                        onChange={(numValue) =>
                                            this._updateDetailedCost("cropInsurance", numValue)
                                        }
                                    />
                                </div>
                            </div>
                            <div className="row-detailed">
                                <div className="text-cell">
                                    {formatMessage(messages.profitLossDetailedIntOpCapitalLbl)}
                                </div>
                                <div className="cell">
                                    <NumericInput
                                        containerClassNames={["detailed-profit-loss-input"]}
                                        scale={2}
                                        precision={9}
                                        defaultValue={0}
                                        value={detailedCost.interestOperatingCapital}
                                        showTopLabel={false}
                                        onChange={(numValue) =>
                                            this._updateDetailedCost(
                                                "interestOperatingCapital",
                                                numValue
                                            )
                                        }
                                    />
                                </div>
                            </div>
                            <div className="row-detailed">
                                <div className="text-cell">
                                    {formatMessage(messages.profitLossDetailedMiscLbl)}
                                </div>
                                <div className="cell">
                                    <NumericInput
                                        containerClassNames={["detailed-profit-loss-input"]}
                                        scale={2}
                                        precision={9}
                                        defaultValue={0}
                                        value={detailedCost.miscellaneous}
                                        showTopLabel={false}
                                        onChange={(numValue) =>
                                            this._updateDetailedCost("miscellaneous", numValue)
                                        }
                                    />
                                </div>
                            </div>
                            <div className="row-detailed">
                                <div className="text-cell">
                                    {formatMessage(messages.profitLossDetailedLandLbl)}
                                </div>
                                <div className="cell">
                                    <NumericInput
                                        containerClassNames={["detailed-profit-loss-input"]}
                                        scale={2}
                                        precision={9}
                                        defaultValue={0}
                                        value={detailedCost.land}
                                        showTopLabel={false}
                                        onChange={(numValue) =>
                                            this._updateDetailedCost("land", numValue)
                                        }
                                    />
                                </div>
                            </div>
                            <div className="row-detailed">
                                <div className="text-cell total">
                                    {formatMessage(messages.profitLossDetailedTotalLbl)}
                                </div>
                                <div className="cell total-value">
                                    {detailedCost.total === 0 ? (
                                        <div className="init-no-value">-</div>
                                    ) : (
                                        <div className="total-center">
                                            {" "}
                                            {"$ " +
                                                detailedCost.total
                                                    .toFixed(2)
                                                    .replace(/[.,]00$/, "")}{" "}
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="row-detailed">
                                <div className="text-cell">
                                    {formatMessage(messages.profitLossDetailedNonCropIncomeLbl)}
                                </div>
                                <div className="cell">
                                    <NumericInput
                                        containerClassNames={["detailed-profit-loss-input"]}
                                        scale={2}
                                        precision={9}
                                        defaultValue={0}
                                        value={detailedCost.nonCropIncome}
                                        showTopLabel={false}
                                        onChange={(numValue) =>
                                            this._updateDetailedCost("nonCropIncome", numValue)
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="grid-child"></div>
                </div>
                {isLoading ? <Loader /> : null}
            </DialogBox>
        );
    }
}
const mapStateToProps = (state, ownProps) => {
    const { fieldGuid } = ownProps;
    const applicationCost = pfselectors.getActualValues(state);
    const isLoading = pfselectors.getIsLoading(state);
    return {
        applicationCost,
        isLoading,
        fieldGuid,
    };
};
export const ProfitLossDetailedConfigModel = connect(mapStateToProps)(
    injectIntl(ProfitLossDetailedConfigModal_)
);
