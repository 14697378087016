import React, { PureComponent } from "react";
import { connect } from "react-redux";
import convert from "convert-units";
import { debounce } from "lodash";

import { defineMessages, injectIntl, intlShape } from "react-intl";

import {
    DialogBox,
    DialogBoxFooterType,
    RadioButtonGroup,
    RadioButton,
    NumericInput,
    SelectInput,
} from "~/core";

import { mapToolsActions } from "~/map";
import { ISelectOption, Toolset } from "@ai360/core";
import { ACTIVE_YN } from "~/core/picklist";
import { logFirebaseEvent } from "~/utils/firebase";

import { actions as recsEventsActions, eventsModels as em, eventsSelectors } from "~/recs-events";

import "./place-grid-modal.css";

const messages = defineMessages({
    sampleSoilGridAreaOutOfRange: {
        id: "eventModule.eventInfo.sampleSoilGridAreaOutOfRange",
        defaultMessage:
            "A Grid size between {gridMinAreaAcres, number} " +
            "and {gridMaxAreaAcres, number} acres is recommended to avoid performance issues.",
    },
    sampleSoilGridTabTxt: {
        id: "eventModule.eventInfo.sampleSoilGridTabTxt",
        defaultMessage: "Grid",
    },
    sampleSoilGridMethodLblTxt: {
        id: "eventModule.eventInfo.sampleSoilGridMethodLblTxt",
        defaultMessage: "Method:",
    },
    sampleSoilGridPlacementLblTxt: {
        id: "eventModule.eventInfo.sampleSoilGridPlacementLblTxt",
        defaultMessage: "Placement:",
    },
    sampleSoilGridAreaLblTxt: {
        id: "eventModule.eventInfo.sampleSoilGridAreaLblTxt",
        defaultMessage: "Area",
    },
    sampleSoilGridCentroidLblTxt: {
        id: "eventModule.eventInfo.sampleSoilGridCentroidLblTxt",
        defaultMessage: "Centroid",
    },
    sampleSoilGridDimensionsLblTxt: {
        id: "eventModule.eventInfo.sampleSoilGridDimensionsLblTxt",
        defaultMessage: "Dimensions",
    },
    sampleSoilGridHeightLblTxt: {
        id: "eventModule.eventInfo.sampleSoilGridHeightLblTxt",
        defaultMessage: "Height",
    },
    sampleSoilGridRandomLblTxt: {
        id: "eventModule.eventInfo.sampleSoilGridRandomLblTxt",
        defaultMessage: "Random",
    },
    sampleSoilPlacePointsBtnTxt: {
        id: "eventModule.eventInfo.sampleSoilPlacePointsBtnTxt",
        defaultMessage: "Place Points",
    },
    sampleSoilGridModalInstructions: {
        id: "eventModule.eventInfo.sampleSoilGridModalInstructions",
        defaultMessage: "Adjust the Grid by dragging to the desired location.",
    },
    sampleSoilGridModalInstructions2: {
        id: "eventModule.eventInfo.sampleSoilGridModalInstructions2",
        defaultMessage: "To rotate Grid right-click (or ctrl + left-click) and drag.",
    },
    sampleSoilGridModalTitle: {
        id: "eventModule.eventInfo.sampleSoilGridModalTitle",
        defaultMessage: "Grid Setup",
    },
    sampleSoilGridSizeLblTxt: {
        id: "eventModule.eventInfo.sampleSoilGridSizeLblTxt",
        defaultMessage: "Size",
    },
    sampleSoilGridUnitLblTxt: {
        id: "eventModule.eventInfo.sampleSoilGridUnitLblTxt",
        defaultMessage: "Unit",
    },
    sampleSoilGridWidthLblTxt: {
        id: "eventModule.eventInfo.sampleSoilGridWidthLblTxt",
        defaultMessage: "Width",
    },
});

const getDefaultAreaUnitGuid = (unitOptions) => {
    const acresOption = unitOptions.find((o) => o.label === "ac");
    if (acresOption != null) {
        return acresOption.value;
    }
    return unitOptions[0].value;
};

const getDefaultLengthUnitGuid = (unitOptions) => {
    const ftOption = unitOptions.find((o) => o.label === "ft");
    if (ftOption != null) {
        return ftOption.value;
    }
    return unitOptions[0].value;
};

interface IPlaceGridModalProps {
    agEventModel: em.SampleSetup;
    clearGridOnCancel: boolean;
    gridMaxAreaAcres: number;
    gridMinAreaAcres: number;
    intl: intlShape;
    onClose: (isAction: boolean) => void;
    onEnableGridToolset: () => void;
    onSetGridStandardUnitSettings: (settings) => void;
    onUpdateSamplingAgEventModel: (newProps) => void;
    setGridOrientationSettings: (settings) => void;
    unitOptionsArea: ISelectOption<string>[];
    unitOptionsLength: ISelectOption<string>[];
}
interface IPlaceGridModalState {
    lastSelectedAreaUnitGuid: string;
    lastSelectedLengthUnitGuid: string;
    originalGridSettings: any;
}
export class PlaceGridModal_ extends PureComponent<IPlaceGridModalProps, IPlaceGridModalState> {
    constructor(props) {
        super(props);
        this.state = {
            lastSelectedAreaUnitGuid:
                props.agEventModel.areaDimension === em.AreaDimension.AREA
                    ? props.agEventModel.gridUnitGuid
                    : getDefaultAreaUnitGuid(props.unitOptionsArea),
            lastSelectedLengthUnitGuid:
                props.agEventModel.areaDimension === em.AreaDimension.DIMENSION
                    ? props.agEventModel.gridUnitGuid
                    : getDefaultLengthUnitGuid(props.unitOptionsLength),

            originalGridSettings: null,
        };
    }

    componentDidMount() {
        this.setState({
            originalGridSettings: {
                offsetX: this.props.agEventModel.offsetX,
                offsetY: this.props.agEventModel.offsetY,
                rotation: this.props.agEventModel.rotation,
                areaAcres: this.props.agEventModel.areaAcres,
                cellHeightFt: this.props.agEventModel.cellHeightFt,
                cellWidthFt: this.props.agEventModel.cellWidthFt,
            },
        });
    }

    _getIsAreaOutOfRange(agEventModel) {
        const { gridMaxAreaAcres, gridMinAreaAcres } = this.props;

        if (agEventModel == null) {
            agEventModel = this.props.agEventModel;
        }

        const { areaDimension, height, gridSize, width } = agEventModel;
        if (
            areaDimension === em.AreaDimension.AREA &&
            (typeof gridSize !== "number" || isNaN(gridSize))
        ) {
            return true;
        }
        if (
            areaDimension === em.AreaDimension.DIMENSION &&
            (typeof height !== "number" ||
                typeof width !== "number" ||
                isNaN(height) ||
                isNaN(width))
        ) {
            return true;
        }

        const specifiedAreaAc = this._getCurrentAreaAc(agEventModel);
        return specifiedAreaAc < gridMinAreaAcres || specifiedAreaAc > gridMaxAreaAcres;
    }

    _getAreaUnitFromGuid(gridUnitGuid) {
        const { agEventModel, unitOptionsArea, unitOptionsLength } = this.props;

        if (gridUnitGuid == null) {
            gridUnitGuid = agEventModel.gridUnitGuid;
        }

        const fromUnitOption = unitOptionsArea.find((o) => o.value === gridUnitGuid);
        if (fromUnitOption == null) {
            if (unitOptionsLength.find((o) => o.value === gridUnitGuid) == null) {
                console.error("Invalid `gridUnitGuid`", gridUnitGuid);
            }
            return "ac";
        }
        const m = fromUnitOption.label.match(/^(sq )?(.+)$/);
        return m[1] === "sq " ? `${m[2]}2` : m[0];
    }

    _getCurrentAreaAc(agEventModel) {
        if (agEventModel == null) {
            agEventModel = this.props.agEventModel;
        }
        const { areaDimension, height, gridSize, width } = agEventModel;

        if (areaDimension === em.AreaDimension.AREA) {
            const fromUnit = this._getAreaUnitFromGuid(null);
            return convert(gridSize).from(fromUnit).to("ac");
        }

        console.assert(areaDimension === em.AreaDimension.DIMENSION);
        const fromUnit = this._getLengthUnitFromGuid(null);

        const widthFt = convert(width).from(fromUnit).to("ft");
        const heightFt = convert(height).from(fromUnit).to("ft");

        return convert(widthFt * heightFt)
            .from("ft2")
            .to("ac");
    }

    _getDimensionsPart() {
        const {
            agEventModel,
            gridMaxAreaAcres,
            gridMinAreaAcres,
            unitOptionsArea,
            unitOptionsLength,
        } = this.props;
        const { formatMessage } = this.props.intl;

        const areaOutOfRange = this._getIsAreaOutOfRange(null);
        const outOfRangeMsg = !areaOutOfRange ? null : (
            <div className="out-of-range-msg">
                {formatMessage(messages.sampleSoilGridAreaOutOfRange, {
                    gridMinAreaAcres,
                    gridMaxAreaAcres,
                })}
            </div>
        );

        if (agEventModel.areaDimension === em.AreaDimension.AREA) {
            return (
                <div className="dimensions">
                    <div className="dim-input">
                        <NumericInput
                            onChange={(v, f, numVal) =>
                                this._updateGridDimensions({ gridSize: numVal })
                            }
                            containerClassNames={[{ "form-input-error": areaOutOfRange }]}
                            placeholderText={formatMessage(messages.sampleSoilGridSizeLblTxt)}
                            precision={9}
                            scale={3}
                            value={agEventModel.gridSize}
                        />
                        <SelectInput
                            clearable={false}
                            optionIsHiddenKey={ACTIVE_YN}
                            onChange={(val) => this._setGridUnit(val)}
                            placeholderText={formatMessage(messages.sampleSoilGridUnitLblTxt)}
                            options={unitOptionsArea}
                            value={agEventModel.gridUnitGuid}
                        />
                    </div>
                    {outOfRangeMsg}
                </div>
            );
        }
        console.assert(agEventModel.areaDimension === em.AreaDimension.DIMENSION);
        return (
            <div className="dimensions">
                <div className="dim-input">
                    <div>
                        <NumericInput
                            onChange={(v, f, numVal) =>
                                this._updateGridDimensions({ width: numVal })
                            }
                            containerClassNames={[{ "form-input-error": areaOutOfRange }]}
                            placeholderText={formatMessage(messages.sampleSoilGridWidthLblTxt)}
                            precision={9}
                            scale={2}
                            value={agEventModel.width}
                        />
                    </div>
                    <div>
                        <NumericInput
                            onChange={(v, f, numVal) =>
                                this._updateGridDimensions({ height: numVal })
                            }
                            containerClassNames={[{ "form-input-error": areaOutOfRange }]}
                            placeholderText={formatMessage(messages.sampleSoilGridHeightLblTxt)}
                            precision={9}
                            scale={2}
                            value={agEventModel.height}
                        />
                    </div>
                    <SelectInput
                        clearable={false}
                        disabled={true}
                        optionIsHiddenKey={ACTIVE_YN}
                        onChange={(val) => this._setGridUnit(val)}
                        placeholderText={formatMessage(messages.sampleSoilGridUnitLblTxt)}
                        options={unitOptionsLength}
                        value={getDefaultLengthUnitGuid(unitOptionsLength)}
                    />
                </div>
                {outOfRangeMsg}
            </div>
        );
    }

    _getInstructionsPart() {
        const { formatMessage } = this.props.intl;
        return (
            <div className="instructions">
                <div>{formatMessage(messages.sampleSoilGridModalInstructions)}</div>
                <div>{formatMessage(messages.sampleSoilGridModalInstructions2)}</div>
            </div>
        );
    }

    _getLastSelectedAreaUnit() {
        return {
            gridUnitGuid: this.state.lastSelectedAreaUnitGuid,
            toUnit: this._getAreaUnitFromGuid(this.state.lastSelectedAreaUnitGuid),
        };
    }

    _getLastSelectedLengthUnit() {
        return {
            gridUnitGuid: getDefaultLengthUnitGuid(this.props.unitOptionsLength),
            toUnit: "ft",
        };
    }

    _getLengthUnitFromGuid(gridUnitGuid) {
        const { unitOptionsArea, unitOptionsLength } = this.props;

        if (gridUnitGuid == null) {
            gridUnitGuid = getDefaultLengthUnitGuid(unitOptionsLength);
        }

        const fromUnitOption = unitOptionsLength.find((o) => o.value === gridUnitGuid);
        if (fromUnitOption == null) {
            if (unitOptionsArea.find((o) => o.value === gridUnitGuid) == null) {
                console.error("Invalid `gridUnitGuid`", gridUnitGuid);
            }
            return "ft";
        }
        return fromUnitOption.label;
    }

    _getRadioBtnGroupPart() {
        const { agEventModel, onUpdateSamplingAgEventModel } = this.props;
        const { formatMessage } = this.props.intl;

        const setPointPlacement = (val) => {
            if (agEventModel.pointPlacement !== val) {
                val === "C" && logFirebaseEvent("sampling_grid_centroid");
                val === "R" && logFirebaseEvent("sampling_grid_random");
                onUpdateSamplingAgEventModel({ pointPlacement: val });
            }
        };

        return (
            <div className="radio-btn-groups">
                <RadioButtonGroup
                    className="radio-grp"
                    selectedValue={agEventModel.pointPlacement}
                    afterOnChange={setPointPlacement}
                >
                    <div className="radio-grp-lbl">
                        {formatMessage(messages.sampleSoilGridPlacementLblTxt)}
                    </div>
                    <RadioButton
                        value={em.PointPlacement.CENTROID}
                        label={formatMessage(messages.sampleSoilGridCentroidLblTxt)}
                    />
                    <RadioButton
                        value={em.PointPlacement.RANDOM}
                        label={formatMessage(messages.sampleSoilGridRandomLblTxt)}
                    />
                </RadioButtonGroup>

                <RadioButtonGroup
                    className="radio-grp"
                    selectedValue={agEventModel.areaDimension}
                    afterOnChange={(val) => this._setAreaDimension(val)}
                >
                    <div className="radio-grp-lbl">
                        {formatMessage(messages.sampleSoilGridMethodLblTxt)}
                    </div>
                    <RadioButton
                        value={em.AreaDimension.AREA}
                        label={formatMessage(messages.sampleSoilGridAreaLblTxt)}
                    />
                    <RadioButton
                        value={em.AreaDimension.DIMENSION}
                        label={formatMessage(messages.sampleSoilGridDimensionsLblTxt)}
                    />
                </RadioButtonGroup>
            </div>
        );
    }

    _getStandardGridDimensions() {
        const { agEventModel } = this.props;
        const { areaDimension, height, gridSize, width } = agEventModel;

        if (areaDimension === em.AreaDimension.AREA) {
            const fromUnit = this._getAreaUnitFromGuid(null);
            const gridSizeFtSq = convert(gridSize).from(fromUnit).to("ft2");
            return { gridSizeFtSq };
        }

        console.assert(areaDimension === em.AreaDimension.DIMENSION);
        const heightFt = height;
        const widthFt = width;
        return { heightFt, widthFt };
    }

    _setAreaDimension(newAreaDimension) {
        const { agEventModel } = this.props;
        if (agEventModel.areaDimension === newAreaDimension) {
            return;
        }

        newAreaDimension === "A" && logFirebaseEvent("sampling_grid_area");
        newAreaDimension === "D" && logFirebaseEvent("sampling_grid_dimensions");

        let { height, gridSize, width } = agEventModel;

        if (newAreaDimension === em.AreaDimension.AREA) {
            // Changing from width/height to area.
            const { gridUnitGuid, toUnit } = this._getLastSelectedAreaUnit();

            const { heightFt, widthFt } = this._getStandardGridDimensions();
            gridSize = convert(heightFt * widthFt)
                .from("ft2")
                .to(toUnit);

            this._updateGridDimensions({
                areaDimension: newAreaDimension,
                gridSize,
                gridUnitGuid,
            });
            return;
        }

        // Changing from area to width/height
        console.assert(newAreaDimension === em.AreaDimension.DIMENSION);

        const { gridUnitGuid, toUnit } = this._getLastSelectedLengthUnit();
        const { gridSizeFtSq } = this._getStandardGridDimensions();
        const widthHeightRatio = Math.pow(width / height, 0.5);
        height = convert(Math.pow(gridSizeFtSq, 0.5) / widthHeightRatio)
            .from("ft")
            .to(toUnit);
        width = convert(Math.pow(gridSizeFtSq, 0.5) * widthHeightRatio)
            .from("ft")
            .to(toUnit);
        this._updateGridDimensions({
            areaDimension: newAreaDimension,
            gridUnitGuid,
            height,
            width,
        });
    }

    _setGridUnit(gridUnitGuid) {
        const { agEventModel } = this.props;
        let { height, gridSize, width } = agEventModel;

        if (agEventModel.areaDimension === em.AreaDimension.AREA) {
            const fromUnit = this._getAreaUnitFromGuid(null);
            const toUnit = this._getAreaUnitFromGuid(gridUnitGuid);
            gridSize = convert(gridSize).from(fromUnit).to(toUnit);

            this.setState({ lastSelectedAreaUnitGuid: gridUnitGuid }, () =>
                this._updateGridDimensions({ gridSize, gridUnitGuid })
            );
            return;
        }

        console.assert(agEventModel.areaDimension === em.AreaDimension.DIMENSION);
        const fromUnit = this._getLengthUnitFromGuid(null);
        const toUnit = this._getLengthUnitFromGuid(gridUnitGuid);
        height = convert(height).from(fromUnit).to(toUnit);
        width = convert(width).from(fromUnit).to(toUnit);

        this.setState({ lastSelectedLengthUnitGuid: gridUnitGuid }, () =>
            this._updateGridDimensions({ height, width, gridUnitGuid })
        );
    }

    _updateGridDimensions = debounce((newProps) => {
        const { agEventModel, onUpdateSamplingAgEventModel, onSetGridStandardUnitSettings } =
            this.props;

        onUpdateSamplingAgEventModel(newProps);

        const newAgEventModel = { ...agEventModel, ...newProps };

        if (newAgEventModel.areaDimension === em.AreaDimension.AREA) {
            const fromUnit = this._getAreaUnitFromGuid(newAgEventModel.gridUnitGuid);
            onSetGridStandardUnitSettings({
                areaAcres: convert(newAgEventModel.gridSize).from(fromUnit).to("ac"),
            });
            return;
        }

        console.assert(newAgEventModel.areaDimension === em.AreaDimension.DIMENSION);
        const fromUnit = this._getLengthUnitFromGuid(null);
        onSetGridStandardUnitSettings({
            cellHeightFt: convert(newAgEventModel.height).from(fromUnit).to("ft"),
            cellWidthFt: convert(newAgEventModel.width).from(fromUnit).to("ft"),
        });
    }, 700);

    UNSAFE_componentWillMount() {
        this.props.onEnableGridToolset();
    }

    render() {
        const { onClose, setGridOrientationSettings } = this.props;
        const { formatMessage } = this.props.intl;

        return (
            <DialogBox
                draggable
                forceOverflow
                isOpen
                unrestricted
                action={formatMessage(messages.sampleSoilPlacePointsBtnTxt)}
                className="place-grid-modal"
                footerType={DialogBoxFooterType.ACTION_CANCEL}
                onAction={() => onClose(true)}
                onClose={() => {
                    setGridOrientationSettings(this.state.originalGridSettings);
                    onClose(false);
                }}
                title={formatMessage(messages.sampleSoilGridModalTitle)}
            >
                {this._getRadioBtnGroupPart()}
                {this._getDimensionsPart()}
                {this._getInstructionsPart()}
            </DialogBox>
        );
    }
}

const mapDispatchToProps = (dispatch) => ({
    onEnableGridToolset: (gridOrientationSettings) => {
        dispatch(recsEventsActions.setGridOrientationSettings(gridOrientationSettings));
        dispatch(mapToolsActions.setActiveToolset(Toolset.SAMPLING_GRID));
    },
    setGridOrientationSettings: (gridOrientationSettings) => {
        dispatch(recsEventsActions.setGridOrientationSettings(gridOrientationSettings));
    },
    onEnableSamplingToolset: (placePoints, clearGrid) =>
        dispatch(
            mapToolsActions.setActiveToolset(Toolset.SAMPLING, {
                placePoints,
                clearGrid,
            })
        ),
    onSetGridStandardUnitSettings: debounce(
        (gridSettings) => dispatch(recsEventsActions.setGridStandardUnitSettings(gridSettings)),
        1000,
        { leading: true }
    ),
});

const mapStateToProps = (state) => {
    const { gridMaxAreaAcres, gridMinAreaAcres } = eventsSelectors.getModuleState(state);
    return { gridMaxAreaAcres, gridMinAreaAcres };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
    onEnableGridToolset: () =>
        dispatchProps.onEnableGridToolset({
            offsetX: ownProps.agEventModel.offsetX,
            offsetY: ownProps.agEventModel.offsetY,
            rotation: ownProps.agEventModel.rotation,
            areaAcres: ownProps.agEventModel.areaAcres,
            cellHeightFt: ownProps.agEventModel.cellHeightFt,
            cellWidthFt: ownProps.agEventModel.cellWidthFt,
        }),
    onClose: (placePoints) => {
        if (!placePoints) {
            dispatchProps.onEnableSamplingToolset(false, ownProps.clearGridOnCancel);
        } else {
            dispatchProps.onEnableSamplingToolset(ownProps.agEventModel.pointPlacement);
        }
        ownProps.onClose(placePoints);
    },
});

export const PlaceGridModal = connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
)(injectIntl(PlaceGridModal_));
