import { createAction } from "redux-act";

const FETCH_PREFERENCE = "FETCH_PREFERENCE_DATA";
const FETCH_PREFERENCE_SUCCESS = "FETCH_PREFERENCE_SUCCESS";
const FETCH_PREFERENCE_FAILED = "FETCH_PREFERENCE_FAILED";

const SAVE_PREFERENCE = "SAVE_PREFERENCE_DATA";
const SAVE_PREFERENCE_SUCCESS = "SAVE_PREFERENCE_SUCCESS";
const SAVE_PREFERENCE_FAILED = "SAVE_PREFERENCE_FAILED";

const FETCH_COLOR_OVERRIDE = "FETCH_COLOR_OVERRIDE_DATA";
const FETCH_COLOR_OVERRIDE_SUCCESS = "FETCH_COLOR_OVERRIDE_SUCCESS";
const FETCH_COLOR_OVERRIDE_FAILED = "FETCH_COLOR_OVERRIDE_FAILED";

const FETCH_COLOR_OVERRIDE_TYPE = "FETCH_COLOR_OVERRIDE_TYPE_DATA";
const FETCH_COLOR_OVERRIDE_TYPE_SUCCESS = "FETCH_COLOR_OVERRIDE_TYPE_SUCCESS";
const FETCH_COLOR_OVERRIDE_TYPE_FAILED = "FETCH_COLOR_OVERRIDE_TYPE_FAILED";

const SAVE_COLOR_OVERRIDE = "SAVE_COLOR_OVERRIDE_DATA";
const SAVE_COLOR_OVERRIDE_SUCCESS = "SAVE_COLOR_OVERRIDE_SUCCESS";
const SAVE_COLOR_OVERRIDE_FAILED = "SAVE_COLOR_OVERRIDE_FAILED";

const DELETE_COLOR_OVERRIDE = "DELETE_COLOR_OVERRIDE_DATA";
const DELETE_COLOR_OVERRIDE_SUCCESS = "DELETE_COLOR_OVERRIDE_SUCCESS";
const DELETE_COLOR_OVERRIDE_FAILED = "DELETE_COLOR_OVERRIDE_FAILED";

const FETCH_DROPDOWN_DATA_SUCCESS = "FETCH_DROPDOWN_DATA_SUCCESS";

export const savePreference = createAction(SAVE_PREFERENCE);
export const savePreferenceSuccess = createAction(SAVE_PREFERENCE_SUCCESS);
export const savePreferenceFailure = createAction(SAVE_PREFERENCE_FAILED);

export const fetchPreference = createAction(FETCH_PREFERENCE);
export const fetchPreferenceSuccess = createAction(FETCH_PREFERENCE_SUCCESS);
export const fetchPreferenceFailure = createAction(FETCH_PREFERENCE_FAILED);

export const saveColorOverride = createAction(SAVE_COLOR_OVERRIDE);
export const saveColorOverrideSuccess = createAction(SAVE_COLOR_OVERRIDE_SUCCESS);
export const saveColorOverrideFailure = createAction(SAVE_COLOR_OVERRIDE_FAILED);

export const fetchColorOverride = createAction(FETCH_COLOR_OVERRIDE);
export const fetchColorOverrideSuccess = createAction(FETCH_COLOR_OVERRIDE_SUCCESS);
export const fetchColorOverrideFailure = createAction(FETCH_COLOR_OVERRIDE_FAILED);

export const fetchColorOverrideType = createAction(FETCH_COLOR_OVERRIDE_TYPE);
export const fetchColorOverrideTypeSuccess = createAction(FETCH_COLOR_OVERRIDE_TYPE_SUCCESS);
export const fetchColorOverrideTypeFailure = createAction(FETCH_COLOR_OVERRIDE_TYPE_FAILED);

export const deleteColorOverride = createAction(DELETE_COLOR_OVERRIDE);
export const deleteColorOverrideSuccess = createAction(DELETE_COLOR_OVERRIDE_SUCCESS);
export const deleteColorOverrideFailure = createAction(DELETE_COLOR_OVERRIDE_FAILED);

export const fetchDropdownDataSuccess = createAction(FETCH_DROPDOWN_DATA_SUCCESS);

export const SET_DROPDOWN_DATA = "preferences/SET_DROPDOWN_DATA";

export const setDropdownData = (payload) => ({
    type: SET_DROPDOWN_DATA,
    payload,
});
