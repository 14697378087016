import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import classnames from "classnames";

import { Accordion, actions as accordionActions } from "~/accordion";
import { LayerAPI, LayerUtilsAPI } from "@ai360/core";
import { mapActions } from "~/map";

import { LayerItem } from "./layer-item";
import { LegendItem } from "./legend-item";
import { SurfaceItem } from "./surface-item";
import * as actions from "../actions";
import * as selectors from "../selectors";
import { FieldItem } from "../../../../common/accordion/field-accordion-item";
import { messages } from "../../../../context-menus/i18n-messages";

const getAccordionItemEl = ({
    key,
    style,
    accordionItem,
    accordionItemDimIdx,
    isStuck,
    componentProps,
}) => {
    let contentEl;
    if (accordionItemDimIdx.length === 1) {
        const { fieldGuid } = accordionItem.payload;
        const isLoading = componentProps.loadingFieldGuids.has(fieldGuid);
        const hasVisibleSurface = componentProps.visibleSurfaces.has(fieldGuid);
        const hasVisibleSampleSites = componentProps.visibleSampleSites.has(fieldGuid);
        contentEl = (
            <FieldItem
                accordionId={componentProps.accordionId}
                field={accordionItem.payload}
                getMenuItems={componentProps.getFieldMenuItems()}
                hasSelected={hasVisibleSurface || hasVisibleSampleSites}
                hideEventCount={true}
                hideRecCount={true}
                isExpanded={accordionItem.expanded}
                isLoading={isLoading}
                itemDimIdx={accordionItemDimIdx}
                showHasSelected={true}
            />
        );
    } else if (accordionItemDimIdx.length === 2) {
        const parentItem = componentProps.getAccordionItemFromDimIdx(
            accordionItemDimIdx.slice(0, -1)
        );
        const { fieldGuid } = parentItem.payload;
        let hasVisibleSurface = componentProps.visibleSurfaces.has(fieldGuid);
        if (hasVisibleSurface) {
            const surfaceInfo = componentProps.visibleSurfaces.get(fieldGuid);
            hasVisibleSurface = Boolean(
                accordionItem.children.find((c) => {
                    return c.payload.surfaceGuid === surfaceInfo.surfaceGuid;
                })
            );
        }
        let hasVisibleSampleSites = componentProps.visibleSampleSites.has(fieldGuid);
        if (hasVisibleSampleSites) {
            const layerInfo = componentProps.visibleSampleSites.get(fieldGuid);
            hasVisibleSampleSites =
                accordionItem.payload.agEventGeneralGuid === layerInfo.agEventGeneralGuid;
        }
        contentEl = (
            <LayerItem
                accordionId={componentProps.accordionId}
                fieldGuid={fieldGuid}
                hasVisibleSurface={hasVisibleSurface || hasVisibleSampleSites}
                index={accordionItem.payload.index}
                isExpanded={accordionItem.expanded}
                itemDimIdx={accordionItemDimIdx}
                type={accordionItem.payload.type}
            />
        );
    } else if (accordionItemDimIdx.length === 3) {
        const parentFieldItem = componentProps.getAccordionItemFromDimIdx(
            accordionItemDimIdx.slice(0, 1)
        );
        const parentLayerItem = componentProps.getAccordionItemFromDimIdx(
            accordionItemDimIdx.slice(0, -1)
        );
        const isLoading =
            componentProps.loadingSurfaces.has(accordionItem.payload.surfaceGuid) ||
            (accordionItem.payload.surfaceGuid === LayerUtilsAPI.SAMPLE_SITES_GUID &&
                componentProps.loadingSampleSites.has(parentLayerItem.payload.agEventGeneralGuid));
        const layerInfos: LayerAPI.ILayerInfo[] = componentProps.layerInfos.get(
            parentFieldItem.payload.fieldGuid
        );
        const layer = layerInfos == null ? null : layerInfos[accordionItemDimIdx[1]];
        contentEl =
            layer == null ? null : (
                <SurfaceItem
                    accordionId={componentProps.accordionId}
                    field={parentFieldItem.payload}
                    layer={layer}
                    isExpanded={accordionItem.expanded}
                    isLoading={isLoading}
                    itemDimIdx={accordionItemDimIdx}
                    surfaceGuid={accordionItem.payload.surfaceGuid}
                    attributeGuid={accordionItem.payload.attributeGuid}
                    type={parentLayerItem.payload.type}
                />
            );
    } else {
        console.assert(accordionItemDimIdx.length === 4);
        const parentFieldItem = componentProps.getAccordionItemFromDimIdx(
            accordionItemDimIdx.slice(0, 1)
        );
        const parentSurfaceItem = componentProps.getAccordionItemFromDimIdx(
            accordionItemDimIdx.slice(0, -1)
        );
        const isLoading = componentProps.loadingSurfaces.has(parentSurfaceItem.payload.surfaceGuid);
        const layerInfos = componentProps.layerInfos.get(parentFieldItem.payload.fieldGuid);
        contentEl =
            layerInfos == null || layerInfos[accordionItemDimIdx[1]] == null ? null : (
                <LegendItem
                    accordionId={componentProps.accordionId}
                    fieldGuid={parentFieldItem.payload.fieldGuid}
                    isLoading={isLoading}
                    itemDimIdx={accordionItemDimIdx}
                    surfaceGuid={parentSurfaceItem.payload.surfaceGuid}
                    attributeGuid={parentSurfaceItem.payload.attributeGuid}
                />
            );
    }

    return (
        <div key={key} style={style} className={classnames("accordion-item", { sticky: isStuck })}>
            {contentEl}
        </div>
    );
};

getAccordionItemEl.propTypes = {
    key: PropTypes.oneOfType([PropTypes.number, PropTypes.string]), // Unique key within array of rows
    style: PropTypes.object, // Style object to be applied to row (to position it)
    accordionItem: PropTypes.object,
    accordionItemDimIdx: PropTypes.arrayOf(PropTypes.number),
    isStuck: PropTypes.bool,
    componentProps: PropTypes.object, // Pass thru props from <Accordion />
};

const mapDispatchToProps = (dispatch) => ({
    collapseChildren: (accordionId, itemDimIdx) =>
        dispatch(accordionActions.collapseAccordionItemChildren(accordionId, itemDimIdx)),
    expandChildren: (accordionId, itemDimIdx) =>
        dispatch(accordionActions.expandAccordionItemChildren(accordionId, itemDimIdx)),
    onRemoveVisibleSampleSites: (fieldGuid) =>
        dispatch(actions.removeVisibleSampleSites([fieldGuid])),
    onRemoveVisibleSurfaces: (fieldGuid) => dispatch(actions.removeVisibleSurfaces([fieldGuid])),
    unselectAllSurfaces: (accordionId, itemDimIdx) =>
        dispatch(accordionActions.collapseAccordionItemChildren(accordionId, itemDimIdx, [2])),
    onReprocessSoilTypes: (fieldGuid) => dispatch(actions.reprocessSoilTypes(fieldGuid)),
    onScrollTopChanged: (scrollTop) => dispatch(actions.setScrollTop(scrollTop)),
    onSetZoomToField: (fieldGuid) => dispatch(mapActions.setZoomToField(fieldGuid)),
    onSplitScreenCompareOpen: (fieldGuid, surfaceInfo) =>
        dispatch(actions.splitScreenCompareToolOpen(fieldGuid, surfaceInfo)),
});

const mapStateToProps = (state) => ({
    itemCount: selectors.getAccordionRecItemCount(state),
    itemList: selectors.getAccordionItems(state),
    totalHeight: selectors.getAccordionRecHeight(state),
    getAccordionItemFromFlatIdx: (idx) => selectors.getFlatIdxMapSelector(state)(idx),
    getAccordionItemFromDimIdx: (dimIdx) => selectors.getDimIdxMapSelector(state)(dimIdx),
    getAccordionItemEl: getAccordionItemEl,
    scrollTop: selectors.getAccordionScrollTop(state),

    // pass-thru prop; <Accordion /> doesn't care about these but they're passed-thru
    // to `getAccordionItemEl` and `onScrollTopChanged`
    accordionId: selectors.getAccordionId(state),
    layerInfos: selectors.getLayerInfos(state),
    loadingFieldGuids: selectors.getLoadingFieldGuidSet(state),
    loadingSampleSites: selectors.getLoadingSampleSitesSet(state),
    loadingSurfaces: selectors.getLoadingSurfacesSet(state),
    visibleSampleSites: selectors.getVisibleSampleSitesMap(state),
    visibleSurfaces: selectors.getVisibleSurfacesMap(state),
});

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
    getFieldMenuItems:
        () =>
        ({ field, intl, itemDimIdx }) => {
            const { fieldGuid } = field;

            const fieldItem = stateProps.getAccordionItemFromDimIdx(itemDimIdx);
            const hasVisibleSampleSites = stateProps.visibleSampleSites.has(fieldGuid);
            const hasVisibleSurface = stateProps.visibleSurfaces.has(fieldGuid);
            const fieldLayerInfo = stateProps.layerInfos.get(fieldGuid);
            const soilLayer = fieldLayerInfo
                ? fieldLayerInfo.find(
                      (l) => LayerUtilsAPI.getLayerType(l) === LayerUtilsAPI.LayerType.SOIL
                  )
                : undefined;
            let allCollapsed = true;
            let allExpanded = true;
            for (const layer of fieldItem.children) {
                if (layer.expanded) {
                    allCollapsed = false;
                } else {
                    allExpanded = false;
                }
            }
            const items = [
                {
                    key: `${itemDimIdx}_zoom`,
                    label: intl.formatMessage(messages.zoomToText),
                    action: () => dispatchProps.onSetZoomToField(fieldGuid),
                },
                {
                    disabled: allExpanded,
                    key: `${itemDimIdx}_expand`,
                    label: intl.formatMessage(messages.expandAll),
                    action: () => dispatchProps.expandChildren(stateProps.accordionId, itemDimIdx),
                },
                {
                    disabled: allCollapsed,
                    key: `${itemDimIdx}_collapse`,
                    label: intl.formatMessage(messages.collapseAll),
                    action: () =>
                        dispatchProps.collapseChildren(stateProps.accordionId, itemDimIdx),
                },
                {
                    disabled: !hasVisibleSampleSites && !hasVisibleSurface,
                    key: `${itemDimIdx}_clear`,
                    label: intl.formatMessage(messages.clearSelected),
                    action: () => {
                        dispatchProps.onRemoveVisibleSampleSites(fieldGuid);
                        dispatchProps.onRemoveVisibleSurfaces(fieldGuid);
                        dispatchProps.unselectAllSurfaces(stateProps.accordionId, itemDimIdx);
                    },
                },
                {
                    key: `${itemDimIdx}_ssc`,
                    label: intl.formatMessage(messages.splitScreenCompare),
                    action: () => dispatchProps.onSplitScreenCompareOpen(fieldGuid),
                },
            ];

            if (
                fieldLayerInfo &&
                (soilLayer === undefined || soilLayer.subLayers.some((l) => l.isCorrupt))
            ) {
                items.push({
                    key: `${itemDimIdx}_reprocess`,
                    label: intl.formatMessage(messages.reprocessSoilTypes),
                    action: () => dispatchProps.onReprocessSoilTypes(fieldGuid),
                });
            }

            return items;
        },
});

export const LayerAccordion = connect(mapStateToProps, mapDispatchToProps, mergeProps)(Accordion);
