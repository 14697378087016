import React, { PureComponent } from "react";
import { connect } from "react-redux";

import { defineMessages, injectIntl, intlShape } from "react-intl";

import { Checkbox, DialogBox, DialogBoxFooterType } from "~/core";

import { getAutoTraceDefault } from "../selectors";
import { mapToolsActions } from "~/map";
import { eventsSelectors } from "~/recs-events";
import { Toolset } from "@ai360/core";
import { updateAutoTracePreferences } from "../actions";

import "./trace-points-modal.css";

const messages = defineMessages({
    sampleSoilTracePointsModalTitle: {
        id: "eventModule.eventInfo.sampleSoilTracePointsModalTitle",
        defaultMessage: "Trace Points",
    },
    sampleSoilTraceAutoLbl: {
        id: "eventModule.eventInfo.sampleSoilTraceAutoLbl",
        defaultMessage: "Auto Trace",
    },
    sampleSoilTraceCountsMessage: {
        id: "eventModule.eventInfo.sampleSoilTraceCountsMessage",
        defaultMessage:
            "{traceCompleteCount, number} of {traceTotalCount, number} " + "Points Traced",
    },
    sampleSoilTraceApplyTxt: {
        id: "eventModule.eventInfo.sampleSoilTraceApplyTxt",
        defaultMessage: "Apply",
    },
    sampleSoilTraceResetTxt: {
        id: "eventModule.eventInfo.sampleSoilTraceResetTxt",
        defaultMessage: "Reset",
    },
    sampleSoilTraceInstructions: {
        id: "eventModule.eventInfo.sampleSoilTraceInstructions",
        defaultMessage:
            "Trace the first two sample points to give the preferred direction of the auto trace.",
    },
});

interface ITracePointsModalProps {
    autoTraceDefault: boolean;
    intl: intlShape;
    onApplyTrace: () => void;
    onCancelTrace: () => void;
    onDisableTraceToolset: () => void;
    onEnableTraceToolset: (auto) => void;
    onResetTrace: () => void;
    onSetAutoTrace: (auto) => void;
    traceCompleteCount: number;
    traceTotalCount: number;
    traceSites: any[];
}

interface ITracePointsModalState {
    auto: any;
}

export class TracePointsModal_ extends PureComponent<
    ITracePointsModalProps,
    ITracePointsModalState
> {
    constructor(props) {
        super(props);
        this.state = {
            auto: this.props.autoTraceDefault,
        };
    }

    _getOptionsPart() {
        const { formatMessage } = this.props.intl;
        return (
            <div className="trace-options">
                <Checkbox
                    label={formatMessage(messages.sampleSoilTraceAutoLbl)}
                    value={this.state.auto}
                    onChange={(evt, val) => this._setAutoTrace(val)}
                />
            </div>
        );
    }

    _getTraceProgressPart() {
        const { traceCompleteCount, traceTotalCount } = this.props;
        const { formatMessage } = this.props.intl;
        return (
            <div className="trace-counts">
                {formatMessage(messages.sampleSoilTraceCountsMessage, {
                    traceCompleteCount,
                    traceTotalCount,
                })}
            </div>
        );
    }

    _getTraceInstructionsPart() {
        const { formatMessage } = this.props.intl;
        return (
            <div className="trace-instructions">
                {formatMessage(messages.sampleSoilTraceInstructions)}
            </div>
        );
    }
    _setAutoTrace(auto) {
        const { onSetAutoTrace } = this.props;
        this.setState({ auto }, () => onSetAutoTrace(auto));
    }

    UNSAFE_componentWillMount() {
        if (this.props.traceSites.length > 0) {
            this.props.onResetTrace();
        }
        this.props.onEnableTraceToolset(this.state.auto);
    }

    componentWillUnmount() {
        this.props.onDisableTraceToolset();
    }

    render() {
        const { onApplyTrace, onCancelTrace, onResetTrace, traceCompleteCount, traceTotalCount } =
            this.props;
        const { formatMessage } = this.props.intl;

        const actionList = [
            {
                action: formatMessage(messages.sampleSoilTraceApplyTxt),
                actionDisabled: traceCompleteCount !== traceTotalCount,
                onAction: onApplyTrace,
            },
            {
                action: formatMessage(messages.sampleSoilTraceResetTxt),
                actionDisabled: traceCompleteCount === 0,
                onAction: onResetTrace,
            },
        ];
        return (
            <DialogBox
                draggable
                isOpen
                unrestricted
                className="trace-points-modal"
                footerType={DialogBoxFooterType.MULTI_ACTION_CANCEL}
                multiActionList={actionList}
                onClose={onCancelTrace}
                title={formatMessage(messages.sampleSoilTracePointsModalTitle)}
            >
                <div className="trace-progress-options">
                    {this._getTraceProgressPart()}
                    {this._getOptionsPart()}
                </div>
                <div>{this._getTraceInstructionsPart()}</div>
            </DialogBox>
        );
    }
}

const mapDispatchToProps = (dispatch) => ({
    onApplyTrace: () => dispatch(mapToolsActions.applyTrace()),
    onCancelTrace: () => dispatch(mapToolsActions.setActiveToolset(Toolset.SAMPLING)),
    onDisableTraceToolset: () => dispatch(mapToolsActions.setActiveToolset(Toolset.SAMPLING)),
    onEnableTraceToolset: (auto) =>
        dispatch(mapToolsActions.setActiveToolset(Toolset.SAMPLING_TRACE, { auto })),
    onResetTrace: () => dispatch(mapToolsActions.resetTrace()),
    onSetAutoTrace: (auto) => dispatch(updateAutoTracePreferences(auto)),
});

const mapStateToProps = (state) => {
    const { traceCompleteCount, traceTotalCount, traceSites } =
        eventsSelectors.getModuleState(state);
    const autoTraceDefault = !!getAutoTraceDefault(state);
    return {
        autoTraceDefault,
        traceCompleteCount,
        traceTotalCount,
        traceSites,
    };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
    onApplyTrace: () => {
        dispatchProps.onApplyTrace();
        ownProps.onClose();
    },
    onCancelTrace: () => {
        dispatchProps.onCancelTrace();
        ownProps.onClose();
    },
});

export const TracePointsModal = connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
)(injectIntl(TracePointsModal_));
