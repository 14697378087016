import React, { useEffect } from "react";
import { connect } from "react-redux";
import { injectIntl, intlShape } from "react-intl";
import { TextInput, Bucket, BucketHeader, SelectInput, DateInput, Pane, TextArea } from "~/core";
import * as actions from "./actions";
import * as selectors from "./selectors";
import { IErrorCodeList } from "../analysis-info/interfaces";
import { ImageryIcon } from "~/core/icons/imagery";
import {
    actions as picklistActions,
    picklistNames,
    selectors as picklistSelectors,
} from "~/core/picklist";
import { AgEventAPI, NonFieldFeatureAPI, PicklistAPI } from "@ai360/core";

import "./water-sampling-form.css";
import "~/action-panel/components/common/rec-event-info/rec-event-info.css";
import { messages } from "../i18n-messages";

import moment from "moment";
import { RecEventInfo } from "~/action-panel/components/common";
import { RecEventItem } from "~/action-panel/components/common/accordion/rec-event-accordion-item";
import { GeneralPaneLabel } from "~/action-panel/components/common/rec-event-info/rec-event-info";
import { SampleResultsTable } from "~/action-panel/components/common/sampling/sample-results-table";
import {
    ISampleGridData,
    ISampleGridHeader,
    ISampleResults,
} from "~/action-panel/components/common/sampling/models";

class IWaterSamplingProps {
    closeSampling: () => void;
    samplingSummary: AgEventAPI.IAgEventWaterSampling;
    nonFieldFeature: NonFieldFeatureAPI.NonFieldFeature;
    croppingSeasonOptions: PicklistAPI.IPicklistItem[];
    fetchPicklistData: (picklists) => void;
    intl: intlShape;
    isLoading: boolean;
    saveSampling: () => void;
    updateSamplingSummary: (newProps) => void;
}

export const formLabelMessage = messages.imagerySidebar;
export const formLabelIcon = ImageryIcon;

const errorCodeToMessageIdSetMap = new Map([
    [2805, messages.analysisMethodPlaceholderText],
    [2806, messages.analysisDuplicateName],
]);

const SAMPLE_ID = "Sample ID";

export const errorCodesApply = (errorCodeList: IErrorCodeList): number => {
    return errorCodeList.some((errorCode) => errorCodeToMessageIdSetMap.has(errorCode));
};

const WaterSamplingForm = (props: IWaterSamplingProps): JSX.Element => {
    const {
        samplingSummary,
        croppingSeasonOptions,
        fetchPicklistData,
        nonFieldFeature,
        updateSamplingSummary,
    } = props;
    const { formatMessage } = props.intl;

    //state variables

    useEffect(() => {
        fetchPicklistData({
            [picklistNames.PICKLIST_CROPPING_SEASON]: picklistNames.getPickListCode(
                picklistNames.PICKLIST_CROPPING_SEASON
            ),
        });
    }, []);

    const updateSampling = (data) => {
        updateSamplingSummary({ ...data });
    };

    const areAllRequiredFieldsSet = (): boolean => {
        return Boolean(samplingSummary.croppingSeasonGuid && samplingSummary.sampleDate);
    };

    const getPanes = () => {
        const panes = [];
        const genPaneLabel = <GeneralPaneLabel hasError={false} />;

        panes.push(
            <Pane key="general" label={genPaneLabel}>
                <div className="rec-event-info-form water-sampling-info">
                    <Bucket showSymbol={false} isCollapsible={false} isExpanded>
                        <BucketHeader>{formatMessage(messages.analysisFormLabelText)}</BucketHeader>
                        <div className="input-row half">
                            <TextInput
                                required={false}
                                autoFocus={!samplingSummary.eventName}
                                containerClassNames={["water-sampling-input"]}
                                onChange={(v) => updateSampling({ eventName: v })}
                                placeholderText={formatMessage(messages.sampleNamePlaceholder)}
                                value={samplingSummary.eventName}
                                maxLength={50}
                            />
                        </div>
                        <div className="input-row">
                            <SelectInput
                                disabled={false}
                                clearable={false}
                                required={true}
                                containerClassNames={["water-sampling-input"]}
                                onChange={(v) =>
                                    updateSampling({
                                        croppingSeasonGuid: v,
                                    })
                                }
                                options={croppingSeasonOptions}
                                placeholderText={formatMessage(messages.season)}
                                value={samplingSummary.croppingSeasonGuid}
                            />
                            <DateInput
                                required
                                clearDisabled
                                containerClassNames={[]}
                                onChange={(v) =>
                                    updateSampling({
                                        sampleDate: moment(v),
                                    })
                                }
                                openDirection="right"
                                timeFormat={false}
                                placeholderText={formatMessage(messages.sampleDatePlaceholderText)}
                                value={
                                    !samplingSummary.sampleDate
                                        ? null
                                        : moment(samplingSummary.sampleDate)
                                }
                            />
                        </div>
                        <div className="input-row">
                            <TextArea
                                value={samplingSummary.notes || ""}
                                onChange={(v) => updateSampling({ notes: v })}
                                placeholderText={formatMessage(messages.notesPlaceholderText)}
                                maxLength={1000}
                            />
                        </div>
                    </Bucket>
                    <div className="event-sample-water-form">
                        {samplingSummary.importAttributeValues?.length === 0
                            ? null
                            : getResultsTable()}
                    </div>
                </div>
            </Pane>
        );
        return panes;
    };

    const joined = (elements, delimiter) => elements.filter((v) => v && v !== "").join(delimiter);

    const basicSummaryMessage = () => {
        const elements = [nonFieldFeature.customerName, nonFieldFeature.name];

        return joined(elements, ", ");
    };

    const formatSamplingStripText = (samplingSummary, croppingSeason) => {
        const displayNameParts = [
            "Sampling - Water",
            croppingSeason,
            samplingSummary.sampleDate?.format("M/D/YY"),
        ].filter((p) => p);
        return displayNameParts.join(" - ");
    };

    const getStripItem = () => {
        const { croppingSeasonOptions, isLoading, samplingSummary } = props;
        if (isLoading || samplingSummary == null) {
            return null;
        }
        const croppingSeasonOption = croppingSeasonOptions.find(
            (option) => option.value === samplingSummary.croppingSeasonGuid
        );
        const displayText = formatSamplingStripText(samplingSummary, croppingSeasonOption?.label);
        const itemProps = {
            displayName: displayText,
            eventId: [samplingSummary.eventId],
            summary: basicSummaryMessage(),
            contextMenu: null,
            deselectItemsFromDimIdx: () => null,
            isExpanded: false,
            itemDimIdx: [],
            lastClickedDimIdx: [],
            setLastClickedDimIdx: () => null,
            selectItemsFromDimIdx: () => null,
            status: null,
            statusMessageArg: { statusCode: null, isWaterSample: true },
            isSelected: true,
        };

        return (
            <div className="item-container">
                <RecEventItem {...itemProps} />
            </div>
        );
    };

    const getWaterSamplingPoint = () => {
        const samplePoint: AgEventAPI.ISamplePoint = {
            isNew: false,
            sampleId: 1,
            sequenceId: 1,
            shape: null,
        };
        return samplePoint;
    };

    const getGridHeader = (sampleId: string) => {
        const headerTitle: ISampleGridHeader = {
            columnName: SAMPLE_ID,
        };
        const headerSinglePoint: ISampleGridHeader = {
            columnName: sampleId,
        };
        return [headerTitle, headerSinglePoint];
    };

    const getGridData = (sampleId: string) => {
        const sortedImportAttributes = samplingSummary.importAttributeValues
            .filter((v) => v.displayName !== SAMPLE_ID)
            .sort((a, b) => a.displayName.localeCompare(b.displayName));
        const gridData: ISampleGridData[] = sortedImportAttributes.map((v) => {
            return {
                actualAttributeName: v.name,
                sampleAttributeName: v.displayName,
                sampleResultValues: [
                    {
                        samplePointSequenceDepthId: sampleId,
                        sampleResultValue: v.value?.toString(),
                    },
                ],
            };
        });
        return gridData;
    };

    const getSampleResultsGrid = () => {
        const sampleIdAttribute = samplingSummary.importAttributeValues.find(
            (v) => v.displayName === SAMPLE_ID
        );

        const result: ISampleResults = {
            gridData: getGridData(sampleIdAttribute?.value.toString()),
            gridHeader: getGridHeader(sampleIdAttribute?.value.toString()),
        };
        return result;
    };

    const getResultsTable = () => {
        const { intl } = props;

        return (
            <div className="sample-points with-results">
                <SampleResultsTable
                    intl={intl}
                    selectedPointSet={new Set<string>()}
                    samplePoints={[getWaterSamplingPoint()]}
                    sampleResults={getSampleResultsGrid()}
                    onSetPointProdRating={null}
                    pointGuidAttributeName={"waterSamplingGuid"}
                    productivityRatingOptions={[]}
                    showProductivityRating={false}
                />
            </div>
        );
    };

    const onSave = () => {
        props.saveSampling();
    };

    const onClose = () => {
        props.closeSampling();
    };

    return (
        <RecEventInfo
            addItemTitle={""}
            addMenuItems={[]}
            enableSave={areAllRequiredFieldsSet()}
            errorCodeList={[]}
            eventRecItemStrip={getStripItem()}
            informationPanes={getPanes()}
            isBatch={false}
            isBatchTemplate={false}
            isLoading={props.isLoading}
            isNew={false}
            canAddEvent={false}
            onSave={onSave}
            onCancel={onClose}
        />
    );
};

const mapDispatchToProps = (dispatch) => ({
    closeSampling: () => dispatch(actions.closeSampling()),
    saveSampling: () => dispatch(actions.saveSampling()),
    updateSamplingSummary: (newProps) => dispatch(actions.updateSampling(newProps)),
    fetchPicklistData: (pickLists) => dispatch(picklistActions.fetchPicklistData(pickLists)),
});

const mapStateToProps = (state) => {
    const croppingSeasonOptions = picklistSelectors.getPicklistOptionsFromCode(
        state,
        picklistNames.getPickListCode(picklistNames.PICKLIST_CROPPING_SEASON)
    );
    const samplingSummary = selectors.getSampling(state);
    const nonFieldFeature = selectors.getNonFieldFeature(state);
    const isLoading = selectors.getIsLoading(state);
    return {
        croppingSeasonOptions,
        isLoading,
        nonFieldFeature,
        samplingSummary,
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(WaterSamplingForm));
