import Immutable from "immutable";
import { combineReducers } from "redux";
import { ActionType } from "typesafe-actions";

import { accordionReducer } from "~/accordion";

import * as actions from "./actions";
import * as models from "./models";
import * as selectors from "./selectors";

export type FieldListAction = ActionType<typeof actions>;

const initState: models.IFieldListAttrsState = Object.freeze({
    accordionInit: {
        lastClickedDimIdx: [0],
        initScrollTop: 0,
        scrollToFlatIdx: null,
    },
    batchFieldDetails: null,
    collapsingAll: false,
    expandingAll: false,
    manualExpandedCustomers: Immutable.Set<string>(),
    filterSelections: Object.freeze({
        search: null,
        certifiedOrganic: null,
        classifications: [],
        crops: [],
        enrolled: null,
        irrigated: [],
        orgLevelGuid: null,
        salespeople: [],
    }),
    filterCount: 0,
    filterOptions: Object.freeze({
        classifications: [],
        crops: [],
        enrolled: [],
        irrigated: [],
        certifiedOrganic: [],
        salespeople: [],
        displayClassifications: false,
        displayEnrollment: false,
        displayLocation: false,
    }),
    salespersonGuids: Immutable.Map<string, Set<string>>(),
    showBatchEdit: false,
    showFilters: false,
    searchValue: "",
    selectedAccordionInit: {
        lastClickedDimIdx: [0],
        initScrollTop: 0,
        scrollToFlatIdx: null,
    },
});

const updateAccordionInit = (
    state: models.IFieldListAttrsState,
    props: Partial<models.IAccordionInit>
) => {
    return Object.freeze({
        ...state,
        accordionInit: Object.freeze({
            ...state.accordionInit,
            ...props,
        }),
    });
};

const fieldListAttrsReducer = (
    state: models.IFieldListAttrsState = initState,
    action: FieldListAction
) => {
    const { manualExpandedCustomers } = state;
    switch (action.type) {
        case actions.FETCH_FILTERS_SUCCESS: {
            const { options } = action.payload;
            return Object.freeze({
                ...state,
                filterOptions: options,
            });
        }
        case actions.COLLAPSE_ALL_CUSTOMER_ITEMS:
            return Object.freeze({
                ...state,
                collapsingAll: true,
                expandingAll: false,
            });
        case actions.COLLAPSE_CUSTOMER_ITEM:
            return Object.freeze({
                ...state,
                manualExpandedCustomers: manualExpandedCustomers.delete(
                    action.payload.customerGuid
                ),
            });
        case actions.EXPAND_ALL_CUSTOMER_ITEMS:
            return Object.freeze({
                ...state,
                collapsingAll: false,
                expandingAll: true,
            });
        case actions.EXPAND_CUSTOMER_ITEM:
            return Object.freeze({
                ...state,
                manualExpandedCustomers: manualExpandedCustomers.add(action.payload.customerGuid),
            });
        case actions.SET_MANUAL_EXPANDED_CUSTOMERS:
            return Object.freeze({
                ...state,
                manualExpandedCustomers: Immutable.Set(action.payload.customerGuids),
            });
        case actions.SET_FILTER_SELECTIONS: {
            const { filterSelections } = action.payload;
            const newFilterSelections = {
                ...state.filterSelections,
                ...filterSelections,
            };
            const filterCount =
                (newFilterSelections.crops.length ? 1 : 0) +
                (newFilterSelections.classifications.length ? 1 : 0) +
                (newFilterSelections.salespeople.length ? 1 : 0) +
                (newFilterSelections.irrigated.length ? 1 : 0) +
                (newFilterSelections.certifiedOrganic != null ? 1 : 0) +
                (newFilterSelections.enrolled != null ? 1 : 0) +
                (newFilterSelections.orgLevelGuid != null ? 1 : 0);

            return Object.freeze({
                ...state,
                collapsingAll: false,
                filterSelections: newFilterSelections,
                filterCount,
            });
        }
        case actions.UPDATE_BATCH_FIELD_DETAILS: {
            const { newProps } = action.payload;
            return Object.freeze({
                ...state,
                batchFieldDetails: {
                    ...state.batchFieldDetails,
                    ...newProps,
                },
            });
        }
        case actions.SET_LAST_CLICKED_DIM_IDX: {
            const { lastClickedDimIdx } = action.payload;
            return updateAccordionInit(state, { lastClickedDimIdx });
        }
        case actions.SET_SCROLL_TOP: {
            const { initScrollTop } = action.payload;
            return updateAccordionInit(state, { initScrollTop });
        }
        case actions.SET_SCROLL_TO_FLAT_IDX: {
            const { scrollToFlatIdx } = action.payload;
            return updateAccordionInit(state, { scrollToFlatIdx });
        }
        case actions.SET_SHOW_BATCH_EDIT: {
            const { showBatchEdit } = action.payload;
            return Object.freeze({
                ...state,
                showBatchEdit,
            });
        }
        case actions.SET_SHOW_FILTERS: {
            const { showFilters } = action.payload;
            return Object.freeze({
                ...state,
                showFilters,
            });
        }
        case actions.UPDATE_SEARCH: {
            const { searchValue } = action.payload;
            return Object.freeze({
                ...state,
                searchValue: searchValue,
            });
        }
        default:
            return state;
    }
};

export const fieldListReducer = combineReducers({
    [selectors.FIELDS_ACCORDION_KEY]: accordionReducer,
    [selectors.FIELD_LIST_ATTRS_KEY]: fieldListAttrsReducer,
});
