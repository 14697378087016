import { ACTION_PANEL_STATE_KEY } from "../../../../selectors";
import { LAYER_MODULE_STATE_KEY } from "../../selectors";
export const SAMPLING_STATE_KEY = "SAMPLING_STATE";

export const getModuleState = (state) =>
    state[ACTION_PANEL_STATE_KEY][LAYER_MODULE_STATE_KEY][SAMPLING_STATE_KEY];

export const getSampling = (state) => {
    return getModuleState(state).sampling;
};

export const getNonFieldFeature = (state) => {
    return getModuleState(state).nonFieldFeature;
};

export const getIsLoading = (state) => getModuleState(state).isLoading;
