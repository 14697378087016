import { NonFieldFeatureAPI } from "@ai360/core";

export const ADD_SAMPLING = "sampling/ADD_SAMPLING";
export const CLOSE_SAMPLING = "sampling/CLOSE_SAMPLING";
export const DELETE_SAMPLING = "sampling/DELETE_SAMPLING";
export const EDIT_SAMPLING = "sampling/EDIT_SAMPLING";
export const FETCH_SAMPLING_INFO = "sampling/FETCH_SAMPLING_INFO";
export const UPDATE_SAMPLING = "sampling/UPDATE_SAMPLING";
export const SAVE_SAMPLING = "sampling/SAVE_SAMPLING";
export const SET_CUSTOMER = "sampling/SET_CUSTOMER";
export const SET_SAMPLING = "sampling/SET_SAMPLING";
export const SET_SAMPLING_DETAILS_LOADING = "sampling/SET_SAMPLING_DETAILS_LOADING";
export const SET_NON_FIELD_FEATURE = "sampling/SET_NON_FIELD_FEATURE";

export const setSamplingDetailsLoading = (isLoading) => ({
    type: SET_SAMPLING_DETAILS_LOADING,
    payload: { isLoading },
});

export const addSampling = (nonFieldFeature: NonFieldFeatureAPI.NonFieldFeature) => ({
    type: ADD_SAMPLING,
    payload: { nonFieldFeature },
});

export const closeSampling = () => ({
    type: CLOSE_SAMPLING,
});

export const deleteSampling = (
    waterSamplingGuid: string,
    nonFieldFeatureGuid: string,
    customerGuid: string
) => ({
    type: DELETE_SAMPLING,
    payload: { customerGuid, nonFieldFeatureGuid, waterSamplingGuid },
});

export const editSampling = (
    nonFieldFeature: NonFieldFeatureAPI.NonFieldFeature,
    waterSamplingGuid: string
) => ({
    type: EDIT_SAMPLING,
    payload: { nonFieldFeature, waterSamplingGuid },
});

export const fetchSamplingInfo = (waterSamplingGuid: string) => ({
    type: FETCH_SAMPLING_INFO,
    payload: { waterSamplingGuid },
});

export const setNonFieldFeature = (nonFieldFeature: NonFieldFeatureAPI.NonFieldFeature) => ({
    type: SET_NON_FIELD_FEATURE,
    payload: { nonFieldFeature },
});

export const saveSampling = () => ({
    type: SAVE_SAMPLING,
});

export const setSampling = (sampling) => ({
    type: SET_SAMPLING,
    payload: { sampling },
});

export const updateSampling = (newProps) => ({
    type: UPDATE_SAMPLING,
    payload: { newProps },
});
