import React, { Component, MouseEvent } from "react";
import { connect } from "react-redux";

import { defineMessages, InjectedIntl, injectIntl } from "react-intl";

import { Bucket, BucketHeader, Button, Checkbox, Pane, SelectInput, Tabs } from "~/core";

import {
    actions as picklistActions,
    picklistNames,
    selectors as picklistSelectors,
} from "~/core/picklist";

import { ACTIVE_YN } from "~/core/picklist";

import { PICKLIST_CROP_PURPOSE, PICKLIST_PLANT_PART } from "~/core/picklist/picklist-names";

import { mapToolsActions } from "~/map";
import { Toolset, Tool } from "@ai360/core";
import {
    actions as recsEventsActions,
    models as recsEventsModel,
    eventsSelectors,
} from "~/recs-events";

import { getSetValuesForErrorCodeList } from "../../../../../../common/validation-utils";

import { SamplingTissueIcon } from "../../icons";

import * as actions from "../actions";
import { ICropGrowthStage, IPicklistOption, ISampleResults } from "../models";
import * as selectors from "../selectors";

import { SamplePointTable } from "./sample-point-table";
import { SampleResultsTable } from "~/action-panel/components/common/sampling/sample-results-table";

import "../../../../../../common/rec-event-info/rec-event-info.css";
import "./event-sample-tissue-form.css";
import { AgEventAPI } from "@ai360/core";

type ITissueSamplePoint = AgEventAPI.ITissueSamplePoint;

const messages = defineMessages({
    crop: {
        id: "eventModule.eventInfo.crop",
        defaultMessage: "Crop",
    },
    cropClass: {
        id: "eventModule.eventInfo.cropClass",
        defaultMessage: "Crop Class",
    },
    growthStage: {
        id: "eventModule.eventInfo.growthStage",
        defaultMessage: "Crop Growth Stage",
    },
    cropPurpose: {
        id: "eventModule.eventInfo.cropPurpose",
        defaultMessage: "Crop Purpose",
    },
    plantPart: {
        id: "eventModule.eventInfo.plantPart",
        defaultMessage: "Plant Part",
    },
    tissueDetails: {
        id: "eventModule.eventInfo.tissueDetails",
        defaultMessage: "Tissue Details",
    },
    samplePlacePointsTxt: {
        id: "eventModule.eventInfo.samplePlacePointsTxt",
        defaultMessage: "Place Points",
    },
    sampleTissueFormLabelText: {
        id: "eventModule.eventInfo.sampleTissueFormLabelText",
        defaultMessage: "Tissue",
    },
    sampleTissueProductivityRatingTxt: {
        id: "eventModule.eventInfo.sampleTissueProductivityRatingTxt",
        defaultMessage: "Productivity Rating",
    },
});

const { getPickListCode, PICKLIST_NUMERIC_RATING } = picklistNames;

const AUTO_METHOD_LABEL = "Auto";
const MANUAL_METHOD_LABEL = "Manual";

export const formLabelMessage = messages.sampleTissueFormLabelText;
export const formLabelIcon = SamplingTissueIcon;

const errorCodeToMessageIdSetMap = new Map([
    // TODO: the rest of the tissue errors (crop required, etc.)
    [105, []], // ErrorCode.SamplingSequenceIdRequired
    [145, []], // ErrorCode.SamplingEventIdAlreadyUsed
    [146, []], // ErrorCode.SamplingEventIdRequired
    [2104, []], // ErrorCode.SamplingStartGreaterThanEnd
    [2156, []], // ErrorCode.SamplingSamplePointRequired
]);

export const errorCodesApply = (errorCodeList: [number]): any => {
    return errorCodeList.some((errorCode) => errorCodeToMessageIdSetMap.has(errorCode));
};

interface ISampleTissueFormProps {
    agEventModel: any;
    cropGrowthStageList: ICropGrowthStage[];
    intl: InjectedIntl;
    fieldGuid: string;
    onAutoPlaceSamplingPoints: (cropGrowthStageGuid: string, plantPartGuid: string) => void;
    onEnableAddPointsTool: () => void;
    onFetchCropGrowthStageList: (fieldGuid: string) => void;
    onFetchPicklists: (picklists: any) => void;
    onSetSamplingFieldPointsPlaced: (isPlaced: boolean) => void;
    onSetShowProductivityRating: (event: MouseEvent, showProductivityRating: boolean) => void;
    onSetSelectedPointSet: (selectedPointSet: Set<string>) => void;
    onUpdateBatchSamplingEvents: (isPointProps: boolean, newProps: any) => void;
    onUpdateSamplingAgEventModel: (newProps: any) => void;
    picklistOptionsProductivityRating: IPicklistOption[];
    picklistOptionsCropPurpose: IPicklistOption[];
    picklistOptionsPlantPart: IPicklistOption[];
    samplingFieldPointsPlaced: boolean;
    saveEventDetailsErrorCodeList: number[];
    selectedPointSet: Set<string>;
    showProductivityRating: boolean;
    tissueSampleResults: ISampleResults;
}

interface ISampleTissueFormState {
    errorMessagePlaceholderSet: Set<string>;
    haveSetDefaultValues: boolean;
    cropId?: number;
    cropClassId?: number;
    cropGrowthStageGuid?: string;
    plantPartGuid?: string;
}

export class SampleTissueForm_ extends Component<ISampleTissueFormProps, ISampleTissueFormState> {
    constructor(props: ISampleTissueFormProps) {
        super(props);
        this.state = {
            errorMessagePlaceholderSet: this._getErrorMessagePlaceholderSet(props),
            haveSetDefaultValues: false,
            cropId: null,
            cropClassId: null,
            cropGrowthStageGuid: null,
            plantPartGuid: null,
        };
    }

    public UNSAFE_componentWillMount(): void {
        const fetchPicklistNames = [
            PICKLIST_NUMERIC_RATING,
            PICKLIST_CROP_PURPOSE,
            PICKLIST_PLANT_PART,
        ];
        const picklistFetchArgObj = fetchPicklistNames.reduce((accum, key) => {
            accum[key] = getPickListCode(key);
            return accum;
        }, {});
        this.props.onFetchPicklists(picklistFetchArgObj);
        this.props.onFetchCropGrowthStageList(this.props.fieldGuid);
    }

    public UNSAFE_componentWillReceiveProps(newProps: ISampleTissueFormProps): void {
        const newSaveEventDetailsErrorCodeList =
            newProps.saveEventDetailsErrorCodeList !== this.props.saveEventDetailsErrorCodeList;

        if (newSaveEventDetailsErrorCodeList) {
            this.setState({
                errorMessagePlaceholderSet: this._getErrorMessagePlaceholderSet(newProps),
            });
        }

        if (
            newProps.fieldGuid &&
            this.props.fieldGuid &&
            newProps.fieldGuid !== this.props.fieldGuid
        ) {
            return;
        }

        if (
            newProps.cropGrowthStageList.length > 0 &&
            !this.state.cropId &&
            newProps.agEventModel.cropGuid
        ) {
            const cropRecord = newProps.cropGrowthStageList.find(
                (record) => record.cropGuid === newProps.agEventModel.cropGuid
            );
            if (cropRecord) {
                this.setState({
                    cropId: cropRecord.cropId,
                    cropClassId: cropRecord.cropClassId,
                });
            }
        }

        //handles moving sketch points
        if (
            newProps.agEventModel.samplePoints.length ===
                this.props.agEventModel.samplePoints.length &&
            newProps.agEventModel.samplePoints.length > 0
        ) {
            newProps.agEventModel.samplePoints.forEach((sp, index) => {
                const updatedPoint = this.props.agEventModel.samplePoints[index];
                if (sp.shape !== updatedPoint.shape) {
                    const cropGrowthStageGuid =
                        sp.cropGrowthStageGuid || updatedPoint.cropGrowthStageGuid;
                    const plantPartGuid = sp.plantPartGuid || updatedPoint.plantPartGuid;
                    const shape = sp.shape;
                    this._updateSamplePoint(
                        index,
                        { cropGrowthStageGuid, plantPartGuid, shape },
                        this.props
                    );
                }
            });
        }

        if (
            newProps.agEventModel.samplePoints.length > this.props.agEventModel.samplePoints.length
        ) {
            // Added a point

            newProps.agEventModel.samplePoints.forEach((sp) => {
                const updatedPoint = this.props.agEventModel.samplePoints.find(
                    (op) => op.sequenceId === sp.sequenceId
                );
                if (updatedPoint) {
                    sp.cropGrowthStageGuid =
                        sp.cropGrowthStageGuid || updatedPoint.cropGrowthStageGuid;
                    sp.plantPartGuid = sp.plantPartGuid || updatedPoint.plantPartGuid;
                }
            });

            const highestSampleId = Math.max(
                ...this.props.agEventModel.samplePoints.map((p: ITissueSamplePoint) => p.sampleId)
            );
            const newSamplePoint = newProps.agEventModel.samplePoints.filter(
                (p: ITissueSamplePoint) =>
                    this.props.agEventModel.samplePoints.findIndex(
                        (p2: ITissueSamplePoint) =>
                            p2.eventSampleTissuePointGuid === p.eventSampleTissuePointGuid
                    ) === -1
            )[0];

            const highestSamplePointIdx = this.props.agEventModel.samplePoints.findIndex(
                (p: ITissueSamplePoint) =>
                    p.sampleId === highestSampleId &&
                    p.cropGrowthStageGuid &&
                    p.eventSampleTissuePointGuid !== newSamplePoint.eventSampleTissuePointGuid
            );

            const highestSamplePoint = this.props.agEventModel.samplePoints[highestSamplePointIdx];
            const newSamplePointIdx = newProps.agEventModel.samplePoints.findIndex(
                (p: ITissueSamplePoint) =>
                    p.eventSampleTissuePointGuid === newSamplePoint.eventSampleTissuePointGuid
            );

            if (
                newProps.agEventModel.samplePoints.length === 1 &&
                this.props.agEventModel.samplePoints.length === 0
            ) {
                // Added the first point
                const { cropGrowthStageGuid, plantPartGuid } = this.state;

                if (cropGrowthStageGuid || plantPartGuid) {
                    this._updateSamplePoint(
                        newSamplePointIdx,
                        { cropGrowthStageGuid, plantPartGuid },
                        newProps
                    );
                }
            } else if (highestSamplePointIdx !== -1 && !newSamplePoint.cropGrowthStageGuid) {
                const newSamplePoint = newProps.agEventModel.samplePoints[newSamplePointIdx];
                const samplePointsWithSameId = newProps.agEventModel.samplePoints.filter(
                    (p: ITissueSamplePoint) => p.sampleId === newSamplePoint.sampleId
                );
                const isCompositePoint = samplePointsWithSameId.length > 1;

                if (isCompositePoint) {
                    const compositeMinimumSequenceId = Math.min(
                        ...newProps.agEventModel.samplePoints
                            .filter(
                                (p: ITissueSamplePoint) => p.sampleId === newSamplePoint.sampleId
                            )
                            .map((p: ITissueSamplePoint) => p.sequenceId)
                    );

                    const compositeParentPoint = newProps.agEventModel.samplePoints.find(
                        (p: ITissueSamplePoint) =>
                            p.sampleId === newSamplePoint.sampleId &&
                            p.sequenceId === compositeMinimumSequenceId
                    );

                    this._updateSamplePoint(
                        newSamplePointIdx,
                        {
                            cropGrowthStageGuid: compositeParentPoint.cropGrowthStageGuid,
                            plantPartGuid: compositeParentPoint.plantPartGuid,
                        },
                        newProps
                    );
                } else {
                    this._updateSamplePoint(
                        newSamplePointIdx,
                        {
                            cropGrowthStageGuid: highestSamplePoint.cropGrowthStageGuid,
                            plantPartGuid: highestSamplePoint.plantPartGuid,
                        },
                        newProps
                    );
                }
            }
        }

        if (
            newProps.agEventModel.cropGuid !== this.props.agEventModel.cropGuid ||
            newProps.agEventModel.cropPurposeGuid !== this.props.agEventModel.cropPurposeGuid
        ) {
            this._updateSamplePoint(
                0,
                { cropGrowthStageGuid: null, plantPartGuid: null },
                newProps,
                true
            );
        }

        if (
            (this.state.cropGrowthStageGuid == null || this.state.plantPartGuid == null) &&
            newProps.agEventModel.samplePoints.length > 0
        ) {
            const isDefaultCase =
                !newProps.agEventModel.samplePoints.some((p: ITissueSamplePoint) =>
                    Boolean(p.cropGrowthStageGuid || p.plantPartGuid)
                ) ||
                newProps.agEventModel.samplePoints.every(
                    (p: ITissueSamplePoint) =>
                        p.cropGrowthStageGuid ===
                            newProps.agEventModel.samplePoints[0].cropGrowthStageGuid &&
                        p.plantPartGuid === newProps.agEventModel.samplePoints[0].plantPartGuid
                );

            const cropGrowthStageGuid = newProps.agEventModel.samplePoints[0].cropGrowthStageGuid;
            const plantPartGuid = newProps.agEventModel.samplePoints[0].plantPartGuid;

            if (
                isDefaultCase &&
                (cropGrowthStageGuid !== this.state.cropGrowthStageGuid ||
                    plantPartGuid !== this.state.plantPartGuid)
            ) {
                this.setState({
                    cropGrowthStageGuid,
                    plantPartGuid,
                });
            }
        }
    }

    public render(): JSX.Element {
        return (
            <div className="event-sample-tissue-form">
                {this._getTissueDetailPart()}
                {this._getMethodSelectionPart()}
                {this._getSamplePointsPart()}
            </div>
        );
    }

    private _getErrorMessagePlaceholderSet = (props: ISampleTissueFormProps): Set<string> => {
        const { saveEventDetailsErrorCodeList } = props;
        return getSetValuesForErrorCodeList(
            saveEventDetailsErrorCodeList,
            errorCodeToMessageIdSetMap
        );
    };

    private _onUpdate = (key: string, val: any): void => {
        const { cropGrowthStageList, onUpdateBatchSamplingEvents } = this.props;
        const resetProps: any = {};

        if (key === "cropId") {
            resetProps.cropPurposeGuid = null;
            this.setState({ cropId: val }, () => {
                const cropHasClasses = Boolean(
                    cropGrowthStageList.find(
                        (record) => record.cropId === val && Boolean(record.cropClassName)
                    )
                );
                const cropHasEmptyClass =
                    cropHasClasses &&
                    Boolean(
                        cropGrowthStageList.find(
                            (record) => record.cropId === val && !record.cropClassName
                        )
                    );
                const cropRecord = cropGrowthStageList.find((record) => {
                    return (
                        record.cropId === val &&
                        (!cropHasClasses || cropHasEmptyClass) &&
                        !record.cropClassName
                    );
                });
                const cropClassId = cropRecord ? cropRecord.cropClassId : null;
                this.setState({ cropClassId, cropGrowthStageGuid: null }, () => {
                    this._onUpdateSampling({
                        cropGuid: cropRecord ? cropRecord.cropGuid : null,
                        ...resetProps,
                    });
                    onUpdateBatchSamplingEvents(true, {
                        cropGrowthStageGuid: null,
                    });
                });
            });
        } else if (key === "cropClassId") {
            const cropRecord =
                val == null
                    ? cropGrowthStageList.find(
                          (record) => record.cropId === this.state.cropId && !record.cropClassName
                      )
                    : cropGrowthStageList.find(
                          (record) =>
                              record.cropId === this.state.cropId && record.cropClassId === val
                      );
            resetProps.cropPurposeGuid = null;

            const cropClassId = cropRecord ? cropRecord.cropClassId : val;

            this.setState({ cropClassId }, () => {
                this._onUpdateSampling({
                    cropGuid: cropRecord ? cropRecord.cropGuid : null,
                    ...resetProps,
                });
            });
        } else {
            this._onUpdateSampling({ [key]: val, ...resetProps });
        }
    };

    private _onUpdateSampling(newProps) {
        const { fieldGuid, onUpdateBatchSamplingEvents, onUpdateSamplingAgEventModel } = this.props;
        const isBatchTemplate = fieldGuid === recsEventsModel.BATCH_TEMPLATE_FIELD_GUID;
        if (isBatchTemplate) {
            onUpdateBatchSamplingEvents(false, newProps);
        }
        onUpdateSamplingAgEventModel(newProps);
    }

    private _getDistinctList(listObjects: IPicklistOption[]): IPicklistOption[] {
        const returnList = [];
        listObjects.forEach((record) => {
            const existingRecord = returnList.find((retObj) => retObj.value === record.value);
            if (!existingRecord) {
                returnList.push(record);
            }
        });
        return returnList;
    }
    private _getMethodSelectionPart(): JSX.Element {
        const {
            agEventModel,
            fieldGuid,
            onAutoPlaceSamplingPoints,
            onSetSamplingFieldPointsPlaced,
            samplingFieldPointsPlaced,
        } = this.props;
        const { cropGrowthStageGuid, plantPartGuid } = this.state;
        const { formatMessage } = this.props.intl;
        const isBatchTemplate = fieldGuid === recsEventsModel.BATCH_TEMPLATE_FIELD_GUID;
        if (agEventModel == null || !isBatchTemplate) {
            return null;
        }
        const onTabSelect = () => {
            // Add logic when batch Manual becomes available
        };
        return (
            <Tabs className="method-selection" onTabSelect={onTabSelect} selected={0}>
                {!isBatchTemplate ? null : (
                    <Pane label={AUTO_METHOD_LABEL}>
                        <div className="manual-method-steps">
                            <Button
                                disabled={samplingFieldPointsPlaced}
                                value={formatMessage(messages.samplePlacePointsTxt)}
                                onClick={() => {
                                    onSetSamplingFieldPointsPlaced(true);
                                    onAutoPlaceSamplingPoints(cropGrowthStageGuid, plantPartGuid);
                                }}
                            />
                        </div>
                    </Pane>
                )}
                <Pane label={MANUAL_METHOD_LABEL} disabled={true}>
                    <div className="manual-method-steps"></div>
                </Pane>
            </Tabs>
        );
    }

    private _getTissueDetailPart(): JSX.Element {
        const {
            agEventModel,
            cropGrowthStageList,
            fieldGuid,
            onUpdateBatchSamplingEvents,
            picklistOptionsCropPurpose,
            picklistOptionsPlantPart,
            selectedPointSet,
        } = this.props;
        const { cropId, cropClassId } = this.state;
        const { formatMessage } = this.props.intl;
        const isBatchTemplate = fieldGuid === recsEventsModel.BATCH_TEMPLATE_FIELD_GUID;

        const isCropSet = Boolean(cropId);
        const isCropDetermined = Boolean(agEventModel.cropGuid);
        const isTargetValueDriven: boolean =
            Boolean(cropGrowthStageList.find((record) => record.isTargetValue)) ||
            cropGrowthStageList.length === 0;

        const cropHasClasses: boolean =
            isCropSet &&
            Boolean(
                cropGrowthStageList.filter(
                    (record) => record.cropId === cropId && Boolean(record.cropClassName)
                ).length > 0
            );
        const cropClassRequired: boolean =
            isTargetValueDriven &&
            cropHasClasses &&
            Boolean(
                cropGrowthStageList.filter(
                    (record) => record.cropId === cropId && !record.cropClassName
                ).length === 0
            );

        const cropHasPurposes: boolean =
            !isTargetValueDriven ||
            (isCropDetermined &&
                Boolean(
                    cropGrowthStageList.filter(
                        (record) =>
                            record.cropGuid === agEventModel.cropGuid &&
                            Boolean(record.cropPurposeGuid)
                    ).length > 0
                ));
        const cropPurposeRequired: boolean =
            isTargetValueDriven &&
            isCropDetermined &&
            cropHasPurposes &&
            Boolean(
                cropGrowthStageList.filter(
                    (record) => record.cropId === cropId && !record.cropPurposeGuid
                ).length === 0
            );

        const cropPlantPartRequired = false;

        const cropListOptions = this._getDistinctList(
            cropGrowthStageList.map((record) => ({
                label: record.cropName,
                value: record.cropId.toString(),
            }))
        );

        const cropClassListOptions = !isCropSet
            ? []
            : this._getDistinctList(
                  cropGrowthStageList
                      .filter((record) => record.cropId === cropId && Boolean(record.cropClassName))
                      .map((record) => ({
                          label: record.cropClassName,
                          value: record.cropClassId.toString(),
                      }))
              );

        const cropPurposeListOptions =
            !isCropDetermined && isTargetValueDriven
                ? []
                : !isTargetValueDriven
                ? picklistOptionsCropPurpose
                : this._getDistinctList(
                      cropGrowthStageList
                          .filter(
                              (record) =>
                                  record.cropGuid === agEventModel.cropGuid &&
                                  Boolean(record.cropPurposeGuid)
                          )
                          .map((record) => ({
                              label: picklistOptionsCropPurpose.find(
                                  (opt) => opt.value === record.cropPurposeGuid
                              ).label,
                              value: record.cropPurposeGuid,
                          }))
                  );

        const growthStageOrderListOptions = !isCropDetermined
            ? []
            : this._getDistinctList(
                  cropGrowthStageList
                      .filter(
                          (record) =>
                              record.cropGuid === agEventModel.cropGuid &&
                              ((!record.cropPurposeGuid && !agEventModel.cropPurposeGuid) ||
                                  record.cropPurposeGuid === agEventModel.cropPurposeGuid)
                      )
                      .map((record) => ({
                          label: record.growthStageOrderName,
                          value: record.growthStageOrderGuid,
                      }))
              );

        const plantPartListOptions =
            !isCropDetermined && isTargetValueDriven ? [] : picklistOptionsPlantPart;

        const currentSelectedPointIdx =
            selectedPointSet.size === 1
                ? agEventModel.samplePoints.findIndex((p: ITissueSamplePoint) =>
                      selectedPointSet.has(p.eventSampleTissuePointGuid)
                  )
                : -1;
        const currentSelectedPoint = agEventModel.samplePoints[currentSelectedPointIdx];

        // In the default case, we'll behave more or less like it did before.  Everything will be updated in unison until the user
        //  explicitly makes one of the points different.
        const isDefaultCase = this._isDefaultCase();
        const combinedGrowthStageSet = new Set();
        const combinedPlantPartSet = new Set();

        const combinedGrowthStageName = agEventModel.samplePoints.reduce(
            (acc: string, p: ITissueSamplePoint) => {
                if (
                    selectedPointSet.size > 0 &&
                    !selectedPointSet.has(p.eventSampleTissuePointGuid)
                ) {
                    return acc;
                }
                const label = growthStageOrderListOptions.find(
                    (g) => g.value === p.cropGrowthStageGuid
                )?.label;
                if (combinedGrowthStageSet.has(p.cropGrowthStageGuid) || label == null) {
                    return acc;
                }
                combinedGrowthStageSet.add(p.cropGrowthStageGuid);
                return acc + (acc ? ", " : "") + label;
            },
            ""
        );

        const combinedPlantPartName = agEventModel.samplePoints.reduce(
            (acc: string, p: ITissueSamplePoint) => {
                if (
                    selectedPointSet.size > 0 &&
                    !selectedPointSet.has(p.eventSampleTissuePointGuid)
                ) {
                    return acc;
                }
                const label = plantPartListOptions.find((g) => g.value === p.plantPartGuid)?.label;
                if (combinedPlantPartSet.has(p.plantPartGuid) || label == null) {
                    return acc;
                }
                combinedPlantPartSet.add(p.plantPartGuid);
                return acc + (acc ? ", " : "") + label;
            },
            ""
        );

        return (
            <Bucket
                className="import-field-section-bucket"
                showSymbol={false}
                isCollapsible={false}
                isExpanded={true}
            >
                <BucketHeader className="import-field-section-header create-events-section-header">
                    <div className="create-events-header">
                        {formatMessage(messages.tissueDetails)}
                    </div>
                </BucketHeader>
                <div className="input-row">
                    <SelectInput
                        optionIsHiddenKey={ACTIVE_YN}
                        placeholderText={formatMessage(messages.crop)}
                        value={cropId}
                        options={cropListOptions}
                        required={true}
                        onChange={this._onUpdate.bind(this, "cropId")}
                    />
                    {cropPurposeListOptions.length === 0 ? null : (
                        <SelectInput
                            optionIsHiddenKey={ACTIVE_YN}
                            placeholderText={formatMessage(messages.cropPurpose)}
                            value={agEventModel.cropPurposeGuid}
                            options={cropPurposeListOptions}
                            required={cropPurposeRequired}
                            onChange={this._onUpdate.bind(this, "cropPurposeGuid")}
                        />
                    )}
                    {cropClassListOptions.length === 0 ||
                    cropPurposeListOptions.length > 0 ? null : (
                        <SelectInput
                            optionIsHiddenKey={ACTIVE_YN}
                            placeholderText={formatMessage(messages.cropClass)}
                            value={cropClassId}
                            options={cropClassListOptions}
                            required={cropClassRequired}
                            onChange={this._onUpdate.bind(this, "cropClassId")}
                        />
                    )}
                </div>
                {cropClassListOptions.length === 0 || cropPurposeListOptions.length === 0 ? null : (
                    <div className="input-row">
                        <SelectInput
                            optionIsHiddenKey={ACTIVE_YN}
                            placeholderText={formatMessage(messages.cropClass)}
                            value={cropClassId}
                            options={cropClassListOptions}
                            required={cropClassRequired}
                            onChange={this._onUpdate.bind(this, "cropClassId")}
                        />
                    </div>
                )}
                {!currentSelectedPoint && !isDefaultCase ? (
                    <div className="readonly-row">
                        <div className="readonly-label">
                            {formatMessage(messages.growthStage)}:{" "}
                        </div>
                        <div className="readonly-value">{combinedGrowthStageName}</div>

                        <div className="readonly-label">{formatMessage(messages.plantPart)}: </div>
                        <div className="readonly-value">{combinedPlantPartName}</div>
                    </div>
                ) : (
                    <div className="input-row">
                        {growthStageOrderListOptions.length === 0 && isTargetValueDriven ? null : (
                            <SelectInput
                                optionIsHiddenKey={ACTIVE_YN}
                                placeholderText={formatMessage(messages.growthStage)}
                                value={(currentSelectedPoint || this.state).cropGrowthStageGuid}
                                options={growthStageOrderListOptions}
                                required={true}
                                onChange={(cropGrowthStageGuid: string) => {
                                    if (currentSelectedPoint) {
                                        if (!this.state.cropGrowthStageGuid || isDefaultCase) {
                                            this.setState({
                                                cropGrowthStageGuid,
                                            });
                                        }
                                        this._updateSamplePoint(currentSelectedPointIdx, {
                                            cropGrowthStageGuid,
                                        });
                                    } else if (isBatchTemplate) {
                                        this.setState({ cropGrowthStageGuid }, () =>
                                            onUpdateBatchSamplingEvents(true, {
                                                cropGrowthStageGuid,
                                            })
                                        );
                                    } else if (
                                        isDefaultCase &&
                                        agEventModel.samplePoints.length > 0
                                    ) {
                                        this.setState({ cropGrowthStageGuid }, () =>
                                            this._updateSamplePoint(
                                                0,
                                                { cropGrowthStageGuid },
                                                this.props,
                                                isDefaultCase
                                            )
                                        );
                                    } else {
                                        this.setState({ cropGrowthStageGuid });
                                    }
                                }}
                            />
                        )}
                        {plantPartListOptions.length === 0 ? null : (
                            <SelectInput
                                optionIsHiddenKey={ACTIVE_YN}
                                placeholderText={formatMessage(messages.plantPart)}
                                value={(currentSelectedPoint || this.state).plantPartGuid}
                                options={plantPartListOptions}
                                required={cropPlantPartRequired}
                                onChange={(plantPartGuid: string) => {
                                    if (currentSelectedPoint) {
                                        if (!this.state.plantPartGuid || isDefaultCase) {
                                            this.setState({ plantPartGuid });
                                        }
                                        this._updateSamplePoint(currentSelectedPointIdx, {
                                            plantPartGuid,
                                        });
                                    } else if (
                                        isDefaultCase &&
                                        agEventModel.samplePoints.length > 0
                                    ) {
                                        this.setState({ plantPartGuid }, () =>
                                            this._updateSamplePoint(
                                                0,
                                                { plantPartGuid },
                                                this.props,
                                                isDefaultCase
                                            )
                                        );
                                    } else if (isBatchTemplate) {
                                        this.setState({ plantPartGuid }, () =>
                                            onUpdateBatchSamplingEvents(true, {
                                                plantPartGuid,
                                            })
                                        );
                                    } else {
                                        this.setState({ plantPartGuid });
                                    }
                                }}
                            />
                        )}
                    </div>
                )}
            </Bucket>
        );
    }

    private _getSamplePointsPart(): JSX.Element {
        const {
            intl,
            agEventModel,
            fieldGuid,
            tissueSampleResults,
            onEnableAddPointsTool,
            onSetShowProductivityRating,
            onSetSelectedPointSet,
            picklistOptionsProductivityRating,
            selectedPointSet,
            showProductivityRating,
        } = this.props;
        const { formatMessage } = intl;
        const isBatchTemplate = fieldGuid === recsEventsModel.BATCH_TEMPLATE_FIELD_GUID;
        const viewResultsMode = tissueSampleResults != null;
        const currentSelectedPointIdx =
            selectedPointSet.size === 1
                ? agEventModel.samplePoints.findIndex((p: ITissueSamplePoint) =>
                      selectedPointSet.has(p.eventSampleTissuePointGuid)
                  )
                : -1;
        const currentSelectedPoint = agEventModel.samplePoints[currentSelectedPointIdx];
        const isDefaultCase = this._isDefaultCase();

        const sampleClassNameResults = agEventModel.cropGuid
            ? !currentSelectedPoint && !isDefaultCase
                ? "sample-points alt-results"
                : "sample-points crop-selected-results"
            : "sample-points no-crop-selected-results";
        const sampleClassNameNoResults = agEventModel.cropGuid
            ? !currentSelectedPoint && !isDefaultCase
                ? "sample-points alt-no-results"
                : "sample-points crop-selected-no-results"
            : "sample-points no-crop-selected-no-results";

        if (viewResultsMode) {
            return (
                <div className={sampleClassNameResults}>
                    <div className="table-controls">
                        <Checkbox
                            label={formatMessage(messages.sampleTissueProductivityRatingTxt)}
                            value={showProductivityRating}
                            onChange={onSetShowProductivityRating}
                        />
                    </div>
                    <SampleResultsTable
                        intl={intl}
                        selectedPointSet={selectedPointSet}
                        onSetSelectedPointSet={onSetSelectedPointSet}
                        onSetPointProdRating={(idx: number, val: string) =>
                            this._updateSamplePoint(idx, {
                                productivityRatingGuid: val,
                            })
                        }
                        pointGuidAttributeName={"eventSampleTissuePointGuid"}
                        productivityRatingOptions={picklistOptionsProductivityRating}
                        samplePoints={agEventModel == null ? [] : agEventModel.samplePoints}
                        sampleResults={tissueSampleResults}
                        showProductivityRating={showProductivityRating}
                    />
                </div>
            );
        }
        if (isBatchTemplate) {
            return null;
        }

        return (
            <div className={sampleClassNameNoResults}>
                <div className="table-controls">
                    <Checkbox
                        label={formatMessage(messages.sampleTissueProductivityRatingTxt)}
                        value={showProductivityRating}
                        onChange={onSetShowProductivityRating}
                    />

                    <Button
                        className="btn-place-points"
                        value={formatMessage(messages.samplePlacePointsTxt)}
                        onClick={onEnableAddPointsTool}
                    />
                </div>
                <SamplePointTable
                    selectedPointSet={selectedPointSet}
                    onSetSelectedPointSet={onSetSelectedPointSet}
                    onSetPointProdRating={(idx: number, val: string) =>
                        this._updateSamplePoint(idx, {
                            productivityRatingGuid: val,
                        })
                    }
                    productivityRatingOptions={picklistOptionsProductivityRating}
                    samplePoints={agEventModel == null ? [] : agEventModel.samplePoints}
                    showProductivityRating={showProductivityRating}
                />
            </div>
        );
    }

    _isDefaultCase(): boolean {
        const { agEventModel } = this.props;
        return (
            agEventModel.samplePoints.length === 0 ||
            !agEventModel.samplePoints.some((p: ITissueSamplePoint) =>
                Boolean(p.cropGrowthStageGuid || p.plantPartGuid)
            ) ||
            agEventModel.samplePoints.every(
                (p: ITissueSamplePoint) =>
                    p.cropGrowthStageGuid === agEventModel.samplePoints[0].cropGrowthStageGuid &&
                    p.plantPartGuid === agEventModel.samplePoints[0].plantPartGuid
            )
        );
    }

    _setSamplePointProductivityRating(
        samplePointIdx: number,
        productivityRatingGuid: string
    ): void {
        const { agEventModel, onUpdateSamplingAgEventModel } = this.props;
        const samplePoints = [...agEventModel.samplePoints];
        samplePoints[samplePointIdx] = {
            ...agEventModel.samplePoints[samplePointIdx],
            productivityRatingGuid,
        };
        onUpdateSamplingAgEventModel({ samplePoints });
    }

    private _updateSamplePoint = (
        samplePointIdx: number,
        newProps: Partial<ITissueSamplePoint>,
        props = this.props,
        applyToAllPoints = false
    ): void => {
        const { agEventModel, onUpdateSamplingAgEventModel } = props;
        const samplePoints = [...agEventModel.samplePoints];
        const currentSamplePoint = agEventModel.samplePoints[samplePointIdx];
        const samplePointsWithSameId = agEventModel.samplePoints.filter(
            (p: ITissueSamplePoint) => p.sampleId === currentSamplePoint.sampleId
        );

        const cropGrowthStageGuid =
            newProps.cropGrowthStageGuid !== undefined
                ? newProps.cropGrowthStageGuid
                : samplePointsWithSameId[0].cropGrowthStageGuid;
        const plantPartGuid =
            newProps.plantPartGuid !== undefined
                ? newProps.plantPartGuid
                : samplePointsWithSameId[0].plantPartGuid;

        onUpdateSamplingAgEventModel({
            samplePoints: samplePoints.map((p) => ({
                ...p,
                ...(p.sampleId !== currentSamplePoint.sampleId && !applyToAllPoints
                    ? {
                          cropGrowthStageGuid: p.cropGrowthStageGuid || cropGrowthStageGuid,
                          plantPartGuid: p.plantPartGuid || plantPartGuid,
                      }
                    : {
                          cropGrowthStageGuid,
                          plantPartGuid,
                          ...(p.sequenceId !== currentSamplePoint.sequenceId ? {} : newProps),
                      }),
            })),
        });
    };
}

const mapDispatchToProps = (dispatch) => ({
    onAutoPlaceSamplingPoints: (cropGrowthStageGuid, plantPartGuid) =>
        dispatch(
            mapToolsActions.setActiveToolset(Toolset.SAMPLING_AUTO_PLACE, {
                attributes: { cropGrowthStageGuid, plantPartGuid },
                placePoints: "A",
                isAuto: true,
                isTissue: true,
                fields: [{ fieldGuid: recsEventsModel.BATCH_TEMPLATE_FIELD_GUID }],
            })
        ),
    onEnableAddPointsTool: () => dispatch(mapToolsActions.setActiveMapTool(Tool.SamplingAddSelect)),
    onFetchCropGrowthStageList: (fieldGuid: string) =>
        dispatch(actions.fetchCropGrowthStageList(fieldGuid)),
    onFetchPicklists: (pickLists: any) => dispatch(picklistActions.fetchPicklistData(pickLists)),
    onSetSamplingFieldPointsPlaced: (isPlaced) =>
        dispatch(recsEventsActions.setSamplingFieldPointsPlaced(isPlaced)),
    onSetShowProductivityRating: (evt: any, showProductivityRating: boolean) =>
        dispatch(actions.setShowProductivityRating(showProductivityRating)),
    onSetSelectedPointSet: (selectedPointSet: Set<number>) =>
        dispatch(recsEventsActions.setSelectedSamplePoints(selectedPointSet)),
    onUpdateBatchSamplingEvents: (isPointProps: boolean, newProps: any) =>
        dispatch(recsEventsActions.updateBatchSamplingEvents(isPointProps, newProps)),
    onUpdateSamplingAgEventModel: (fieldGuid: string, newProps: any) =>
        dispatch(recsEventsActions.updateSamplingAgEventModel(fieldGuid, newProps)),
});

const mapStateToProps = (state) => {
    const sampleModuleState = selectors.getModuleState(state);
    const {
        samplingFieldPointsPlaced,
        saveEventDetailsErrorCodeList,
        selectedSamplePointGuidIdSet,
        tissueSampleResults,
    } = eventsSelectors.getModuleState(state);

    return {
        cropGrowthStageList: selectors
            .getCropGrowthStageList(state)
            .sort((a: ICropGrowthStage, b: ICropGrowthStage) =>
                a.cropName < b.cropName ? -1 : a.cropName > b.cropName ? 1 : 0
            ),
        picklistOptionsProductivityRating: picklistSelectors.getPicklistOptionsFromCode(
            state,
            getPickListCode(PICKLIST_NUMERIC_RATING)
        ),
        picklistOptionsCropPurpose: picklistSelectors.getPicklistOptionsFromCode(
            state,
            getPickListCode(PICKLIST_CROP_PURPOSE)
        ),
        picklistOptionsPlantPart: picklistSelectors.getPicklistOptionsFromCode(
            state,
            getPickListCode(PICKLIST_PLANT_PART)
        ),
        samplingFieldPointsPlaced,
        saveEventDetailsErrorCodeList,
        selectedPointSet: selectedSamplePointGuidIdSet,
        showProductivityRating: sampleModuleState.showProductivityRating,
        tissueSampleResults,
    };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
    onUpdateBatchSamplingEvents: (isPointProps: boolean, newProps: any) =>
        dispatchProps.onUpdateBatchSamplingEvents(isPointProps, newProps),
    onUpdateSamplingAgEventModel: (newProps: any) =>
        dispatchProps.onUpdateSamplingAgEventModel(ownProps.fieldGuid, newProps),
    onFetchCropGrowthStageList: (fieldGuid) => dispatchProps.onFetchCropGrowthStageList(fieldGuid),
});

export const SampleTissueForm = connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
)(injectIntl(SampleTissueForm_));
