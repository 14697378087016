import React, { Component, MouseEvent as ReactMouseEvent, ReactNode } from "react";
import classnames from "classnames";

import DialogBoxFooter, { DialogBoxFooterType } from "./dialog-box-footer";
import DialogBoxHeader from "./dialog-box-header";

import { preventBubbleUp } from "~/admin/utils";

import "./dialog-box.css";

export interface IMultiActionList {
    action: string;
    actionDisabled?: boolean;
    onAction: () => void;
    isCancelStyle?: boolean;
}

export interface IDialogBoxProps {
    action?: string;
    actionDisabled?: boolean;
    multiActionList?: IMultiActionList[];
    autoOpen?: boolean;
    children?: ReactNode;
    className?: string;
    closeOnClickOff?: boolean;
    closeOnEscape?: boolean;
    draggable?: boolean;
    footerType?: DialogBoxFooterType;
    forceOverflow?: boolean;
    hideCloseX?: boolean;
    isModal?: boolean;
    isOpen?: boolean;
    letterIcon?: string;
    onAction?: (e: MouseEvent | ReactMouseEvent) => void;
    onClose?: (e?: MouseEvent | ReactMouseEvent) => void;
    onOpen?: (e?: MouseEvent | ReactMouseEvent) => void;
    posX?: number;
    posY?: number;
    style?: Record<string, unknown>;
    title?: string;
    tools?: Node | JSX.Element;
    unrestricted?: boolean;
}

export interface IDialogBoxState {
    isOpen: boolean;
    zIndex: number;
    posX?: number;
    posY?: number;
}

export class DialogBox extends Component<IDialogBoxProps, IDialogBoxState> {
    /* show above .loader-container */
    static HIGHEST_ZINDEX = 10001;

    initX: number;
    initY: number;
    initMouseX: number;
    initMouseY: number;
    dragActive: boolean;
    isInWindow: boolean;

    static getNextDialogZIndex(): number {
        DialogBox.HIGHEST_ZINDEX += 2;
        return DialogBox.HIGHEST_ZINDEX;
    }

    static defaultProps = {
        action: "",
        actionDisabled: false,
        autoOpen: false,
        draggable: false,
        footerType: DialogBoxFooterType.NONE,
        closeOnClickOff: false,
        closeOnEscape: false,
        forceOverflow: false,
        hideCloseX: undefined, //this allows us to explicitly set hideCloseX to `false` to override footer automatically removing it
        isModal: true,
        isOpen: false,
        letterIcon: null,
        multiActionList: [
            {
                action: "",
                actionDisabled: false,
                onAction: (): null => null,
                isCancelStyle: false,
            },
        ],
        onAction: (): null => null,
        posX: null,
        posY: null,
        title: "NEEDS A TITLE",
        tools: null,
        unrestricted: false,
    };

    constructor(props: IDialogBoxProps) {
        super(props);
        this.state = {
            isOpen: props.isOpen || props.autoOpen || DialogBox.defaultProps.isOpen,
            zIndex: DialogBox.getNextDialogZIndex(),
            posX: props.posX,
            posY: props.posY,
        };
        this.initX = 0;
        this.initY = 0;
        this.initMouseX = 0;
        this.initMouseY = 0;
        this.dragActive = false;
        this.isInWindow = true;
    }

    private _handleClickModal = (event: MouseEvent | ReactMouseEvent): void => {
        if (event.target instanceof HTMLElement) {
            if (event.target.tagName !== "INPUT" && event.target.tagName !== "A") {
                preventBubbleUp(event);
            }
        }

        this.promoteToTop(event);
    };

    private _handleClickOverlay = (event: MouseEvent | ReactMouseEvent): void => {
        if (this.props.isModal) {
            event.preventDefault();
        }

        if (this.props.closeOnClickOff) {
            event.preventDefault();
            this.setState({ isOpen: false });
        }
    };

    private _handleOnKeyDown = (event: KeyboardEvent): void => {
        if (this.props.closeOnEscape && event.key === "Escape") {
            if (this.state.zIndex === DialogBox.HIGHEST_ZINDEX) {
                this.setState({ isOpen: false });
            }
        }
    };

    private _handleOnMouseUp = (): void => {
        this.dragActive = false;
    };

    private _handleOnMouseDown = (event?: MouseEvent | ReactMouseEvent): void => {
        let containerNode: HTMLElement | null = null;
        if (event && event.target instanceof HTMLElement) {
            containerNode = event.target.closest(".dialog-container");
        }
        if (event && containerNode) {
            const containerBounds = containerNode.getBoundingClientRect();
            this.initX = containerBounds.left;
            this.initY = containerBounds.top;
            this.initMouseX = event.clientX;
            this.initMouseY = event.clientY;
            this.dragActive = true;
            this.promoteToTop(event);
            event.preventDefault();
        }
    };

    private _handleOnMouseOver = (event: MouseEvent | ReactMouseEvent): void => {
        if (event.target instanceof HTMLElement) {
            this.isInWindow = false;
        }
    };

    private _handleOnMouseOut = (event: MouseEvent | ReactMouseEvent): void => {
        if (event.target instanceof HTMLElement) {
            this.isInWindow = true;
        }
    };

    private _handleOnMouseMove = (event: MouseEvent | ReactMouseEvent): void => {
        if (this.dragActive) {
            this.setState({
                posX: this.initX - (this.initMouseX - event.clientX),
                posY: this.initY - (this.initMouseY - event.clientY),
            });
        }
        if (this.dragActive) {
            event.preventDefault();
        }
    };

    private close(e?: MouseEvent | ReactMouseEvent): void {
        if (this.state.isOpen) {
            this.setState({ isOpen: false });
        } else {
            if (this.props.onClose) {
                this.props.onClose(e);
            }
        }
    }

    private open(e?: MouseEvent): void {
        if (!this.state.isOpen) {
            this.setState({ isOpen: true });
        } else {
            if (this.props.onOpen) {
                this.props.onOpen(e);
            }
        }
    }

    private promoteToTop(event?: MouseEvent | ReactMouseEvent): void {
        if (this.state.zIndex !== DialogBox.HIGHEST_ZINDEX) {
            if (event) {
                event.preventDefault();
            }
            this.setState({ zIndex: DialogBox.getNextDialogZIndex() });
        }
    }

    componentDidMount(): void {
        window.addEventListener("keydown", this._handleOnKeyDown, false);
        window.addEventListener("mouseup", this._handleOnMouseUp, false);
        window.addEventListener("mousemove", this._handleOnMouseMove, false);
        window.addEventListener("mouseover", this._handleOnMouseOver, false);
        window.addEventListener("mouseout", this._handleOnMouseOut, false);
        if (this.state.isOpen || this.props.autoOpen) {
            this.open();
        }
    }

    componentDidUpdate(prevProps: IDialogBoxProps, prevState: IDialogBoxState): void {
        if (this.state.isOpen && !prevState.isOpen) {
            this.open();
        } else if (!this.state.isOpen && prevState.isOpen) {
            this.close();
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps: IDialogBoxProps): void {
        if (!this.state.isOpen && nextProps.isOpen) {
            this.promoteToTop();
            this.open();
        } else if (this.state.isOpen && !nextProps.isOpen) {
            this.setState({ zIndex: 0 });
            this.close();
        }
        if (nextProps.posX != null && nextProps.posY != null) {
            this.setState({
                posX: nextProps.posX,
                posY: nextProps.posY,
            });
        }
    }

    componentWillUnmount(): void {
        window.removeEventListener("keydown", this._handleOnKeyDown, false);
        window.removeEventListener("mouseup", this._handleOnMouseUp, false);
        window.removeEventListener("mousemove", this._handleOnMouseMove, false);
        window.removeEventListener("mouseover", this._handleOnMouseOver, false);
        window.removeEventListener("mouseout", this._handleOnMouseOut, false);
    }

    render(): JSX.Element {
        const { isOpen, posX, posY, zIndex } = this.state;
        if (isOpen === false) {
            return <></>;
        }
        const {
            action,
            actionDisabled,
            children,
            className,
            draggable,
            footerType,
            forceOverflow,
            isModal,
            letterIcon,
            multiActionList,
            onAction,
            style,
            title,
            tools,
            unrestricted,
        } = this.props;

        let { hideCloseX } = this.props;
        if (hideCloseX == null) {
            hideCloseX = footerType !== DialogBoxFooterType.NONE;
        }

        const dialogStyle = { zIndex, ...style };
        if (posX != null && posY != null) {
            Object.assign(dialogStyle, {
                position: "absolute",
                left: posX,
                top: posY,
            });
        }

        const dialogProps = {
            className: classnames([
                "dialog-container",
                className,
                {
                    "allow-overflow": forceOverflow,
                },
            ]),
            style: dialogStyle,
            onClick: this._handleClickModal,
        };

        return (
            <div className="dialog-window" style={{ zIndex }}>
                <div {...dialogProps}>
                    {!draggable ? null : (
                        <div
                            className="dialog-drag-bar"
                            onMouseDown={(event: MouseEvent | ReactMouseEvent) =>
                                this._handleOnMouseDown(event)
                            }
                            title={title}
                        />
                    )}
                    {DialogBoxHeader({
                        hideX: hideCloseX,
                        letterIcon,
                        onClose: (e: MouseEvent | ReactMouseEvent) => this.close(e),
                        title,
                        tools,
                        unrestricted,
                    })}
                    <div
                        className={classnames("dialog-box-body", {
                            restricted: !unrestricted,
                        })}
                    >
                        <div className="dialog-box-children">{children}</div>
                    </div>
                    {DialogBoxFooter({
                        action,
                        actionDisabled,
                        multiActionList,
                        onAction,
                        onClose: (e: MouseEvent | ReactMouseEvent) => this.close(e),
                        footerType,
                    })}
                </div>
                <div
                    className={isModal ? "modal-overlay" : "dialog-overlay"}
                    style={{ zIndex: zIndex - 1 }}
                    onClick={(e: MouseEvent | ReactMouseEvent) => this._handleClickOverlay(e)}
                />
            </div>
        );
    }
}
