import React, { useEffect } from "react";
import classNames from "classnames";
import { Tabs, Pane } from "~/core";
import * as actions from "../../../../actions";
import { FieldAccordionContextMenu } from "~/action-panel/components/context-menus/field-accordion-context-menu/field-accordion-context-menu";
import { defineMessages, injectIntl, intlShape } from "react-intl";

import "./field-select-bar.css";
import { logFirebaseEvent } from "~/utils/firebase";
import { UserAPI } from "@ai360/core";

const messages = defineMessages({
    inactiveTabText: {
        id: "fieldSelect.inactiveTabText",
        defaultMessage: "Inactive",
    },
    activeTabText: {
        id: "fieldSelect.activeTabText",
        defaultMessage: "Active",
    },
    allTabText: {
        id: "fieldSelect.allTabText",
        defaultMessage: "All",
    },
    selectedTabText: {
        id: "fieldSelect.selectedTabText",
        defaultMessage: "Selected",
    },
});

interface IFieldSelectBarProps {
    className: string;
    hasInactivePermission: boolean;
    activeTab: actions.FieldListTabs;
    hasSelectedFields: boolean;
    onTabChange: (tab) => void;
    onSelectAll: () => void;
    onClearAll: () => void;
    onRecSelection: () => void;
    onEventSelection: () => void;
    onExpandAll: () => void;
    onBatchEditDetails: () => void;
    onCollapseAll: () => void;
    onDeleteSelected: () => void;
    onExportBoundaries: () => void;
    onExportDetails: () => void;
    intl: intlShape;
    children: Node;
    userRole: UserAPI.IUserRole;
}

const FieldSelectBar = (props: IFieldSelectBarProps): JSX.Element => {
    const {
        className,
        children,
        hasInactivePermission,
        intl,
        onBatchEditDetails,
        onClearAll,
        onCollapseAll,
        onDeleteSelected,
        onEventSelection,
        onExpandAll,
        onExportBoundaries,
        onExportDetails,
        onRecSelection,
        onSelectAll,
        onTabChange,
        userRole,
    } = props;

    const activeTab = props.activeTab == undefined ? actions.FieldListTabs.ACTIVE : props.activeTab;
    const hasSelectedFields =
        props.hasSelectedFields == undefined ? false : props.hasSelectedFields;

    useEffect(() => {
        if (activeTab === actions.FieldListTabs.SELECTED && !hasSelectedFields) {
            onTabChange(actions.FieldListTabs.ACTIVE);
        }
    }, [activeTab]);

    useEffect(() => {
        if (activeTab === actions.FieldListTabs.SELECTED && !hasSelectedFields) {
            onTabChange(actions.FieldListTabs.ACTIVE);
        }
    }, [hasSelectedFields]);

    const onTabClick = (newTab) => {
        if (newTab === actions.FieldListTabs.SELECTED) {
            logFirebaseEvent("selected_tabs_fields");
        }
        if (
            (newTab === actions.FieldListTabs.INACTIVE && !hasInactivePermission) ||
            (newTab === actions.FieldListTabs.SELECTED &&
                activeTab === actions.FieldListTabs.INACTIVE &&
                !hasSelectedFields) ||
            newTab === activeTab
        ) {
            onTabChange(actions.FieldListTabs.ACTIVE);
        } else {
            onTabChange(newTab);
        }
    };

    const getTabFromIdx = (tabIdx) => {
        const { hasInactivePermission } = props;
        switch (tabIdx) {
            case 0:
                return hasInactivePermission
                    ? actions.FieldListTabs.INACTIVE
                    : actions.FieldListTabs.ACTIVE;
            case 1:
                return hasInactivePermission
                    ? actions.FieldListTabs.ACTIVE
                    : actions.FieldListTabs.SELECTED;
            case 2:
                console.assert(hasInactivePermission);
                return actions.FieldListTabs.SELECTED;
            default:
                break;
        }
    };

    const getIdxFromTab = (tab) => {
        const { hasInactivePermission } = props;
        switch (tab) {
            case actions.FieldListTabs.INACTIVE:
                console.assert(hasInactivePermission);
                return 0;
            case actions.FieldListTabs.ACTIVE:
                return hasInactivePermission ? 1 : 0;
            case actions.FieldListTabs.SELECTED:
                return hasInactivePermission ? 2 : 1;
            default:
                break;
        }
    };

    const { formatMessage } = intl;
    const activeTabText = hasInactivePermission
        ? formatMessage(messages.activeTabText)
        : formatMessage(messages.allTabText);
    const fieldAccordionContextMenuProps = {
        activeTab,
        onSelectAll,
        onClearAll,
        onRecSelection,
        onEventSelection,
        onExpandAll,
        onBatchEditDetails,
        onCollapseAll,
        onDeleteSelected,
        onExportBoundaries,
        onExportDetails,
        userRole,
    };
    const labelContainerRightElements = [
        <FieldAccordionContextMenu
            key={"field-accordion-context-menu"}
            {...fieldAccordionContextMenuProps}
        />,
    ];

    const panes = [];
    if (hasInactivePermission) {
        panes.push(
            <Pane
                key={getIdxFromTab(actions.FieldListTabs.INACTIVE)}
                label={formatMessage(messages.inactiveTabText)}
            >
                {children}
            </Pane>
        );
    }
    panes.push(
        <Pane key={getIdxFromTab(actions.FieldListTabs.ACTIVE)} label={activeTabText}>
            {children}
        </Pane>
    );
    panes.push(
        <Pane
            key={getIdxFromTab(actions.FieldListTabs.SELECTED)}
            label={formatMessage(messages.selectedTabText)}
            disabled={!hasSelectedFields}
        >
            {children}
        </Pane>
    );

    return (
        <div className={classNames("field-filter-tabs", className)}>
            <Tabs
                selected={getIdxFromTab(activeTab)}
                labelContainerLeftElements={[<div key={0} className="spacer"></div>]}
                labelContainerRightElements={labelContainerRightElements}
                onTabSelect={(tabIdx) => onTabClick(getTabFromIdx(tabIdx))}
            >
                {panes}
            </Tabs>
        </div>
    );
};

export default injectIntl(FieldSelectBar);
