import { all, fork, put, select, takeEvery } from "redux-saga/effects";
import * as actions from "./actions";

import { PreferenceAPI, APIError } from "@ai360/core";
import { actions as notificationActions } from "~/notifications";
import { getTheUserGuid, getLoginGuid } from "~/login/selectors";
import { setApiResult } from "~/core/api/actions";
import { actions as customerDataActions } from "~/customer-data";
import { actions as recsEventsActions } from "~/recs-events";
import { ColorOverrideAPI } from "@ai360/core";

export function* fetchPreference(action) {
    const UserGuid = yield select(getTheUserGuid);
    try {
        const response = yield PreferenceAPI.getUserPreferences({
            UserGuid,
            Model: UserGuid,
        });
        if (response) {
            yield put(actions.fetchPreferenceSuccess(response));
        } else {
            yield put(actions.fetchPreferenceFailure("No Data"));
        }
    } catch (error) {
        yield put(actions.fetchPreferenceFailure({ error }));
        yield put(notificationActions.apiCallError(error, action));
    }
}

export function* watchFetchPreference() {
    yield takeEvery(actions.fetchPreference, fetchPreference);
}

export function* savePreference(action) {
    const { payload } = action;
    if (payload) {
        const UserGuid = yield select(getTheUserGuid);
        const requestOptions = { UserGuid, Model: payload };
        try {
            yield PreferenceAPI.saveUserPreferences(requestOptions);
            yield put(actions.savePreferenceSuccess(payload));
            yield put(customerDataActions.fetchFieldsForExpandedCustomers());
            yield put(customerDataActions.fetchSummary());
            yield put(recsEventsActions.fetchSelectedFieldEvents());
            yield put(recsEventsActions.fetchSelectedFieldRecs());
        } catch (error) {
            if (error instanceof APIError) {
                yield put(setApiResult(error));
            }
            yield put(actions.fetchPreferenceFailure({ error }));
            yield put(notificationActions.apiCallError(error, action));
        }
    } else {
        yield put(actions.fetchPreferenceFailure("No Data"));
    }
}

export function* watchSavePreference() {
    yield takeEvery(actions.savePreference, savePreference);
}

export function* fetchColorOverride(action) {
    const LoginGuid = yield select(getLoginGuid);
    try {
        const response = yield ColorOverrideAPI.getColorOverrides(LoginGuid);
        if (response) {
            yield put(actions.fetchColorOverrideSuccess(response));
        } else {
            yield put(actions.fetchColorOverrideFailure("No Data"));
        }
    } catch (error) {
        yield put(actions.fetchColorOverrideFailure({ error }));
        yield put(notificationActions.apiCallError(error, action));
    }
}

export function* watchFetchColorOverride() {
    yield takeEvery(actions.fetchColorOverride, fetchColorOverride);
}

export function* fetchColorOverrideType(action) {
    try {
        const response = yield ColorOverrideAPI.getColorOverrideTypes();
        if (response) {
            yield put(actions.fetchColorOverrideTypeSuccess(response));
        } else {
            yield put(actions.fetchColorOverrideFailure("No Data"));
        }
    } catch (error) {
        yield put(actions.fetchPreferenceFailure({ error }));
        yield put(notificationActions.apiCallError(error, action));
    }
}

export function* watchFetchColorOverrideType() {
    yield takeEvery(actions.fetchColorOverrideType, fetchColorOverrideType);
}

export function* saveColorOverride(action) {
    const LoginGuid = yield select(getLoginGuid);
    const { payload } = action;
    if (payload) {
        try {
            yield ColorOverrideAPI.saveColorOverrides(LoginGuid, payload);
            yield put(actions.saveColorOverrideSuccess(payload));
        } catch (error) {
            if (error instanceof APIError) {
                yield put(setApiResult(error));
            }
            yield put(actions.saveColorOverrideSuccess({ error }));
            yield put(notificationActions.apiCallError(error, action));
        }
    } else {
        yield put(actions.fetchColorOverrideFailure("No Data"));
    }
}

export function* watchSaveColorOverride() {
    yield takeEvery(actions.saveColorOverride, saveColorOverride);
}

export function* deleteColorOverride(action) {
    const loginGuid = yield select(getLoginGuid);
    const { payload } = action;
    if (payload) {
        try {
            yield ColorOverrideAPI.deleteColorOverrides(loginGuid, payload);
            yield put(actions.deleteColorOverrideSuccess(payload));
        } catch (error) {
            if (error instanceof APIError) {
                yield put(setApiResult(error));
            }
            yield put(actions.deleteColorOverrideFailure({ error }));
            yield put(notificationActions.apiCallError(error, action));
        }
    } else {
        yield put(actions.fetchColorOverrideFailure("No Data"));
    }
}

export function* watchDeleteColorOverride() {
    yield takeEvery(actions.deleteColorOverride, deleteColorOverride);
}

const PreferenceSagas = function* () {
    yield all([
        fork(watchFetchPreference),
        fork(watchFetchColorOverride),
        fork(watchFetchColorOverrideType),
        fork(watchSavePreference),
        fork(watchSaveColorOverride),
        fork(watchDeleteColorOverride),
    ]);
};

export default PreferenceSagas;
