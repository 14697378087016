import React from "react";
import { RadioButton } from "~/core";
import "./feature-item.css";
import { NonFieldDataContextMenu } from "./context-menu";
import { NonFieldFeatureAPI } from "@ai360/core";

interface IFeatureItemProps {
    customer: any;
    feature: NonFieldFeatureAPI.NonFieldFeature;
    isHighlighted: boolean;
    onRadioButtonClicked: (isChecked: boolean) => void;
}

export const FeatureItem = (props: IFeatureItemProps) => {
    const { feature, customer } = props;

    const featureType = feature.type;
    const featureTypeLetter = featureType[0];

    const onRadioButtonToggled = (event, isChecked) => {
        props.onRadioButtonClicked(isChecked);
    };

    return (
        <div className="feature-data-item">
            <div className="row top-row">
                <div className="letter-icon">{featureTypeLetter}</div>
                <div className="feature-type">
                    <span>{featureType}</span>
                </div>
            </div>
            <div className="row feature-row">
                <div className="left-column">
                    <RadioButton
                        className="radio-button"
                        checked={props.isHighlighted}
                        ternary={true}
                        onChange={onRadioButtonToggled}
                    />
                </div>
                <div className="feature-name">
                    <span>{feature.name}</span>
                </div>
                <NonFieldDataContextMenu feature={feature} customer={customer} />
            </div>
        </div>
    );
};
