import React, { useState } from "react";
import { injectIntl, intlShape } from "react-intl";
import { connect } from "react-redux";

import { Menu } from "~/core";
import { messages } from "../../../context-menus/i18n-messages";
import { messages as globalMessages } from "~/i18n-messages";
import { DeleteConfDialog } from "../../../context-menus/item-delete-confirmation-dialog";
import "./sampling-item-context-menu.css";

import * as samplingActions from "../../components/sampling/actions";
import { logFirebaseEvent } from "~/utils/firebase";
import { AgEventAPI, NonFieldFeatureAPI } from "@ai360/core";

interface ISampleItemContextMenuProps {
    editSample: (nonFieldFeature, waterSamplingGuid) => void;
    deleteSample: (waterSamplingGuid, nonFieldFeatureGuid, customerGuid) => void;
    intl: intlShape;
    nonFieldFeature: NonFieldFeatureAPI.NonFieldFeature;
    sampleSummary: AgEventAPI.IWaterSamplingSummary;
}

const SampleItemContextMenu_ = (props: ISampleItemContextMenuProps) => {
    const { formatMessage } = props.intl;
    const { sampleSummary, nonFieldFeature } = props;

    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const editOrResultsMsg = props.sampleSummary.hasResults
        ? formatMessage(messages.viewResults)
        : formatMessage(messages.editSample);
    const menuItems = [
        {
            disabled: false,
            label: editOrResultsMsg,
            action: () =>
                props.editSample(props.nonFieldFeature, props.sampleSummary.waterSamplingGuid),
        },
        {
            disabled: false,
            label: formatMessage(messages.deleteSample),
            action: () => setShowDeleteDialog(true),
        },
    ].map((menuItem, key) => {
        return { ...menuItem, key };
    });

    const deleteDialog = !showDeleteDialog
        ? null
        : DeleteConfDialog({
              formatMessage,
              deleteConfirmationTitle: globalMessages.confirmTitle,
              deleteConfirmationMessage: messages.deleteSampleConfirmation,
              onConfirmationClick: (confirmation) => {
                  setShowDeleteDialog(false);
                  if (confirmation) {
                      logFirebaseEvent("water_sample_delete_feature");
                      props.deleteSample(
                          sampleSummary.waterSamplingGuid,
                          nonFieldFeature.id,
                          nonFieldFeature.customerId
                      );
                  }
              },
          });

    return (
        <div>
            <div
                className="context-menu-container sampling-item-context-menu-container"
                onClick={(evt) => evt.preventDefault()}
            >
                <Menu
                    className="context-menu sampling-item-context-menu"
                    isDotMenu={true}
                    getMenuItems={() => menuItems}
                />
            </div>
            {deleteDialog}
        </div>
    );
};

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
    editSample: (nonFieldFeature, waterSamplingGuid) =>
        dispatch(samplingActions.editSampling(nonFieldFeature, waterSamplingGuid)),
    deleteSample: (waterSamplingGuid, nonFieldFeatureGuid, customerGuid) =>
        dispatch(
            samplingActions.deleteSampling(waterSamplingGuid, nonFieldFeatureGuid, customerGuid)
        ),
});

const mergeProps = (_, dispatchProps, ownProps) => {
    return {
        ...dispatchProps,
        ...ownProps,
    };
};

export const SampleItemContextMenu = connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
)(injectIntl(SampleItemContextMenu_));
