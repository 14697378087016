import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { LayerList } from "./layer-list/layer-list";
import { AnalysisInfo } from "./analysis-info/analysis-info";
import { ImageryLayer } from "./imagery/imagery-layer";
import NonFieldFeatureInformation from "./non-field-feature-information/non-field-feature-information";
import WaterSamplingForm from "./sampling/water-sampling-form";

import * as actions from "../actions";
import * as selectors from "../selectors";

import "./layer-module.css";

interface ILayerModuleProps {
    activePage: string;
}

export class LayerModule_ extends Component<ILayerModuleProps> {
    static propTypes = {
        activePage: PropTypes.string.isRequired,
    };

    _getCurrentPage() {
        const { activePage } = this.props;

        if (activePage === actions.LayerModulePages.LAYER_LIST) {
            return <LayerList />;
        } else if (activePage === actions.LayerModulePages.ANALYSIS_INFORMATION) {
            return <AnalysisInfo />;
        } else if (activePage === actions.LayerModulePages.IMAGERY_LAYER) {
            return <ImageryLayer />;
        } else if (activePage === actions.LayerModulePages.NON_FIELD_FEATURE_INFORMATION) {
            return <NonFieldFeatureInformation />;
        } else if (activePage === actions.LayerModulePages.WATER_SAMPLING) {
            return <WaterSamplingForm />;
        }
    }

    render() {
        return <div className="layer-module">{this._getCurrentPage()}</div>;
    }
}

const mapStateToProps = (state) => {
    const { activePage } = selectors.getModuleState(state);
    return { activePage };
};

export const LayerModule = connect(mapStateToProps, null)(LayerModule_);
