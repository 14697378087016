import React, { Component } from "react";
import { connect } from "react-redux";
import { injectIntl, intlShape } from "react-intl";

import { DialogBox, DialogBoxFooterType, Loader, LoaderTypes, NoLink, SelectInput } from "~/core";
import { getTheUserGuid } from "~/login";
import { actions as notificationActions } from "~/notifications";
import { LayerAPI, LayerUtilsAPI } from "@ai360/core";

import { ACTIVE_YN } from "~/core/picklist";

import { messages } from "../../../i18n-messages";
import * as selectors from "../../selectors";
import { colorOptionRenderer, getBackgroundGradientStyle } from "../../../../utils";

interface IManualSurfacePropertiesProps {
    colorSchemeOptions: any[];
    intl: intlShape;
    isOpen: boolean;
    layer: any;
    letterIcon: string;
    onApiError: (err, message) => void;
    onClose: () => void;
    onSave: (surface) => void;
    surface: any;
    type: number;
    userGuid: string;
}

interface IManualSurfacePropertiesState {
    attributeGuid: string;
    colorSchemeGuid: string;
    isLoading: boolean;
    surfaceGuid: string;
}

class ManualSurfaceProperties_ extends Component<
    IManualSurfacePropertiesProps,
    IManualSurfacePropertiesState
> {
    constructor(props) {
        super(props);
        this.state = {
            attributeGuid: props.layer.selectedAttributeGuid,
            colorSchemeGuid: props.surface.colorSchemeGuid,
            isLoading: false,
            surfaceGuid: this._getSurfaceGuidFromProps(props),
        };
    }

    colorSchemeSelect = null;

    _getSurfaceGuidFromProps = (props) =>
        props.layer.selectedSurfaceGuid || props.surface.surfaceGuid;

    _onSave() {
        const { attributeGuid, colorSchemeGuid, surfaceGuid } = this.state;
        this.props.onSave({
            attributeGuid,
            colorSchemeGuid,
            surfaceGuid,
        });
    }

    _resetToDefault() {
        const { layer, surface, type, userGuid } = this.props;
        const attributeName = LayerUtilsAPI.getAttributeGuidFieldName(type);

        if (
            layer.originalAttributeGuid &&
            [LayerUtilsAPI.LayerType.SOIL, LayerUtilsAPI.LayerType.REC].includes(type)
        ) {
            //NOTE: Soils currently does not save your chosen attribute and Manual Recs only have
            //one attribute, and neither saves the coloScheme ... so we're simply grabbing the
            //original attributeGuid from the original request and calling that the default
            this._updateColorScheme(layer.originalColorSchemeGuid, layer.originalAttributeGuid);
        } else if (type === LayerUtilsAPI.LayerType.EVENT_MANUAL) {
            this.setState({ isLoading: true }, () => {
                LayerAPI.getDefaultAttributeGuid(userGuid, {
                    AgEventGeneralGuid: surface.agEventGeneralGuid,
                    AgEventTransactionTypeGuid: surface.agEventTransactionTypeGuid,
                    isManual: true,
                })
                    .then((result) => {
                        const firstAttributeLayer = layer.subLayers.find(
                            (slyr) => slyr[attributeName] === result[attributeName]
                        );
                        this._updateAttribute(
                            firstAttributeLayer[attributeName],
                            firstAttributeLayer.surfaceGuid
                        );
                    })
                    .catch((err) => {
                        this.props.onApiError(err, messages.failedToResetToDefault);
                    })
                    .finally(() => this.setState({ isLoading: false }));
            });
        } else {
            this._updateColorScheme(surface.colorSchemeGuid, layer.selectedAttributeGuid);
        }
    }

    _updateAttribute(attributeGuid, surfaceGuid) {
        const { layer, type } = this.props;
        const attributeName = LayerUtilsAPI.getAttributeGuidFieldName(type);
        const otherSurface = layer.subLayers.find(
            (s) => s.surfaceGuid === surfaceGuid && s[attributeName] === attributeGuid
        );
        this._updateColorScheme(otherSurface.colorSchemeGuid, attributeGuid, surfaceGuid);
    }

    _updateColorScheme(
        colorSchemeGuid,
        attributeGuid = this.state.attributeGuid,
        surfaceGuid = this.state.surfaceGuid
    ) {
        this.setState({ colorSchemeGuid, attributeGuid, surfaceGuid });
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.isOpen) {
            this._updateColorScheme(
                this.props.surface.colorSchemeGuid,
                this.props.layer.selectedAttributeGuid,
                this._getSurfaceGuidFromProps(this.props)
            );
        }
    }

    render() {
        const { colorSchemeOptions, isOpen, layer, letterIcon, onClose, surface, type } =
            this.props;
        const { formatMessage } = this.props.intl;
        const { colorSchemeGuid, isLoading, surfaceGuid, attributeGuid } = this.state;

        const attributeName = LayerUtilsAPI.getAttributeGuidFieldName(type);

        let placeholderText = formatMessage(messages.attribute);
        const isSoil = type === LayerUtilsAPI.LayerType.SOIL;
        if (isSoil) {
            placeholderText = formatMessage(messages.soilTypeAttribute);
        }
        const attributeOptions = layer.subLayers
            .map((subLayer) => {
                return {
                    label: subLayer.displayName,
                    value: !isSoil ? subLayer.surfaceGuid : subLayer[attributeName],
                };
            })
            .sort((a, b) => a.label.localeCompare(b.label));
        const colorSchemeSelectOptions = colorSchemeOptions.map((colorScheme) => {
            return {
                background: getBackgroundGradientStyle(colorScheme, true),
                label: " ",
                value: colorScheme.colorSchemeGuid,
            };
        });

        const colorScheme = colorSchemeOptions.find((cs) => cs.colorSchemeGuid === colorSchemeGuid);

        const backgroundImageStyle = getBackgroundGradientStyle(colorScheme, true);
        const backgroundImageCss = {
            backgroundImage: `${backgroundImageStyle}`,
        };

        return (
            <DialogBox
                className="surface-properties-dialog"
                draggable
                footerType={DialogBoxFooterType.ACTION_CANCEL}
                forceOverflow
                isOpen={isOpen}
                letterIcon={letterIcon}
                onAction={() => this._onSave()}
                onClose={() => onClose()}
                title={`${layer.displayName} - ${formatMessage(messages.layerProperties)}`}
            >
                {type === LayerUtilsAPI.LayerType.REC ||
                type === LayerUtilsAPI.LayerType.EVENT_MANUAL ? (
                    <div className="attribute-label">{surface.displayName}</div>
                ) : (
                    <SelectInput
                        clearable={false}
                        optionIsHiddenKey={ACTIVE_YN}
                        onChange={(e) => {
                            const newLayer = layer.subLayers.find((slyr) =>
                                isSoil ? slyr[attributeName] === e : slyr.surfaceGuid === e
                            );

                            this._updateAttribute(newLayer[attributeName], newLayer.surfaceGuid);
                        }}
                        options={attributeOptions}
                        placeholderText={placeholderText}
                        value={isSoil ? attributeGuid : surfaceGuid}
                    />
                )}
                <SelectInput
                    inputCSS={backgroundImageCss}
                    clearable={false}
                    optionIsHiddenKey={ACTIVE_YN}
                    filterable={false}
                    onChange={(e) => this._updateColorScheme(e)}
                    options={colorSchemeSelectOptions}
                    optionRenderer={colorOptionRenderer}
                    placeholderText={formatMessage(messages.colorScheme)}
                    ref={(ref) => (this.colorSchemeSelect = ref)}
                    value={colorSchemeGuid}
                />
                <NoLink
                    label={formatMessage(messages.resetToDefault)}
                    onClick={() => this._resetToDefault()}
                />
                {!isLoading ? null : <Loader type={LoaderTypes.LINE_SCALE_PULSE_OUT} />}
            </DialogBox>
        );
    }
}

const mapDispatchToProps = (dispatch) => ({
    onApiError: (err, message) => dispatch(notificationActions.apiCallError(err, null, message)),
});

const mapStateToProps = (state) => ({
    colorSchemeOptions: selectors.getColorSchemeOptions(state),
    userGuid: getTheUserGuid(state),
});

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
});

export const ManualSurfaceProperties = connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
)(injectIntl(ManualSurfaceProperties_));
