import React, { Component } from "react";
import { connect } from "react-redux";
import { InjectedIntl, injectIntl } from "react-intl";

import { getActiveModule } from "~/action-panel";
import { Menu } from "~/core";
import { getUser } from "~/login";
import { SurfaceType, Confirm } from "@ai360/core";
import { actions as recsEventsActions, recsSelectors, recsModels } from "~/recs-events";
import { LayerAPI, LayerUtilsAPI, UserAPI } from "@ai360/core";

import * as analysisActions from "../analysis-info/actions";
import * as imageryActions from "../imagery/actions";
import { messages } from "../i18n-messages";
import { keywords } from "../../../import-module/keywords";

import "./surface-layer-context-menu.css";
import { logFirebaseEvent } from "~/utils/firebase";
import AnalysisBatchDeleteDialogBox from "../analysis-info/analysis-batch-delete-dialog-modal";
import AnalysisDeleteDialogBox from "../analysis-info/analysis-delete-dialog-modal";

interface IProps {
    field: any;
    onEditProps: () => void;
    onLayerStats: () => void;
    onPrintLayer: (rendererGuid: string, renderPoints: boolean, imageryLayerGuid: string) => void;
    surfaceInfo: LayerAPI.ISubLayer;
    onSampleSites: boolean;
    onDeleteAnalysisLayer: (analysisLayerGuid: string, fieldGuid: string) => void;
    onDeleteAnalysisLayerBatch: (
        batchGuid: string,
        analysisInBatch: LayerAPI.IAnalysisInBatch[]
    ) => void;
    onDeleteImageryLayer: (imageryLayerGuid: string, fieldGuid: string) => void;
    onDeleteAnalysisSurface: (
        surfaceGuid: string,
        analysisLayerGuid: string,
        fieldGuid: string
    ) => void;
    type: number;
    activeModule: string;
    onCreateEventFromLayer: (
        field: any,
        surfaceInfo: LayerAPI.ISubLayer,
        activeModule: string
    ) => void;
    onCreateRecFromLayer: (
        field: any,
        surfaceInfo: LayerAPI.ISubLayer,
        recTypeInfo: recsModels.RecTypeInfo,
        activeModule: string
    ) => void;
    onShowAnalysisLayer: (analysisInfoGuid: string) => void;
    onShowImageryLayer: (surfaceInfo: LayerAPI.ISubLayer, fieldGuid: string) => void;
    recTypeOptions: { label: string; value: recsModels.RecTypeInfo }[];
    userInfo: UserAPI.IUser;
    intl: InjectedIntl;
}

interface IState {
    analysisLayerGuid: string;
    imageryLayerGuid: string;
    confirmationType: Confirm;
    isConfirmationModalOpen: boolean;
}

class SurfaceLayerContextMenu_ extends Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            analysisLayerGuid: "",
            imageryLayerGuid: "",
            confirmationType: Confirm.NONE,
            isConfirmationModalOpen: false,
        };
    }

    private isCreateEventAvailable() {
        const { surfaceInfo, userInfo } = this.props;
        const isImagerySetup =
            this.isAnalysisLayer(surfaceInfo) && surfaceInfo.surfaceType === "Imagery";
        return (
            userInfo.role.agEvents &&
            ((!surfaceInfo.imageryLayerGuid && !isImagerySetup) ||
                (isImagerySetup && surfaceInfo.classBreaks?.length > 0))
        );
    }

    private isCreateRecAvailable() {
        const { surfaceInfo, userInfo } = this.props;
        const isImagerySetup =
            this.isAnalysisLayer(surfaceInfo) && surfaceInfo.surfaceType === "Imagery";
        return (
            userInfo.role.recommendations &&
            ((!surfaceInfo.imageryLayerGuid && !isImagerySetup) ||
                (isImagerySetup && surfaceInfo.classBreaks?.length > 0))
        );
    }

    private onDeleteSingle() {
        const { field, surfaceInfo } = this.props;
        const isImagerySetup =
            this.isAnalysisLayer(surfaceInfo) && surfaceInfo.surfaceType === "Imagery";

        if (this.state.confirmationType) {
            if (isImagerySetup) {
                this.props.onDeleteAnalysisSurface(
                    surfaceInfo.surfaceGuid,
                    surfaceInfo.analysisLayerGuid,
                    field.fieldGuid
                );
            } else if (this.state.analysisLayerGuid) {
                this.props.onDeleteAnalysisLayer(this.state.analysisLayerGuid, field.fieldGuid);
            } else if (this.state.imageryLayerGuid) {
                this.props.onDeleteImageryLayer(this.state.imageryLayerGuid, field.fieldGuid);
            }
        }
        this.closeConfirmDialog();
    }

    private closeConfirmDialog() {
        this.setState({
            analysisLayerGuid: "",
            imageryLayerGuid: "",
            confirmationType: Confirm.NONE,
            isConfirmationModalOpen: false,
        });
    }

    private getImagerySetupItems() {
        const { onPrintLayer, surfaceInfo, userInfo } = this.props;
        const { formatMessage } = this.props.intl;
        const imagerySetupOptions = [];
        if (userInfo.role.imagerySetup) {
            imagerySetupOptions.push({
                label: formatMessage(messages.deleteImageryLayer),
                action: () =>
                    this.openConfirmDialog(Confirm.DELETE, surfaceInfo.analysisLayerGuid, null),
            });
        }
        imagerySetupOptions.push({
            label: formatMessage(messages.printLayer),
            action: () =>
                onPrintLayer(
                    surfaceInfo.surfaceRendererGuid,
                    SurfaceType.parse(surfaceInfo.surfaceType, surfaceInfo.classBreaks) ===
                        SurfaceType.CanvasLayer,
                    surfaceInfo.importFileGuid
                ),
        });
        return imagerySetupOptions;
    }

    private getManagementAreaItems() {
        const { surfaceInfo, onEditProps, onShowAnalysisLayer } = this.props;
        const { formatMessage } = this.props.intl;
        return [
            {
                label: formatMessage(messages.editAnalysisLayer),
                action: () => onShowAnalysisLayer(surfaceInfo.analysisLayerGuid),
            },
            {
                label: formatMessage(messages.editLayerProperties),
                action: () => onEditProps(),
            },
            {
                label: formatMessage(messages.deleteAnalysisLayer),
                action: () =>
                    this.openConfirmDialog(Confirm.DELETE, surfaceInfo.analysisLayerGuid, null),
            },
        ];
    }

    private getMenuItems() {
        const {
            activeModule,
            field,
            onCreateEventFromLayer,
            onCreateRecFromLayer,
            onEditProps,
            onLayerStats,
            onPrintLayer,
            onSampleSites,
            onShowAnalysisLayer,
            onShowImageryLayer,
            recTypeOptions,
            surfaceInfo,
            type,
            userInfo,
        } = this.props;
        const { formatMessage } = this.props.intl;
        const editMenuItems = [];
        const isImagerySetup =
            this.isAnalysisLayer(surfaceInfo) && surfaceInfo.surfaceType === "Imagery";
        if (onSampleSites) {
            editMenuItems.push({
                label: formatMessage(messages.printLayer),
                action: () => onPrintLayer(null, false, null),
            });
        } else {
            if (userInfo.role.layerAnalysis && this.isAnalysisLayer(surfaceInfo)) {
                if (userInfo.role.analysisLayers) {
                    if (type === LayerUtilsAPI.LayerType.MANAGEMENT_AREA) {
                        editMenuItems.push(...this.getManagementAreaItems());
                    } else if (isImagerySetup) {
                        editMenuItems.push(...this.getImagerySetupItems());
                    } else {
                        editMenuItems.push(
                            {
                                label: formatMessage(messages.editAnalysisLayer),
                                action: () => onShowAnalysisLayer(surfaceInfo.analysisLayerGuid),
                            },
                            {
                                label: formatMessage(messages.deleteAnalysisLayer),
                                action: () =>
                                    this.openConfirmDialog(
                                        Confirm.DELETE,
                                        surfaceInfo.analysisLayerGuid,
                                        null
                                    ),
                            }
                        );
                    }
                }
            } else if (surfaceInfo.imageryLayerGuid) {
                editMenuItems.push(
                    {
                        label: formatMessage(messages.editImageryLayer),
                        action: () => onShowImageryLayer(surfaceInfo, field.fieldGuid),
                    },
                    {
                        label: formatMessage(messages.deleteImageryLayer),
                        action: () =>
                            this.openConfirmDialog(
                                Confirm.DELETE,
                                null,
                                surfaceInfo.imageryLayerGuid
                            ),
                    }
                );
            } else {
                editMenuItems.push({
                    label: formatMessage(messages.editLayerProperties),
                    action: () => onEditProps(),
                });
            }

            if (
                !isImagerySetup &&
                (surfaceInfo.surfaceRendererGuid ||
                    (surfaceInfo.imageryLayerGuid && surfaceInfo.importFileGuid))
            ) {
                editMenuItems.push({
                    label: formatMessage(messages.printLayer),
                    action: () =>
                        onPrintLayer(
                            surfaceInfo.surfaceRendererGuid,
                            SurfaceType.parse(surfaceInfo.surfaceType, surfaceInfo.classBreaks) ===
                                SurfaceType.CanvasLayer,
                            surfaceInfo.importFileGuid
                        ),
                });
            }

            if (
                surfaceInfo.agEventTransactionType === keywords.HARVEST &&
                type === LayerUtilsAPI.LayerType.EVENT_IMPORTED
            ) {
                editMenuItems.push({
                    label: formatMessage(messages.layerStats),
                    action: () => onLayerStats(),
                });
            }

            if (this.isCreateEventAvailable()) {
                editMenuItems.push({
                    label: formatMessage(messages.createEventUsingLayer),
                    action: () => {
                        onCreateEventFromLayer(field, surfaceInfo, activeModule);
                    },
                    disabled:
                        surfaceInfo.surfaceTypeDisplayName === "Coverage" ||
                        surfaceInfo.surfaceTypeDisplayName === "Points",
                });
            }

            if (this.isCreateRecAvailable()) {
                editMenuItems.push({
                    label: formatMessage(messages.createRecUsingLayer),
                    subMenuItems: recTypeOptions.map((recTypeOption) => ({
                        key: recTypeOption.label,
                        label: recTypeOption.label,
                        action: () => {
                            switch (recTypeOption.label) {
                                case recsModels.REC_TYPE_NAME_EQUATION_APPLICATION:
                                    logFirebaseEvent("create_rec_using_layer_equation_application");
                                    break;
                                case recsModels.REC_TYPE_NAME_MANUAL_APPLICATION:
                                    logFirebaseEvent("create_rec_using_layer_manual_application");
                                    break;
                                case recsModels.REC_TYPE_NAME_EQUATION_PLANTING:
                                    logFirebaseEvent("create_rec_using_layer_equation_planting");
                                    break;
                                case recsModels.REC_TYPE_NAME_MANUAL_PLANTING:
                                    logFirebaseEvent("create_rec_using_layer_manual_planting");
                                    break;
                            }
                            onCreateRecFromLayer(
                                field,
                                surfaceInfo,
                                recTypeOption.value,
                                activeModule
                            );
                        },
                    })),
                    disabled:
                        surfaceInfo.surfaceTypeDisplayName === "Coverage" ||
                        surfaceInfo.surfaceTypeDisplayName === "Points",
                });
            }
        }
        return editMenuItems.map((menuItem, key) => {
            return { ...menuItem, key };
        });
    }

    private openConfirmDialog(confirmationType, analysisLayerGuid, imageryLayerGuid) {
        this.setState({
            analysisLayerGuid: analysisLayerGuid ? analysisLayerGuid : null,
            imageryLayerGuid: imageryLayerGuid ? imageryLayerGuid : null,
            confirmationType,
            isConfirmationModalOpen: true,
        });
    }

    private isBatched(surfaceInfo) {
        return (
            this.isAnalysisLayer(surfaceInfo) &&
            surfaceInfo.analysisInBatch &&
            surfaceInfo.analysisInBatch.length > 1
        );
    }

    private isAnalysisLayer(surfaceInfo) {
        return surfaceInfo.analysisLayerTypeGuid && surfaceInfo.analysisLayerTypeGuid.length > 0;
    }

    private deleteDialogBox() {
        return (
            <AnalysisDeleteDialogBox
                closeConfirmDialog={() => this.closeConfirmDialog()}
                isConfirmationModalOpen={this.state.isConfirmationModalOpen}
                onDeleteSingle={() => this.onDeleteSingle()}
            ></AnalysisDeleteDialogBox>
        );
    }

    private batchedDeleteDialogBox() {
        return (
            <AnalysisBatchDeleteDialogBox
                closeConfirmDialog={() => this.closeConfirmDialog()}
                isConfirmationModalOpen={this.state.isConfirmationModalOpen}
                onDeleteSingle={() => this.onDeleteSingle()}
                surfaceInfo={this.props.surfaceInfo}
            ></AnalysisBatchDeleteDialogBox>
        );
    }

    render() {
        const { surfaceInfo } = this.props;
        const { confirmationType } = this.state;

        const deleteDialogBox =
            confirmationType !== Confirm.DELETE
                ? null
                : this.isBatched(surfaceInfo) && surfaceInfo.surfaceType !== "Imagery"
                ? this.batchedDeleteDialogBox()
                : this.deleteDialogBox();

        return (
            <div className="context-menu-container">
                <Menu
                    className="context-menu"
                    isDotMenu={true}
                    getMenuItems={() => this.getMenuItems()}
                />
                {deleteDialogBox}
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    recTypeOptions: recsSelectors.getNewableRecTypeOptions(state),
    activeModule: getActiveModule(state),
    userInfo: getUser(state),
});

const mapDispatchToProps = (dispatch) => ({
    onCreateEventFromLayer: (field, surfaceInfo, activeModule) =>
        dispatch(recsEventsActions.createNewClassifiedEvent(field, surfaceInfo, "", activeModule)),
    onCreateRecFromLayer: (field, surfaceInfo, recTypeInfo, activeModule) =>
        dispatch(
            recsEventsActions.createNewClassifiedRec(field, surfaceInfo, recTypeInfo, activeModule)
        ),
    onShowAnalysisLayer: (analysisInfoGuid) =>
        dispatch(analysisActions.showAnalysisInfo(analysisInfoGuid)),
    onShowImageryLayer: (surfaceInfo, fieldGuid) =>
        dispatch(imageryActions.showImageryLayer(surfaceInfo, fieldGuid)),
    onDeleteAnalysisLayer: (analysisLayerGuid, fieldGuid) =>
        dispatch(analysisActions.deleteAnalysisLayer(analysisLayerGuid, fieldGuid, null)),
    onDeleteAnalysisLayerBatch: (batchGuid, analysisInBatch) =>
        dispatch(analysisActions.deleteAnalysisLayerBatch(batchGuid, analysisInBatch)),
    onDeleteImageryLayer: (imageryLayerGuid, fieldGuid) =>
        dispatch(imageryActions.deleteImageryLayer(imageryLayerGuid, fieldGuid)),
    onDeleteAnalysisSurface: (surfaceGuid, analysisLayerGuid, fieldGuid) =>
        dispatch(analysisActions.deleteAnalysisSurface(surfaceGuid, analysisLayerGuid, fieldGuid)),
});

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
});

export const SurfaceLayerContextMenu = connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
)(injectIntl(SurfaceLayerContextMenu_));
