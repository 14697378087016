import * as actions from "./actions";

const initialState = {
    isLoading: false,
    nonFieldFeature: null,
    sampling: null,
};

export const samplingReducer = (state = initialState, action) => {
    switch (action.type) {
        case actions.SET_SAMPLING: {
            const { sampling } = action.payload;
            return Object.freeze({
                ...state,
                sampling: sampling,
            });
        }
        case actions.SET_SAMPLING_DETAILS_LOADING: {
            const { isLoading } = action.payload;
            return Object.freeze({
                ...state,
                isLoading,
            });
        }
        case actions.SET_NON_FIELD_FEATURE: {
            const { nonFieldFeature } = action.payload;
            return Object.freeze({
                ...state,
                nonFieldFeature: nonFieldFeature,
            });
        }
        case actions.UPDATE_SAMPLING: {
            const { newProps } = action.payload;
            return Object.freeze({
                ...state,
                sampling: {
                    ...state.sampling,
                    ...newProps,
                },
            });
        }
        default:
            return state;
    }
};
