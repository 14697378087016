import React from "react";
import "./sampling-item.css";
import { AgEventAPI, NonFieldFeatureAPI } from "@ai360/core";
import { SampleItemContextMenu } from "../../context-menus/sampling-item-context-menu";

interface ISamplingItemProps {
    nonFieldFeature: NonFieldFeatureAPI.NonFieldFeature;
    sampleSummary: AgEventAPI.IWaterSamplingSummary;
}

export const SamplingItem = (props: ISamplingItemProps) => {
    const { nonFieldFeature, sampleSummary } = props;

    const labelPrefix =
        "Sampling - Water - " + sampleSummary.croppingSeasonName + " - " + sampleSummary.sampleDate;
    const labelSuffix = sampleSummary.eventName ? ` - ${sampleSummary.eventName}` : "";
    const label = labelPrefix + labelSuffix;

    const status = sampleSummary.hasResults ? "Complete" : null;
    return (
        <div className="layer-accordion-item-sampling">
            <div className="layer-label" key={sampleSummary.waterSamplingGuid} title={label}>
                {label}
            </div>
            {!status ? (
                <div
                    title={`${sampleSummary.modifiedBy} - ${sampleSummary.modifiedDate}`}
                    className={"layer-status-event-id"}
                >
                    {sampleSummary.eventId}
                </div>
            ) : (
                <div
                    title={`${sampleSummary.modifiedBy} - ${sampleSummary.modifiedDate}`}
                    className={"layer-status-completed"}
                >
                    {status}
                </div>
            )}
            <SampleItemContextMenu
                nonFieldFeature={nonFieldFeature}
                sampleSummary={sampleSummary}
            />
        </div>
    );
};
