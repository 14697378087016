import { all, call, put, select, take, takeLatest } from "redux-saga/effects";

import { Set } from "immutable";

import * as actions from "./actions";
import * as selectors from "./selectors";

import * as layerModuleActions from "../../../layer-module/actions";
import { LayerModulePages } from "../../../layer-module/actions";
import * as actionPanelActions from "~/action-panel/actions";
import { ActionPanelModuleList } from "~/action-panel/actions";
import * as toolsetActions from "~/map/components/map-tools/actions";
import { actions as cdActions, selectors as cdSelectors } from "~/customer-data";
import { getTheUserGuid } from "~/login/selectors";
import { actions as mcActions, selectors as mcSelectors } from "~/map/components/map-control";
import { fetchPicklistData } from "~/core/picklist/actions";
import { picklists } from "./components/detail/detail";
import { Toolset, MapConfig, SearchAPI } from "@ai360/core";

const executeAfterInformationDone = function* (execution, toolsetPayload) {
    const userGuid = yield select(getTheUserGuid);
    const { customerGuid } = yield select(selectors.getActiveCustomer);

    const fields = yield call(SearchAPI.getFields, {
        userGuid,
        customerGuid: [customerGuid],
        active: true,
    });

    yield put(actions.setFields(fields));

    yield put(toolsetActions.setActiveToolset(Toolset.DRAW_NON_FIELD_FEATURE, toolsetPayload));
    yield put(fetchPicklistData(picklists));
    yield put(actionPanelActions.setActiveModule(ActionPanelModuleList.LAYER));
    yield put(layerModuleActions.setActivePage(LayerModulePages.NON_FIELD_FEATURE_INFORMATION));
    yield put(actionPanelActions.setIsEnabled(false));

    const { savedFeature } = yield take(actions.STOP_EDITING_FEATURE);

    yield execution(savedFeature);

    yield put(layerModuleActions.setActivePage(LayerModulePages.LAYER_LIST));
    yield put(toolsetActions.setActiveToolset(Toolset.DEFAULT));
    yield put(actionPanelActions.setIsEnabled(true));
};

const addFeature = function* (action) {
    const { featureToEdit, customer } = action;
    featureToEdit.active = true;
    featureToEdit.waterSamples = [];
    const scale = yield select(mcSelectors.getScale);
    if (customer.fields > 0 && scale >= MapConfig.layers.fields.minScale) {
        yield put(mcActions.setZoomToCustomer(customer.customerGuid));
    }
    const previousHighlightedFeatures = yield select(cdSelectors.getHighlightedNonFieldFeatures);
    yield put(cdActions.setHighlightedNonFieldFeatures(Set()));

    const execution = function* (savedFeature) {
        if (savedFeature) {
            yield put(cdActions.saveNonFieldFeature(savedFeature));
            yield put(cdActions.modifyNonFieldFeatures({ add: [savedFeature] }));
        }

        yield put(cdActions.setHighlightedNonFieldFeatures(previousHighlightedFeatures));
    };
    yield executeAfterInformationDone(execution, {
        feature: featureToEdit,
        customer,
    });
};

const editFeature = function* (action) {
    const { originalFeature, featureToEdit, customer } = action;
    featureToEdit.active = true;
    const previousHighlightedFeatures = yield select(cdSelectors.getHighlightedNonFieldFeatures);
    yield put(cdActions.setHighlightedNonFieldFeatures(Set()));
    yield put(mcActions.setZoomToNonFieldFeatures([featureToEdit]));

    yield put(cdActions.modifyVisibleNonFieldFeatures({ remove: [originalFeature] }));

    const execution = function* (savedFeature) {
        const wasHighlighted = previousHighlightedFeatures.has(originalFeature);

        let highlightFeatures;

        if (savedFeature) {
            yield put(cdActions.saveNonFieldFeature(savedFeature));
            yield put(
                cdActions.modifyNonFieldFeatures({
                    replace: [{ source: originalFeature, target: savedFeature }],
                })
            );
            yield put(cdActions.modifyVisibleNonFieldFeatures({ add: [savedFeature] }));

            const guaranteedHighlightFeatures = previousHighlightedFeatures.subtract([
                originalFeature,
            ]);
            highlightFeatures = wasHighlighted
                ? guaranteedHighlightFeatures.union([savedFeature])
                : guaranteedHighlightFeatures;
        } else {
            yield put(
                cdActions.modifyVisibleNonFieldFeatures({
                    add: [originalFeature],
                })
            );
            highlightFeatures = previousHighlightedFeatures;
        }

        yield put(cdActions.setHighlightedNonFieldFeatures(highlightFeatures));
    };
    yield executeAfterInformationDone(execution, {
        feature: featureToEdit,
        customer,
    });
};

export const nonFieldFeatureInformationSaga = function* () {
    yield all([
        takeLatest(actions.START_ADDING_FEATURE, addFeature),
        takeLatest(actions.START_EDITING_FEATURE, editFeature),
    ]);
};
