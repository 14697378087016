import { createReducer } from "redux-act";
import * as actions from "./actions";

export const USER_PREFERENCE_DATA_KEY = "USER_PREFERENCE_DATA";

const initialState = {
    colorOverrides: {},
    colorOverrideTypes: {},
    dropdowns: {
        brandOrgData: [],
        cropData: [],
        varietyHybridData: [],
    },
    userPreference: {},
};

export const userPreferenceReducer = createReducer(
    {
        [actions.fetchDropdownDataSuccess]: (state, dropdowns) => ({
            ...state,
            dropdowns: {
                ...state.dropdowns,
                ...dropdowns,
            },
        }),
        [actions.fetchPreferenceSuccess]: (state, userPreference) => ({
            ...state,
            userPreference,
        }),
        [actions.savePreferenceSuccess]: (state, userPreference) => ({
            ...state,
            userPreference,
        }),
        [actions.fetchColorOverrideSuccess]: (state, colorOverrides) => ({
            ...state,
            colorOverrides,
        }),
        [actions.fetchColorOverrideTypeSuccess]: (state, colorOverrideTypes) => ({
            ...state,
            colorOverrideTypes,
        }),
    },
    initialState
);
