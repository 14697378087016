import { all } from "redux-saga/effects";

import { imagerySaga } from "./components/imagery/sagas";
import { analysisInfoSaga } from "./components/analysis-info/sagas";
import { layerListSaga } from "./components/layer-list/sagas";
import { nonFieldFeatureInformationSaga } from "./components/non-field-feature-information/sagas";
import { samplingSaga } from "./components/sampling/sagas";

export const layerModuleSaga = function* () {
    yield all([
        imagerySaga(),
        analysisInfoSaga(),
        layerListSaga(),
        nonFieldFeatureInformationSaga(),
        samplingSaga(),
    ]);
};
