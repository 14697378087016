import React, { Component, MouseEvent as ReactMouseEvent, ReactNode } from "react";

import { injectIntl, intlShape, defineMessages } from "react-intl";
import classnames from "classnames";
import onClickOutside from "react-onclickoutside";

import { FormattingHelpers } from "@ai360/core";

import { PencilIcon, TrashcanIcon } from "~/core/icons";

import PlainTableHeader from "./components/plain-table-header";
import TableCell from "../data-table/components/table-cell";

import "./zero-to-n-grid.css";
import { IColumnData, IRecord, IIndex, ITableService } from "../interfaces";
import { SortDirectionType } from "react-virtualized";

const messages = defineMessages({
    delete: { id: "plainGrid.delete", defaultMessage: "Delete" },
    edit: { id: "plainGrid.edit", defaultMessage: "Edit" },
});

export interface IZeroToInfiniteGridProps {
    children?: ReactNode;
    className?: string;
    columns?: IColumnData | IColumnData[];
    disabled?: boolean;
    intl: intlShape;
    onDelete?: (record: IRecord, index: IIndex) => void;
    onEdit?: (record: IRecord, index: IIndex) => void;
    onItemSelection?: () => void;
    records?: IRecord[];
    rowClassName?: string;
    selectedItems?: IRecord;
    selectedRow?: IRecord;
    showHeader?: boolean;
    toggleChildComponent?: () => void;
    formatCellValue?: (value: string) => React.ReactElement;
    formatColumnKeys?: string[];
}

export interface IZeroToInfiniteGridState {
    selectedRow: IRecord;
    records: IRecord[];
    sortDirection: SortDirectionType;
    sortColumnName: string;
}

class ZeroToInfiniteGrid extends Component<IZeroToInfiniteGridProps, IZeroToInfiniteGridState> {
    constructor(props) {
        super(props);
        const { records } = props;
        this.state = {
            selectedRow: { canDelete: null, modifiedDate: null },
            records: records,
            sortDirection: null,
            sortColumnName: null,
        };
    }

    static defaultProps = {
        showHeader: true,
    };

    UNSAFE_componentWillReceiveProps(nextProps: IZeroToInfiniteGridProps) {
        const { sortDirection, sortColumnName } = this.state;
        if (JSON.stringify(nextProps.records) !== JSON.stringify(this.state.records)) {
            let nextRecords = nextProps.records;
            if (sortDirection && sortColumnName) {
                nextRecords = this.getSortedRecords(nextRecords, sortDirection, sortColumnName);
            }
            this.setState({
                records: nextRecords,
            });
        }
    }

    componentWillUnmount() {
        this.setState({
            selectedRow: { canDelete: null, modifiedDate: null },
            records: null,
            sortDirection: null,
            sortColumnName: null,
        });
    }

    public getColumns(serviceObj: ITableService[]): string[] {
        return Object.keys(serviceObj[0]);
    }

    private getSortedRecords(
        records: IRecord[],
        sortDirection: SortDirectionType,
        sortColumnName: string
    ): IRecord[] {
        const { formatNumber } = this.props.intl;
        return records.sort((a, b) => {
            // Get thousands separator based on locale formatting
            const separator = formatNumber("1000")[1];
            const getComparisonValue = (o) => {
                const val = o[sortColumnName];
                const asNumber = FormattingHelpers.parseStringToNumber(val, separator);
                return !isNaN(asNumber) ? asNumber : val;
            };
            const comparer = (i, j) => (isNaN(Number(i)) ? i.localeCompare(j) : i - j);

            const x = getComparisonValue(a);
            const y = getComparisonValue(b);
            return sortDirection === "ASC" ? comparer(x, y) : comparer(y, x);
        });
    }

    private onRowSelection = (event: ReactMouseEvent, selectedRow: IRecord): void => {
        event.stopPropagation();
        event.nativeEvent.stopImmediatePropagation();
        if (this.props.disabled) {
            this.setState({ selectedRow: null });
            return;
        }
        if (selectedRow === this.state.selectedRow) {
            this.setState({ selectedRow: null });
        } else {
            this.setState({
                selectedRow,
            });
        }
    };

    private isRowSelected = (record: IRecord): boolean => {
        const { selectedRow } = this.state;
        return selectedRow === record;
    };

    private printLabelValue(record: IRecord, attr: string): string {
        return record[attr];
    }

    private isPrinter(columns: IColumnData, attr: string): string {
        return columns[attr].printer;
    }

    private sortRecords = ({ sortOptions, sortColumnName }): void => {
        const { sortDirection } = sortOptions;
        const { records } = this.state;
        const sortedRecords = this.getSortedRecords(records, sortDirection, sortColumnName);
        this.setState({
            records: sortedRecords,
            sortDirection,
            sortColumnName,
        });
    };

    private getCells(record: IRecord, columns: IColumnData): IRecord[] {
        const columnKeys = Object.keys(columns);
        return (
            columnKeys &&
            columnKeys.map((attr) => {
                const value = this.printLabelValue(record, attr);
                if (this.isPrinter(columns, attr)) {
                    return columns[attr].printer(value, record);
                }
                let valueComponent = <p>{value}</p>;
                if (
                    this.props.formatCellValue &&
                    this.props.formatColumnKeys &&
                    this.props.formatColumnKeys.includes(attr)
                ) {
                    valueComponent = this.props.formatCellValue(value);
                }
                return (
                    <TableCell
                        key={`row-${attr}`}
                        className={columns[attr].className}
                        title={`${value}`}
                    >
                        {valueComponent}
                    </TableCell>
                );
            })
        );
    }

    public handleClickOutside = (): void => {
        this.setState({
            selectedRow: { canDelete: null, modifiedDate: null },
        });
    };

    private renderEditDeleteButtons = (
        record: IRecord,
        index: IIndex,
        selected: boolean
    ): IRecord[] => {
        const { onDelete, onEdit } = this.props;
        const { formatMessage } = this.props.intl;
        const retObj = [];
        if (onDelete) {
            retObj.push(
                <TableCell
                    key="zton-delete"
                    className={"z-n-icon"}
                    onClick={() => {
                        if (selected) {
                            this.props.onDelete(record, index);
                        }
                    }}
                    title={formatMessage(messages.delete)}
                >
                    {!selected ? null : <TrashcanIcon />}
                </TableCell>
            );
        }
        if (onEdit) {
            retObj.push(
                <TableCell
                    key="zton-edit"
                    className={"z-n-icon"}
                    onClick={() => {
                        if (selected) {
                            this.props.onEdit(record, index);
                        }
                    }}
                    title={formatMessage(messages.edit)}
                >
                    {!selected ? null : <PencilIcon className="pencil-icon" />}
                </TableCell>
            );
        }
        return retObj;
    };

    renderRows = (): ReactNode => {
        const { records } = this.state;
        const { columns, onDelete } = this.props;
        return (
            records &&
            records
                .filter((record) => record)
                .map((record, index) => {
                    const selected = this.isRowSelected(record);
                    const selectedClass = selected ? "data-table-row-selected" : "";
                    const { restrictEditDelete } = record;
                    const restrictModeClass =
                        record.restrictEditDelete && onDelete ? "data-table-row-restrict" : "";
                    return (
                        <div
                            key={`z-n-row-${index}`}
                            className={classnames(
                                "data-table-row",
                                selectedClass,
                                restrictModeClass
                            )}
                            onClick={(event) => this.onRowSelection(event, record)}
                        >
                            {restrictEditDelete
                                ? null
                                : this.renderEditDeleteButtons(record, index as IIndex, selected)}
                            {this.getCells(record, columns as IColumnData)}
                        </div>
                    );
                })
        );
    };

    render() {
        const { className, columns, onDelete, onEdit, showHeader } = this.props;

        return (
            <div className={classnames("plain-data-table", className)}>
                {!showHeader ? null : (
                    <PlainTableHeader
                        columns={columns}
                        sortRecords={this.sortRecords}
                        onEdit={onEdit}
                        onDelete={onDelete}
                    />
                )}
                <div className="plain-table-body">{this.renderRows()}</div>
            </div>
        );
    }
}

export default injectIntl(onClickOutside(ZeroToInfiniteGrid));
