import React, { Component } from "react";
import { defineMessages, injectIntl } from "react-intl";
import { connect } from "react-redux";
import classnames from "classnames";

import { actions as accordionActions } from "~/accordion";
import { Loader, Pane, SelectInput, Tabs } from "~/core";
import { selectors as cdSelectors } from "~/customer-data";
import { mapActions, mapSelectors } from "~/map";

import { ModuleFiltersToggle, ModuleFilters } from "../../../module-filters";
import * as selectors from "./selectors";
import * as actions from "./actions";
import { messages } from "../i18n-messages";
import { OperationalLayersContextMenu } from "../context-menus/operational-layers-context-menu";
import { NonFieldDataLayersContextMenu } from "../context-menus/non-field-data-layers-context-menu";
import { SplitScreenCompareTool } from "./layer-accordion/dialog-boxes/split-screen-compare-tool";
import { LayerAccordion } from "./layer-accordion/layer-accordion";
import { StandardLayers } from "./standard-layers/standard-layers";
import { NonFieldDataAccordion } from "./non-field-data/accordion";

import "../../../module-tabs/filter-tabs.css";
import { selectors as loginSelectors } from "~/login";
import { LockIcon } from "~/core/icons";
import { logFirebaseEvent } from "~/utils/firebase";

const filterMessages = defineMessages({
    cropPlaceholder: {
        id: "layerList.filterTabs.cropPlaceholder",
        defaultMessage: "Crop",
    },
    layerTypePlaceholder: {
        id: "layerList.filterTabs.layerTypePlaceholder",
        defaultMessage: "Layer Type",
    },
    seasonPlaceholder: {
        id: "layerList.filterTabs.seasonPlaceholder",
        defaultMessage: "Season",
    },
});

interface IProps {
    accordionId: number;
    accordionItemsCount: number;
    closeSplitScreenCompareTool: () => void;
    collapseAll: (accordionId: number) => void;
    currentTabIndex: number;
    expandAll: (accordionId: number) => void;
    expandedFieldsCount: number;
    filterOptions: any;
    intl: any;
    isLockIconShowing: boolean;
    layerFilter: any;
    layerPanelLoading: boolean;
    onToggleFilters: (value: any) => void;
    selectAllStandardLayers: () => void;
    selectedFieldGuids: any;
    selectedStandardLayersCount: number;
    setCurrentTabIndex: (index: number) => void;
    setLayerFilter: (filter: any) => void;
    showFilters: boolean;
    searchValue: string;
    splitScreenCompareToolIsOpen: boolean;
    totalStandardLayersCount: number;
    unselectAllStandardLayers: () => void;
    unselectAllSampleSites: () => void;
    unselectAllSurfaces: (accordionId: number) => void;
    updateSearchValue: () => void;
    userInfo: any;
    visibleSampleSitesCount: number;
    visibleSurfacesCount: number;
}

class LayerList_ extends Component<IProps> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            selectedTabIdx: 1,
        };
    }

    private clearSelected() {
        const { accordionId, unselectAllSampleSites, unselectAllSurfaces } = this.props;
        logFirebaseEvent("clear_selected_layers");
        unselectAllSurfaces(accordionId);
        unselectAllSampleSites();
    }

    render() {
        const { formatMessage } = this.props.intl;
        const {
            accordionId,
            accordionItemsCount,
            closeSplitScreenCompareTool,
            collapseAll,
            currentTabIndex,
            expandAll,
            expandedFieldsCount,
            filterOptions,
            isLockIconShowing,
            layerFilter,
            layerPanelLoading,
            onToggleFilters,
            setCurrentTabIndex,
            setLayerFilter,
            showFilters,
            splitScreenCompareToolIsOpen,
            userInfo,
            visibleSampleSitesCount,
            visibleSurfacesCount,
        } = this.props;
        const { cropOptions, croppingSeasonOptions, layerTypeOptions } = filterOptions;

        const filterInputEls = [
            <SelectInput
                key={0}
                containerClassNames={["filter-115"]}
                options={croppingSeasonOptions}
                placeholderText={formatMessage(filterMessages.seasonPlaceholder)}
                onChange={(croppingSeasonGuid) => setLayerFilter({ croppingSeasonGuid })}
                value={layerFilter.seasonGuid}
            />,
            <SelectInput
                key={1}
                options={layerTypeOptions}
                placeholderText={formatMessage(filterMessages.layerTypePlaceholder)}
                onChange={(layerType) => setLayerFilter({ layerType })}
                value={layerFilter.layerType}
            />,
            <SelectInput
                key={2}
                containerClassNames={["filter-115"]}
                options={cropOptions}
                placeholderText={formatMessage(filterMessages.cropPlaceholder)}
                onChange={(cropGuid) => setLayerFilter({ cropGuid })}
                value={layerFilter.cropGuid}
            />,
        ];

        const labelContainerLeftElements = [<div key={0} className="spacer" />];
        const labelContainerRightElements = [];

        const operationalLayersContextMenuProps = {
            accordionItemsCount: accordionItemsCount,
            expandedCount: expandedFieldsCount,
            onClearSelected: () => this.clearSelected(),
            onCollapseAll: () => collapseAll(accordionId),
            onExpandAll: () => expandAll(accordionId),
            selectedCount: visibleSurfacesCount + visibleSampleSitesCount,
            userInfo: userInfo,
        };
        switch (currentTabIndex) {
            case 0:
                labelContainerRightElements.push(
                    <OperationalLayersContextMenu {...operationalLayersContextMenuProps} />
                );
                break;
            case 1:
                labelContainerRightElements.push(<NonFieldDataLayersContextMenu />);
                break;
            default:
                break;
        }

        return (
            <div className="layer-list">
                <div className="module-filter-row">
                    {!showFilters ? null : (
                        <div className="module-filters">
                            <ModuleFilters>{filterInputEls}</ModuleFilters>
                        </div>
                    )}
                    <ModuleFiltersToggle
                        filterVisible={showFilters}
                        onToggleFilters={(newValue) => {
                            logFirebaseEvent("filters_layers");
                            onToggleFilters(newValue);
                        }}
                    />
                </div>

                <Tabs
                    className="filter-tabs"
                    selected={currentTabIndex}
                    labelContainerLeftElements={labelContainerLeftElements}
                    labelContainerRightElements={labelContainerRightElements}
                    onTabSelect={(tabIdx) => {
                        tabIdx === 1 && logFirebaseEvent("nff_data_tab");
                        tabIdx === 2 && logFirebaseEvent("base_data_tab");
                        setCurrentTabIndex(tabIdx);
                    }}
                >
                    <Pane label={formatMessage(messages.operationalLayers)}>
                        <div
                            className={classnames("pane-content", {
                                "pane-content-loader": layerPanelLoading,
                            })}
                        >
                            {layerPanelLoading ? (
                                <Loader />
                            ) : isLockIconShowing ? (
                                <LockIcon className="pane-content lock-icon" />
                            ) : (
                                <LayerAccordion />
                            )}
                            {!layerPanelLoading && isLockIconShowing ? (
                                <div className="pane-content lock-icon-text">
                                    {formatMessage(messages.allFieldsLocked)}
                                </div>
                            ) : null}
                        </div>
                    </Pane>
                    <Pane label={formatMessage(messages.nonFieldDataTab)}>
                        {layerPanelLoading ? <Loader /> : <NonFieldDataAccordion />}
                    </Pane>
                    <Pane label={formatMessage(messages.cadastralLayers)}>
                        <div className="pane-content scroll-y">
                            <StandardLayers />
                        </div>
                    </Pane>
                </Tabs>
                <SplitScreenCompareTool
                    isOpen={splitScreenCompareToolIsOpen}
                    onClose={() => closeSplitScreenCompareTool()}
                />
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const fieldGuidToFieldMap = selectors.getFieldGuidToFieldMap(state);
    const selectedFieldGuids = cdSelectors.getSelectedFieldGuids(state);
    const lockCustomersNotEnrolled = loginSelectors.getTheUserLockCustomer(state);
    const lastSelectedField = fieldGuidToFieldMap.get(selectedFieldGuids.last());
    const showLockIcon =
        lockCustomersNotEnrolled &&
        selectedFieldGuids.count() > 0 &&
        lastSelectedField != null &&
        !lastSelectedField.customerEnrolled;

    return {
        accordionId: selectors.getAccordionId(state),
        accordionItemsCount: selectors.getAccordionItemsCount(state),
        currentTabIndex: selectors.getCurrentTabIndex(state),
        expandedFieldsCount: selectors.getExpandedFieldGuidSet(state).size,
        filterOptions: selectors.getLayerFilterOptions(state),
        isLockIconShowing: showLockIcon,
        layerFilter: selectors.getLayerFilter(state),
        layerPanelLoading: selectors.getPanelLoading(state),
        selectedFieldGuids: cdSelectors.getSelectedFieldGuids(state),
        selectedStandardLayersCount: mapSelectors.getSelectedStandardLayersCount(state),
        showFilters: selectors.getShowFilters(state),
        searchValue: selectors.getSearchValue(state),
        splitScreenCompareToolIsOpen: selectors.getSplitScreenCompareToolIsOpen(state),
        totalStandardLayersCount: mapSelectors.getTotalStandardLayersCount(state),
        userInfo: loginSelectors.getUser(state),
        visibleSampleSitesCount: selectors.getVisibleSampleSitesMap(state).size,
        visibleSurfacesCount: selectors.getVisibleSurfacesMap(state).size,
    };
};

const mapDispatchToProps = (dispatch) => ({
    closeSplitScreenCompareTool: () => dispatch(actions.splitScreenCompareToolClose()),
    collapseAll: (accordionId) =>
        dispatch(accordionActions.collapseAllAccordionItems(accordionId, [1])),
    expandAll: (accordionId) =>
        dispatch(accordionActions.expandAllAccordionItems(accordionId, [1])),
    onToggleFilters: (showFilters) => dispatch(actions.setShowFilters(showFilters)),
    selectAllStandardLayers: () => dispatch(mapActions.selectAllLayers()),
    setCurrentTabIndex: (index) => dispatch(actions.setCurrentTabIndex(index)),
    setLayerFilter: (filter) => dispatch(actions.setLayerFilter(filter)),
    unselectAllStandardLayers: () => dispatch(mapActions.unselectAllLayers()),
    unselectAllSampleSites: () => dispatch(actions.removeAllVisibleSampleSites()),
    unselectAllSurfaces: (accordionId) =>
        dispatch(accordionActions.collapseAllAccordionItems(accordionId, [3])),
    updateSearchValue: (newSearchValue) => dispatch(actions.updateSearchValue(newSearchValue)),
});

export const LayerList = connect(mapStateToProps, mapDispatchToProps)(injectIntl(LayerList_));
