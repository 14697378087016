import React from "react";
import { injectIntl, intlShape } from "react-intl";
import classNames from "classnames";
import { AddIcon } from "~/core/icons";
import { messages } from "../../i18n-messages";
import "./add-customer-link.css";
import { logFirebaseEvent } from "~/utils/firebase";

interface IAddCustomerLinkProps {
    className: string;
    intl: intlShape;
    loadDetailPage: (childNameCode: string, parentNameCode: string) => void;
}
class AddCustomerLink_ extends React.Component<IAddCustomerLinkProps> {
    static defaultProps = {};
    /// ----------------------------------------- ///

    /// Event Handlers ///
    onAddCustomerClick = (e) => {
        logFirebaseEvent("add_customer");
        e.preventDefault();
        e.target.blur();
        this.props.loadDetailPage("212", "101");
    };
    /// ------------- ///

    render() {
        const { formatMessage } = this.props.intl;
        return (
            <div
                className={classNames(
                    "add-customer-link",
                    "customer-header",
                    "add-new-customer",
                    this.props.className
                )}
            >
                <a
                    href="#/"
                    title={formatMessage(messages.addHint)}
                    onClick={this.onAddCustomerClick}
                >
                    <AddIcon />
                    {formatMessage(messages.addCustomerText)}
                </a>
            </div>
        );
    }
}

export const AddCustomerLink = injectIntl(AddCustomerLink_);
