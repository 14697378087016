import { ADMIN_STATE_KEY } from "~/admin";
import { USER_PREFERENCE_DATA_KEY } from "./reducer";

const _getModuleState = (state) => state[ADMIN_STATE_KEY][USER_PREFERENCE_DATA_KEY];

export const getUserPreference = (state) => _getModuleState(state).userPreference;

export const getColorOverrides = (state) => _getModuleState(state).colorOverrides;

export const getColorOverrideTypes = (state) => _getModuleState(state).colorOverrideTypes;

export const getCropData = (state) => _getModuleState(state).dropdowns.cropData;

export const getBrandOrgData = (state) => _getModuleState(state).dropdowns.brandOrgData;

export const getVarietyHybridData = (state) => _getModuleState(state).dropdowns.varietyHybridData;
