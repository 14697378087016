import React, { Component } from "react";
import { injectIntl, intlShape } from "react-intl";
import { connect } from "react-redux";

import { DialogBox, Loader, LoaderTypes } from "~/core";
import { config as intlConfig } from "~/intl-provider/config";
import { getTheUserGuid } from "~/login";
import { actions as notificationActions } from "~/notifications";
import { AgEventAPI, FormattingHelpers, LayerAPI } from "@ai360/core";

import { messages } from "../../../i18n-messages";
import { keywords } from "../../../../../import-module/keywords";

import "./layer-stats.css";

const DEFAULT_STATS = {
    averageValue: "",
    coverageArea: "",
    displayName: "",
    maxValue: "",
    minValue: "",
    totalYield: "",
    units: "",
};

interface ILayerStatsProps {
    intl: intlShape;
    isOpen: boolean;
    layer: LayerAPI.ILayer;
    letterIcon: string;
    onApiError: (err, message) => void;
    onClose: () => void;
    surface: LayerAPI.ISubLayer;
    userGuid: string;
}

interface ILayerStatsState {
    agEventGeneralGuid: string;
    isLoading: boolean;
    stats: any;
}

class LayerStats_ extends Component<ILayerStatsProps, ILayerStatsState> {
    constructor(props) {
        super(props);
        this.state = {
            agEventGeneralGuid: null,
            isLoading: false,
            stats: DEFAULT_STATS,
        };
    }

    _fetchLayerStats(props) {
        this.setState(
            {
                isLoading: true,
            },
            () => {
                switch (props.surface.agEventTransactionType) {
                    case keywords.HARVEST:
                        AgEventAPI.getHarvestEventLayerStats(props.userGuid, props.surface)
                            .then((stats) => {
                                this.setState({
                                    isLoading: false,
                                    stats,
                                    agEventGeneralGuid: props.surface.agEventGeneralGuid,
                                });
                            })
                            .catch((err) => {
                                this.props.onApiError(err, messages.failedToResetToDefault);
                                this.setState({ isLoading: false });
                            });
                        break;
                    default:
                        this.setState({
                            isLoading: false,
                            stats: DEFAULT_STATS,
                        });
                }
            }
        );
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (
            nextProps.isOpen &&
            nextProps.surface.agEventGeneralGuid !== this.state.agEventGeneralGuid
        ) {
            this._fetchLayerStats(nextProps);
        }
    }

    render() {
        const { isOpen, layer, letterIcon, onClose } = this.props;
        const { formatMessage, formatNumber } = this.props.intl;
        const { isLoading, stats } = this.state;
        const {
            averageValue,
            coverageArea,
            harvestedArea,
            displayName,
            maxValue,
            minValue,
            totalYield,
            units,
        } = stats;

        const unitPatternMatch = units.match(/(.*)\/(.*)/);
        const quantityUnits = unitPatternMatch == null ? "" : unitPatternMatch[1];
        const areaUnits = unitPatternMatch == null ? "" : unitPatternMatch[2];
        const decimals = FormattingHelpers.progressiveRoundingDecimals(Number(averageValue));

        return (
            <DialogBox
                className="layer-stats-dialog"
                draggable
                isOpen={isOpen}
                letterIcon={letterIcon}
                onClose={() => onClose()}
                title={`${layer.displayName} - ${formatMessage(messages.layerStats)}`}
            >
                {isLoading ? (
                    <Loader type={LoaderTypes.LINE_SCALE_PULSE_OUT} />
                ) : (
                    [
                        <h2 key="header">{displayName}</h2>,
                        <table key="table">
                            <tbody>
                                <tr>
                                    <td className="title">
                                        {formatMessage(
                                            harvestedArea
                                                ? messages.layerStatsDialogHarvestedArea
                                                : messages.layerStatsDialogCoverageArea
                                        )}
                                    </td>
                                    <td className="number">{`${formatNumber(
                                        harvestedArea || coverageArea,
                                        intlConfig.numberFormatOptions
                                    )}`}</td>
                                    <td className="units">{areaUnits}</td>
                                </tr>
                                <tr>
                                    <td className="title">
                                        {formatMessage(messages.layerStatsDialogMin)}
                                    </td>
                                    <td className="number">{`${formatNumber(
                                        minValue,
                                        intlConfig.customFormatOptions(decimals)
                                    )}`}</td>
                                    <td className="units">{units}</td>
                                </tr>
                                <tr>
                                    <td className="title">
                                        {formatMessage(messages.layerStatsDialogMax)}
                                    </td>
                                    <td className="number">{`${formatNumber(
                                        maxValue,
                                        intlConfig.customFormatOptions(decimals)
                                    )}`}</td>
                                    <td className="units">{units}</td>
                                </tr>
                                <tr>
                                    <td className="title">
                                        {formatMessage(messages.layerStatsDialogAverage)}
                                    </td>
                                    <td className="number">{`${formatNumber(
                                        averageValue,
                                        intlConfig.customFormatOptions(decimals)
                                    )}`}</td>
                                    <td className="units">{units}</td>
                                </tr>
                                <tr>
                                    <td className="title">
                                        {formatMessage(messages.layerStatsDialogTotal)}
                                    </td>
                                    <td className="number">{`${formatNumber(
                                        totalYield,
                                        intlConfig.wholeNumberFormatOptions
                                    )}`}</td>
                                    <td className="units">{quantityUnits}</td>
                                </tr>
                            </tbody>
                        </table>,
                    ]
                )}
            </DialogBox>
        );
    }
}

const mapDispatchToProps = (dispatch) => ({
    onApiError: (err, message) => dispatch(notificationActions.apiCallError(err, null, message)),
});

const mapStateToProps = (state) => ({
    userGuid: getTheUserGuid(state),
});
const mergeProps = (stateProps, dispatchProps, ownProps) => ({
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
});

export const LayerStats = connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
)(injectIntl(LayerStats_));
