import React, { useState } from "react";
import { injectIntl, intlShape } from "react-intl";
import { connect } from "react-redux";

import { Menu } from "~/core";
import { messages } from "../../../../context-menus/i18n-messages";
import { messages as globalMessages } from "~/i18n-messages";

import { DeleteConfDialog } from "../../../../context-menus/item-delete-confirmation-dialog";

import { actions as cdActions } from "~/customer-data";
import * as nonFieldFeatureInformationActions from "../../../components/non-field-feature-information/actions";
import * as samplingActions from "../../../components/sampling/actions";
import "./context-menu.css";
import { logFirebaseEvent } from "~/utils/firebase";
import { getUser } from "~/login";
import { UserAPI } from "@ai360/core";

interface NonFieldDataContextMenuProps {
    addSampling: any;
    intl: intlShape;
    startEditingFeature: any;
    feature: any;
    customer: any;
    deleteFeature: any;
    userInfo: UserAPI.IUser;
}

const NonFieldDataContextMenu_ = (props: NonFieldDataContextMenuProps) => {
    const { formatMessage } = props.intl;
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const userRole = props.userInfo?.role;
    const items = [
        {
            disabled: false,
            label: formatMessage(messages.editNonFieldFeature),
            action: () => props.startEditingFeature(props.feature, props.customer),
        },
        {
            disabled: false,
            label: formatMessage(messages.deleteNonFieldFeature),
            action: () => setShowDeleteDialog(true),
        },
    ];

    const getMenuItems = () => {
        if (userRole?.water) {
            const revisedItems = [...items];
            revisedItems.splice(1, 0, {
                disabled: false,
                label: formatMessage(messages.addSample),
                action: () => props.addSampling(props.feature),
            });
            return revisedItems.map((menuItem, key) => {
                return { ...menuItem, key };
            });
        }
        return items.map((menuItem, key) => {
            return { ...menuItem, key };
        });
    };

    const deleteDialog = !showDeleteDialog
        ? null
        : DeleteConfDialog({
              formatMessage,
              deleteConfirmationTitle: globalMessages.confirmTitle,
              deleteConfirmationMessage: messages.deleteNonFieldFeatureConfirmation,
              onConfirmationClick: (confirmation) => {
                  setShowDeleteDialog(false);
                  if (confirmation) {
                      logFirebaseEvent("nff_delete_feature");
                      props.deleteFeature(props.feature);
                  }
              },
          });

    return (
        <div>
            <div
                className="context-menu-container non-field-data-context-menu-container"
                onClick={(evt) => evt.preventDefault()}
            >
                <Menu
                    className="context-menu non-field-data-context-menu"
                    isDotMenu={true}
                    getMenuItems={() => getMenuItems()}
                />
            </div>
            {deleteDialog}
        </div>
    );
};

const mapStateToProps = (state) => ({
    userInfo: getUser(state),
});

const mapDispatchToProps = (dispatch) => ({
    addSampling: (nonFieldFeature) => dispatch(samplingActions.addSampling(nonFieldFeature)),
    startEditingFeature: (feature, customer) =>
        dispatch(nonFieldFeatureInformationActions.startEditingFeature(feature, customer)),
    deleteFeature: (feature) => dispatch(cdActions.deleteNonFieldFeature(feature)),
});

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    return {
        ...stateProps,
        ...dispatchProps,
        ...ownProps,
        startEditingFeature: (feature, customer) =>
            dispatchProps.startEditingFeature(feature, customer),
    };
};

export const NonFieldDataContextMenu = connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
)(injectIntl(NonFieldDataContextMenu_));
